import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { CustomBadge } from '../Charts/style';
import { COLORS } from 'styles/colors';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function UserNotificationList({ key, rowItem, onRowClick }: any): any {
  const theme = useTheme();

  const handleClick = (): any => {
    onRowClick(rowItem);
  };

  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      theme={theme}
      style={{ cursor: 'pointer' }}
    >
      <TableCell item xs={4}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '0.625rem',
          }}
        >
          {!rowItem.Read && (
            <CustomBadge badgecolor={COLORS.royalBlue} variant="dot" />
          )}
        </div>
        <OverflowTip text={rowItem.Title}></OverflowTip>
      </TableCell>
      <TableCell item xs={8}>
        <OverflowTip text={rowItem.Message}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default UserNotificationList;
