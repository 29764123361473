import { useTheme } from '@mui/material/styles';

export default function SangomaIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="250"
      height="72"
      viewBox="0 0 250 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.8781 27.9266C63.4687 27.9266 60.1951 29.1292 58.124 30.0645L57.7899 30.1981L59.6606 34.5407L59.9946 34.4071C60.1283 34.3403 63.4019 32.8037 67.5441 32.8037C71.6194 32.8037 74.0914 34.6744 74.0914 37.7476V40.4868C72.0871 39.017 69.3479 38.1484 66.4083 38.1484C61.6649 38.1484 54.6499 40.6204 54.6499 47.4349C54.6499 52.1784 57.8567 57.7903 67.0096 57.7903C73.9578 57.7903 77.7659 55.3852 79.4361 53.9822L79.5697 53.9154V38.9502C79.5697 31.2003 76.0289 27.9266 67.8781 27.9266ZM74.0914 45.6311V51.1094C72.3543 52.2452 70.016 52.8465 67.3437 52.8465C62.6002 52.8465 60.4623 50.2409 60.4623 47.769C60.4623 45.3638 62.5334 43.0255 67.0764 43.0255C70.6173 43.0255 73.0892 44.7626 74.0914 45.6311Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M99.2783 27.9266C95.2029 27.9266 92.1965 29.7973 90.5263 31.2003V28.6615H84.9143V56.9218H90.5263V36.3446C91.662 35.0752 94.468 33.0709 97.6081 33.0709C102.084 33.0709 103.755 35.2088 103.755 40.8876V56.9218H109.366V38.4157C109.3 31.4675 105.892 27.9266 99.2783 27.9266Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M41.9562 37.1463C38.8162 35.6097 36.1438 34.2735 36.1438 31.8683C36.1438 30.0645 37.5468 28.2606 40.6869 28.2606C43.7601 28.2606 46.6329 30.599 47.3678 31.4007L47.8354 31.8683L50.6414 26.9245L50.3742 26.6572C48.5703 24.8534 44.829 22.7823 40.0188 22.7823C33.8723 22.7823 29.1957 26.8576 29.1957 32.2024C29.1957 37.2799 33.8723 39.7518 37.9477 41.8897C41.2213 43.6268 44.0273 45.0966 44.0273 47.5017C44.0273 51.9779 39.8851 52.312 38.6158 52.312H27.9931H26.991V57.7903H38.883C46.0984 57.7903 50.9755 53.5813 50.9755 47.3681C50.9087 41.5557 46.0984 39.2174 41.9562 37.1463Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M129.543 27.9266C125.401 27.9266 121.593 29.2628 118.787 31.7348C115.714 34.4739 114.043 38.2821 114.043 42.8251C114.043 48.3703 116.315 51.8443 118.252 53.7818C120.724 56.2537 124.132 57.7235 127.472 57.7235C130.412 57.7235 133.619 56.3874 135.756 54.3163V58.6589C135.756 64.2708 133.151 67.1436 128.007 67.1436C124.065 67.1436 120.457 65.607 118.586 64.3377L118.319 64.1372L115.78 68.413L116.048 68.6134C117.918 69.8828 121.994 71.9539 128.007 71.9539C130.545 71.9539 134.153 71.4194 137.026 69.0143C139.899 66.6092 141.302 62.9347 141.302 58.0576V31.2671L141.168 31.2003C137.828 28.9956 134.019 27.9266 129.543 27.9266ZM135.756 34.3403V48.7043C134.287 51.1094 131.347 52.7797 128.541 52.7797C122.261 52.7797 119.923 46.8336 119.923 42.8251C119.923 35.4093 125.134 32.8037 129.61 32.8037C132.616 32.8037 134.821 33.8727 135.756 34.3403Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M161.277 27.9266C152.726 27.9266 146.045 34.4739 146.045 42.8251C146.045 51.1762 152.726 57.7235 161.211 57.7235C169.695 57.7235 176.309 51.1762 176.309 42.8251C176.309 34.4739 169.695 27.9266 161.277 27.9266ZM170.43 42.8919C170.43 48.9047 166.689 52.9801 161.077 52.9801C155.999 52.9801 151.857 48.5039 151.857 43.0255C151.857 37.0127 155.665 32.8037 161.211 32.8037C166.422 32.8037 170.43 37.2131 170.43 42.8919Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M211.986 27.9266C207.777 27.9266 204.102 30.3318 202.098 31.9352C200.494 29.2628 198.022 27.9266 194.749 27.9266C190.74 27.9266 187.667 29.9309 186.13 31.2003V28.5947H180.452V56.855H186.064V36.2778C187.133 35.142 189.872 33.0709 192.745 33.0709C196.352 33.0709 197.956 34.808 197.956 38.6829V56.9218H203.568V36.211C205.037 34.7412 207.643 33.0709 210.449 33.0709C213.923 33.0709 215.526 34.808 215.526 38.8165V56.9218H221.138V38.2153C221.138 31.4675 217.932 27.9266 211.986 27.9266Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M238.308 27.9266C233.899 27.9266 230.625 29.1292 228.554 30.0645L228.22 30.1981L230.091 34.5407L230.425 34.4071C230.558 34.3403 233.832 32.8037 237.974 32.8037C242.05 32.8037 244.522 34.6744 244.522 37.7476V40.4868C242.517 39.017 239.778 38.1484 236.839 38.1484C232.095 38.1484 225.08 40.6204 225.08 47.4349C225.08 52.1784 228.287 57.7903 237.44 57.7903C244.388 57.7903 248.196 55.3852 249.866 53.9822L250 53.9154V38.9502C250 31.2003 246.526 27.9266 238.308 27.9266ZM244.588 45.6311V51.1094C242.851 52.2452 240.513 52.8465 237.841 52.8465C233.097 52.8465 230.959 50.2409 230.959 47.769C230.959 45.3638 233.03 43.0255 237.573 43.0255C241.047 43.0255 243.519 44.7626 244.588 45.6311Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M57.5895 21.5129C58.124 21.3793 58.7253 21.2457 59.2598 21.2457L60.1951 21.1789C60.2619 21.1789 60.3287 21.1789 60.3287 21.1789C62.8675 21.1789 65.139 21.9806 67.0096 23.3836C69.8824 22.7155 72.2207 22.6487 74.1582 23.0495C71.419 18.9742 66.876 16.1682 61.6649 15.7673C58.3244 6.34723 49.372 0.000358214 39.3506 0.000358214C27.4586 -0.066451 17.17 9.22003 15.8338 21.1121C6.74773 22.3814 0 30.1981 0 39.4178C0 49.5728 8.28434 57.8571 18.4393 57.8571H21.6462V52.3788H18.4393C11.2908 52.3788 5.47835 46.5664 5.47835 39.4178C5.47835 33.3381 9.68733 28.127 15.4997 26.7908C16.5019 26.5236 17.5708 26.39 18.7066 26.39C19.6419 26.39 20.5772 26.5236 21.4458 26.6572C21.3121 25.6551 21.1785 24.653 21.1785 23.6508C21.1785 13.6294 29.3292 5.4119 39.4174 5.4119C40.8872 5.4119 42.2902 5.61233 43.6932 5.94638C51.0422 7.61661 56.721 13.8299 57.5895 21.5129Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
