/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { ChronicListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { COLORS } from 'styles/colors';
import { DATE_TIME_FORMAT, LOG_TYPE } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function ActivityLog({ key, rowItem }: ChronicListProps): any {
  const theme = useTheme();
  return (
    <TableRow
      container
      key={key}
      alignContent="center"
      theme={theme}
      sx={{
        background:
          rowItem?.rowType === LOG_TYPE.ONTEST
            ? COLORS.testLogColor
            : rowItem?.rowType === LOG_TYPE.DETAILS
            ? COLORS.detailsLogColor
            : theme.palette.customBackground.main,
        color: [LOG_TYPE.ONTEST, LOG_TYPE.DETAILS].includes(rowItem?.rowType)
          ? COLORS.white
          : theme.palette.text.primary,
        '&:hover': {
          background:
            rowItem?.rowType === LOG_TYPE.ONTEST
              ? COLORS.testLogColor
              : rowItem?.rowType === LOG_TYPE.DETAILS
              ? COLORS.detailsLogColor
              : theme.palette.mode === 'dark'
              ? COLORS.haitiViolet
              : COLORS.titanWhite,
        },
      }}
    >
      <TableCell item xs={3}>
        <OverflowTip
          text={getFormatedDate(rowItem?.dateTime, DATE_TIME_FORMAT)}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={9}>
        <OverflowTip text={rowItem?.text ?? '-'}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default ActivityLog;
