import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { CompanyListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import { COLORS } from 'styles/colors';
import { getUserPermissions } from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';
import { ERROR_TEXT } from 'utils/Constants/Content';

function CompanyRowItem({
  key,
  rowItem,
  tableName,
  onEditIconClick,
  handleChange,
}: CompanyListProps): any {
  const theme = useTheme();
  const isSMAdminPermission = getUserPermissions(
    USER_PERMISSIONS.SECURITY_MANAGER_ADMIN,
  );
  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={4}>
        <OverflowTip text={rowItem?.Name || ''}></OverflowTip>
      </TableCell>
      <TableCell item xs={4}>
        <OverflowTip text={rowItem?.PartyNumber || ''}></OverflowTip>
      </TableCell>

      <TableCell item xs={3}>
        <OverflowTip
          text={rowItem?.MasterCompanyType?.Name || ''}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <IconsButton
          name="edit"
          children={<EditIcon />}
          style={{
            background: COLORS.iconBgColor,
            borderRadius: 4,
            opacity:
              isSMAdminPermission && rowItem?.MasterCompany?.master_company_id
                ? 1
                : 0.5,
          }}
          OnIconClick={onEditIconClick}
          isShowTooltip={true}
          title={
            !isSMAdminPermission
              ? ERROR_TEXT.USER_PERMISSION_DENIEND_WARNING
              : 'Edit'
          }
          isDisabled={
            !isSMAdminPermission || !rowItem?.MasterCompany?.master_company_id
          }
        />
      </TableCell>
    </TableRow>
  );
}
export default CompanyRowItem;
