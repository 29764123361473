import { MapIcon, ListIcon, RoundTick } from 'assets/component';
import { COLORS } from 'styles/colors';
import { MenuProps } from './type';

export const FILTER_MENU: MenuProps[] = [
  {
    name: 'map',
    icon: <MapIcon color={COLORS.white} />,
    isSelected: true,
  },
  {
    name: 'list',
    icon: <ListIcon />,
    isSelected: false,
  },
];

export const RESOLVED = [
  {
    name: 'resolved',
    icon: <RoundTick />,
    isSelected: false,
  },
];
