import { useTheme } from '@mui/material/styles';

export default function AcumeraIcon(): JSX.Element {
  const theme = useTheme();

  return (
    <svg
      width="96"
      height="44"
      viewBox="0 0 96 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.6148 0H14.5045C6.48628 0 0 6.49859 0 14.5045V69.4955C0 77.5138 6.49863 84 14.5045 84H80.6148C88.633 84 95.1193 77.5014 95.1193 69.4955V14.5045C95.1193 6.48624 88.6207 0 80.6148 0ZM17.9021 68.8284H15.4311V15.7029H17.9021V68.8284ZM35.1987 68.8284H30.2568V15.7029H35.1987V68.8284ZM54.9663 68.8284H47.5535V15.7029H54.9663V68.8284ZM79.6758 68.8284H67.3211V15.7029H79.6758V68.8284Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
