import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { DATE_FORMAT_1 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function ServiceRequestExportRowItem({
  key,
  rowItem,
  tableName,
  isAutoCells,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      {isAutoCells ? (
        Object.keys(rowItem).length > 0 &&
        Object.keys(rowItem).map((key: any, value: any) => {
          const count = Object.keys(rowItem).length <= 12 ? 2 : 1;
          return (
            <TableCell item xs={count}>
              <OverflowTip
                text={
                  key === 'CreatedOn' ? (
                    getFormatedDate(rowItem.CreatedOn, DATE_FORMAT_1)
                  ) : key === 'DispatchedOn' ? (
                    getFormatedDate(rowItem.DispatchedOn, DATE_FORMAT_1)
                  ) : key === 'ResolvedOn' ? (
                    getFormatedDate(rowItem.ResolvedOn, DATE_FORMAT_1)
                  ) : key === 'UpdatedOn' ? (
                    getFormatedDate(rowItem.UpdatedOn, DATE_FORMAT_1)
                  ) : key === 'Address' ? (
                    <span className="name">
                      {rowItem.Address ? rowItem.Address : '-'}
                    </span>
                  ) : (
                    rowItem[key] || '-'
                  )
                }
              ></OverflowTip>
            </TableCell>
          );
        })
      ) : (
        <>
          <TableCell item xs={2}>
            <OverflowTip text={rowItem.ServiceNumber}></OverflowTip>
          </TableCell>
          <TableCell item xs={2}>
            <OverflowTip
              text={rowItem.CompanyNumber ? rowItem.CompanyNumber : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={2}>
            <OverflowTip
              text={rowItem.Address ? rowItem.Address : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip text={rowItem.City ? rowItem.City : '-'}></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.State ? rowItem.State : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip text={rowItem.Zip ? rowItem.Zip : '-'}></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.PhoneNumber ? rowItem.PhoneNumber : ''}
            ></OverflowTip>
          </TableCell>

          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.Status ? rowItem.Status : ''}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.Subject ? rowItem.Subject : ''}
            ></OverflowTip>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
export default ServiceRequestExportRowItem;
