import { useTheme } from '@mui/material/styles';

export default function OnlineManualsIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="68"
      height="52"
      viewBox="0 0 68 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.9592 5.58124V1.72865C64.9592 1.38806 64.8239 1.06142 64.5831 0.82059C64.3423 0.579757 64.0156 0.444458 63.675 0.444458H39.2753C38.279 0.444591 37.2964 0.676758 36.4054 1.12256C35.5144 1.56837 34.7395 2.21556 34.142 3.01285H34.1351C33.5377 2.21556 32.7627 1.56837 31.8717 1.12256C30.9807 0.676758 29.9981 0.444591 29.0018 0.444458H4.60209C4.2615 0.444458 3.93486 0.579757 3.69403 0.82059C3.4532 1.06142 3.3179 1.38806 3.3179 1.72865V5.58124H0.749512V49.2439H28.4016C29.8065 50.8191 31.8613 51.8123 34.1386 51.8123C36.4159 51.8123 38.4697 50.8191 39.8738 49.2439H67.5276V5.58124H64.9592ZM5.88629 3.01285H29.0018C31.1258 3.01285 32.8544 4.74137 32.8544 6.86543V31.4073C32.8544 31.7478 32.9897 32.0745 33.2305 32.3153C33.4713 32.5562 33.798 32.6914 34.1386 32.6914C34.4791 32.6914 34.8058 32.5562 35.0466 32.3153C35.2875 32.0745 35.4227 31.7478 35.4227 31.4073V6.86543C35.4227 4.74137 37.1513 3.01285 39.2753 3.01285H62.3908V44.1071H39.2753C38.279 44.1073 37.2965 44.3395 36.4055 44.7853C35.5145 45.2311 34.7395 45.8782 34.142 46.6755H34.1351C33.5376 45.8782 32.7627 45.2311 31.8717 44.7853C30.9807 44.3395 29.9981 44.1073 29.0018 44.1071H5.88629V3.01285Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
