import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import CustomTooltip from '../Tooltip';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import { COLORS } from 'styles/colors';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { CONTEXT } from 'utils/Constants/Content';
import { CUSTOM_ROW_WIDTH, DATE_FORMAT_2 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function ServiceTripRowItem({
  key,
  rowItem,
  tableName,
  onServiceTripEditClick,
  onSiteHandleClick = () => {},
  onServiceDetailsClick,
  source,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  const onSiteClick = (e: any) => {
    if (source !== CONTEXT.SITE_MANAGER) {
      onSiteHandleClick(e, rowItem.Site.Id);
    }
  };
  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      <TableCell
        item
        sm={false}
        md={1.5}
        theme={theme}
        width={CUSTOM_ROW_WIDTH}
      >
        <OverflowTip
          text={
            <span
              className={`name ${
                source !== CONTEXT.SITE_MANAGER ? 'link' : ''
              }`}
              onClick={e => onSiteClick(e)}
            >
              {rowItem.Site.Name}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={rowItem.Status === null ? '-' : rowItem.Status}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={
            rowItem?.ServiceRequest?.Priority === null
              ? '-'
              : rowItem.ServiceRequest.Priority
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={
            <span
              className="name link"
              onClick={() => onServiceDetailsClick(rowItem)}
            >
              {rowItem.CaseNumber === null ? '-' : rowItem.CaseNumber}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1.5} theme={theme}>
        <OverflowTip text={rowItem?.Title ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1.25} theme={theme}>
        <OverflowTip
          text={
            <span
              className="name link"
              onClick={(e: any) =>
                onServiceTripEditClick(rowItem.WorkOrderNumber)
              }
            >
              {rowItem.WorkOrderNumber}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1.25} theme={theme}>
        <OverflowTip
          text={rowItem.Type === null ? '-' : rowItem.Type}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={getFormatedDate(
            rowItem.ScheduledDate,
            DATE_FORMAT_2,
            '',
            'utc',
          )}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip text={rowItem?.ScheduledResource ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={getFormatedDate(
            rowItem.CreatedOn,
            DATE_FORMAT_2,
            rowItem?.TimeZone,
          )}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={0.5} theme={theme}>
        <Grid container>
          <CustomTooltip title={'Edit'}>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
              }}
              OnIconClick={(e: any) =>
                onServiceTripEditClick(rowItem.WorkOrderNumber)
              }
            />
          </CustomTooltip>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
export default ServiceTripRowItem;
