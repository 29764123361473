import { useTheme } from '@mui/material/styles';

export default function ServiceRequestSidebarIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M17.0002 2.99976L9.2998 10.7001"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.0001 2.99976L12.0999 17.0004L9.29979 10.7001L2.99951 7.89997L17.0001 2.99976Z"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
