import { useTheme } from '@mui/material/styles';

export default function ElearningIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="76"
      height="56"
      viewBox="0 0 76 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.0495 25.7654L22.6392 18.4267L38.0495 11.0879L53.4599 18.4267L38.0495 25.7654Z"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.2304 23.5635L46.8331 29.5941C46.9097 30.1919 46.8418 30.7993 46.635 31.3654C46.4283 31.9315 46.0887 32.4397 45.6448 32.8474C43.3377 34.1891 40.7164 34.8959 38.0475 34.8959C35.3786 34.8959 32.7573 34.1891 30.4502 32.8474C30.0081 32.4385 29.6699 31.93 29.4638 31.3642C29.2578 30.7983 29.1897 30.1915 29.2653 29.5941L29.868 23.5635"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.94085 38.4019V9.29339C8.94085 7.47691 9.66245 5.73482 10.9469 4.45038C12.2313 3.16593 13.9734 2.44434 15.7899 2.44434H60.3088C62.1253 2.44434 63.8673 3.16593 65.1518 4.45038C66.4362 5.73482 67.1578 7.47691 67.1578 9.29339V38.4019M50.1208 43.5387H74.0069C74.0069 47.3228 70.5824 53.8123 59.5862 53.8123H16.5125C5.51632 53.8123 2.0918 47.3228 2.0918 43.5387H25.9779C26.6861 43.5378 27.3769 43.7581 27.9538 44.1688L31.0359 46.3331C31.6129 46.7438 32.3037 46.964 33.0119 46.9632H43.1142C43.8224 46.964 44.5132 46.7438 45.0902 46.3331L48.1722 44.1688C48.7416 43.7635 49.422 43.5435 50.1208 43.5387Z"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
