import { useTheme } from '@mui/material/styles';

export default function AdminUsersIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M1.75 16V15.25C1.75 12.3505 4.1005 10 7 10V10C9.89949 10 12.25 12.3505 12.25 15.25V16"
          stroke={theme.palette.icon.main}
          strokeLinecap="round"
        />
        <path
          d="M10.75 11.5V11.5C10.75 9.42893 12.4289 7.75 14.5 7.75V7.75C16.5711 7.75 18.25 9.42893 18.25 11.5V11.875"
          stroke={theme.palette.icon.main}
          strokeLinecap="round"
        />
        <path
          d="M7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10Z"
          stroke={theme.palette.icon.main}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 7.75C15.7426 7.75 16.75 6.74264 16.75 5.5C16.75 4.25736 15.7426 3.25 14.5 3.25C13.2574 3.25 12.25 4.25736 12.25 5.5C12.25 6.74264 13.2574 7.75 14.5 7.75Z"
          stroke={theme.palette.icon.main}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
