import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';
import {
  DEFAULT_PAGE,
  SERVICES,
  SITE_HEADER,
  SORT_BY,
} from 'utils/Constants/index';
import { FilterMenu } from 'app/component/core/Filter';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDashboardFilterData,
  getViewType,
  getFilterSiteData,
  getDashboardFilter,
  getCompanyId,
  getDashboardListPageNo,
} from 'app/features/DashboardSlice/Selectors';
import { Listview } from 'app/component/core/CustomListView';
import CustomMaps from 'app/component/core/Maps';
import { actions } from 'app/features/DashboardSlice/slice';
import { PanelGrid } from '../../styles';
import { ClearIcon } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import { persistSiteInfoId } from 'app/features/authService';
import { MultipleSelectCheckmarks } from 'app/component/core/CustomSelectWithoutLable/multiselectCheckbox';
import {
  checkIEVersion,
  customListSearch,
  getFilterData,
  manageDropdownDataForIE,
  useMobileDevice,
} from 'utils/CommonFn';
import { CustomMultiselectIE } from 'app/component/core/CustomMultiselectIE';
import { LocationFilter } from 'app/component/common/LocationFilter';
import { CONTEXT } from 'utils/Constants/Content';
function Sites({ isSort = false }): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getFilters = useSelector(getDashboardFilterData);
  const selectorFilterSite: any = getFilters?.data?.site?.[0] ?? [];
  const selectorSiteData: any = useSelector(getFilterSiteData);
  const selectedView = useSelector(getViewType);
  const selectedCompanyId = useSelector(getCompanyId);
  const MapViewData = selectorSiteData.mapData || [];
  const ListViewData = selectorSiteData.listData || [];
  const TotalListCount = selectorSiteData.recordCount || 0;
  const system = selectorFilterSite?.installed_system || [];
  const service = selectorFilterSite?.service_level || [];
  const systemList = selectorFilterSite?.site_filter_dd_system || [];

  const dashboardFilter: any = useSelector(getDashboardFilter);
  const dashboardPageNo = useSelector(getDashboardListPageNo);
  const {
    locationValue,
    systemValue,
    ServiceValue,
    sortColumn,
    sortBy,
    systemListValue,
  } = dashboardFilter;
  const [currentPage, setCurrentPage] = useState(dashboardPageNo);
  const isMobile = useMobileDevice();
  const sortObject = { sortColumn: '', sortBy: '' };

  const defaultDashboardFilter = (): any => {
    let reqObj: any = {
      page: currentPage,
      recordCountRequire: selectedView === 'list',
    };
    if (ServiceValue.length > 0) {
      reqObj.serviceLevel = customListSearch(ServiceValue, service);
    }
    if (systemValue.length > 0) {
      reqObj.installedSystem = customListSearch(systemValue, system);
    }
    if (systemListValue.length > 0) {
      reqObj.systemLevel = customListSearch(systemListValue, systemList);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };

  const [defaultValue, setDefaultValue] = useState({
    page: currentPage,
    recordCountRequire: selectedView === 'list',
    ...defaultDashboardFilter(),
  });
  const themes = useTheme();
  const systemOptionIE: any = manageDropdownDataForIE(system);
  const serviceOptionIE: any = manageDropdownDataForIE(service);
  const systemListOptionIE: any = manageDropdownDataForIE(systemList);
  const [selectedSystemIE, setSelectedSystemIE] = useState(systemValue ?? []);
  const [selectedServiceIE, setSelectedServiceIE] = useState(
    ServiceValue ?? [],
  );
  const [selectedSystemListIE, setSelectedSystemListIE] = useState(
    systemListValue ?? [],
  );
  const onHandleChange = (e: any): void => {
    const selectedVal = e.target.value || '';
    const selectedFieldName = e.target.name || '';

    const getSelectedList = (list: any, value: any) => {
      return value.map((val: any) => {
        const obj = list.find((o: any) => o.key === val);
        return obj.value;
      });
    };
    let selectedValue = {};
    let selectedType = {};

    switch (selectedFieldName) {
      case CONTEXT.INTSTALLED_SYSTEM:
        selectedValue = { systemValue: selectedVal };
        selectedType = {
          installedSystem: getSelectedList(system, selectedVal),
          ...DEFAULT_PAGE,
        };
        break;
      case CONTEXT.SERVICE_LEVEL:
        selectedValue = { ServiceValue: selectedVal };
        selectedType = {
          serviceLevel: getSelectedList(service, selectedVal),
          ...DEFAULT_PAGE,
        };
        break;
      case CONTEXT.SERVICES:
        selectedValue = { systemListValue: selectedVal };
        selectedType = {
          systemLevel: getSelectedList(systemList, selectedVal),
          ...DEFAULT_PAGE,
        };
        break;
    }
    if (selectedValue) {
      dispatch(
        actions.updateDashboardFilter({
          ...selectedValue,
          ...sortObject,
        }),
      );
      dispatch(actions.updateDashboardListPageNo(1));
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      updateRecord(selectedType);
    }
  };
  const onFilterMenuClick = (keyName: any) => {
    const sortObj =
      sortColumn && keyName === 'list' ? { sortColumn, sortBy } : {};
    updateRecord({ recordCountRequire: keyName === 'list', ...sortObj }, true);
  };

  const updateRecord = (params?: any, isMenuClick = false) => {
    if (selectedCompanyId) {
      dispatch(
        actions.fetchFilterSiteData({
          ...defaultValue,
          ...params,
        }),
      );
    }
  };
  const onPagination = (pageNo: any) => {
    setCurrentPage(pageNo);
    dispatch(actions.updateDashboardListPageNo(pageNo));
    const reqObj: any = { page: pageNo, recordCountRequire: true };
    if (sortBy) {
      reqObj.sortColumn = sortColumn;
      reqObj.sortBy = sortBy;
    }
    updateRecord(reqObj);
  };
  const onSiteHandleClick = (e: any, siteId: any) => {
    e.preventDefault();
    persistSiteInfoId(siteId);
    navigate(`sitemanager/${siteId}`);
  };
  const onFilterClear = () => {
    if (selectedCompanyId) {
      setDefaultValue({
        page: 1,
        recordCountRequire: selectedView === 'list',
      });
      dispatch(
        actions.updateDashboardFilter({
          ServiceValue: [],
          systemValue: [],
          locationValue: {
            address: '',
            city: '',
            siteName: '',
            state: [],
            zipcode: '',
          },
          ...sortObject,
          systemListValue: [],
        }),
      );
      dispatch(actions.updateDashboardListPageNo(1));
      setCurrentPage(1);
      dispatch(
        actions.fetchFilterSiteData({
          page: 1,
          recordCountRequire: selectedView === 'list',
        }),
      );
      if (checkIEVersion()) {
        setSelectedSystemIE([]);
        setSelectedServiceIE([]);
        setSelectedSystemListIE([]);
      }
    }
  };

  const handleChangeSystemIE = (item: any): void => {
    setSelectedSystemIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { installedSystem: newValue };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({ systemValue: item, ...sortObject }),
      );
      dispatch(actions.updateDashboardListPageNo(1));
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      updateRecord(selectedType);
    }
  };

  const handleChangeServiceIE = (item: any): void => {
    setSelectedServiceIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { serviceLevel: newValue };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({ ServiceValue: item, ...sortObject }),
      );
      dispatch(actions.updateDashboardListPageNo(1));
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      updateRecord(selectedType);
    }
  };
  const handleChangeSystemListIE = (item: any): void => {
    setSelectedSystemListIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { systemLevel: newValue };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({ systemListValue: item, ...sortObject }),
      );
      dispatch(actions.updateDashboardListPageNo(1));
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      updateRecord(selectedType);
    }
  };

  const onSortClick = (e: any, item: any): void => {
    const sortObj: any = {};
    if (item.sortColumn === sortColumn) {
      sortObj.sortColumn = sortColumn;
      const sort = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC;
      sortObj.sortBy = sort;
    } else {
      sortObj.sortColumn = item.sortColumn;
      sortObj.sortBy = SERVICES.includes(item.sortColumn)
        ? SORT_BY.DESC
        : SORT_BY.ASC;
    }
    dispatch(actions.updateDashboardFilter(sortObj));
    updateRecord(sortObj);
  };
  useEffect(() => {
    const obj = {
      page: currentPage,
      recordCountRequire: selectedView === 'list',
      ...defaultDashboardFilter(),
    };
    setDefaultValue(obj);
  }, [selectedView]);

  const applyfilter = (value: any) => {
    if (value) {
      dispatch(actions.updateDashboardFilter(value));
      const data: any = getFilterData(value.locationValue);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord({ ...data, page: 1 });
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item container xs={12} md={10} spacing={2}>
            <Grid item xs={6} md={2}>
              <LocationFilter onfilter={applyfilter} />
            </Grid>
            {!checkIEVersion() ? (
              <>
                <Grid item xs={6} md={3}>
                  <MultipleSelectCheckmarks
                    options={system}
                    handleChange={onHandleChange}
                    value={systemValue}
                    placeholder={CONTEXT.INTSTALLED_SYSTEM}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <MultipleSelectCheckmarks
                    value={ServiceValue}
                    handleChange={onHandleChange}
                    options={service}
                    placeholder={CONTEXT.SERVICE_LEVEL}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <MultipleSelectCheckmarks
                    value={systemListValue}
                    handleChange={onHandleChange}
                    options={systemList}
                    placeholder={CONTEXT.SERVICES}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  <CustomMultiselectIE
                    options={systemOptionIE}
                    key={'installed_system'}
                    handleChange={handleChangeSystemIE}
                    value={selectedSystemIE}
                    placeholder={CONTEXT.INTSTALLED_SYSTEM}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomMultiselectIE
                    value={selectedServiceIE}
                    key={'service_level'}
                    handleChange={handleChangeServiceIE}
                    options={serviceOptionIE}
                    placeholder={CONTEXT.SERVICE_LEVEL}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomMultiselectIE
                    value={selectedSystemListIE}
                    key={'services'}
                    handleChange={handleChangeSystemListIE}
                    options={systemListOptionIE}
                    placeholder={CONTEXT.SERVICES}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6} md={1} sx={{ marginTop: '1px' }}>
              {!isMobile && (
                <IconsButton
                  name={'ClearFilter'}
                  children={<ClearIcon color={themes.palette.icon.main} />}
                  style={{
                    background: themes.palette.primary.main,
                    borderRadius: 4,
                    padding: '6px',
                  }}
                  OnIconClick={onFilterClear}
                  title={CONTEXT.CLEAR_FILTER}
                  isShowTooltip={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} md={2} justifyContent="flex-end">
            {isMobile && (
              <IconsButton
                name={'ClearFilter'}
                children={<ClearIcon color={themes.palette.icon.main} />}
                style={{
                  background: themes.palette.primary.main,
                  borderRadius: 4,
                  padding: '0.35rem',
                }}
                OnIconClick={onFilterClear}
                title={CONTEXT.CLEAR_FILTER}
                isShowTooltip={true}
              />
            )}
            <FilterMenu onhandleChange={onFilterMenuClick} />
          </Grid>
        </Grid>
        <PanelGrid container item pt={1} theme={themes}>
          {selectedView === 'map' && (
            <CustomMaps
              markerResponseData={
                MapViewData && MapViewData.length > 0 ? MapViewData : []
              }
              type={CONTEXT.SITE}
              loading={selectorSiteData.loading}
            />
          )}
          {selectedView === 'list' && (
            <Listview
              headerItems={SITE_HEADER}
              name={CONTEXT.SITE}
              listData={
                ListViewData && ListViewData.length > 0 ? ListViewData : []
              }
              totalRecords={TotalListCount}
              onPageChange={onPagination}
              currentPage={currentPage}
              loading={selectorSiteData.loading}
              onSiteHandle={onSiteHandleClick}
              CustomHeight={490}
              isSort={isSort}
              onSortClick={onSortClick}
            />
          )}
        </PanelGrid>
      </Box>
    </>
  );
}

export default React.memo(Sites);
