/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper, ItemHeader, MessageTemplate } from '../QuickStats/styles';
import { LeftArrowIcon, Cancel, RightArrowIcon } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import { getBannerNotification } from 'app/features/GlobalSlice/Selectors';
import { getUserId } from 'app/features/authService';
import { removeFromObj } from 'utils/CommonFn';
import { CustomBadge } from 'app/component/core/Charts/style';
import OverflowTip from 'app/component/core/CustomFixedListView/OverflowTip';
import { COLORS } from 'styles/colors';
import { Box, Grid } from '@mui/material';
import { getCompanyId } from 'app/features/DashboardSlice/Selectors';
import { useTheme } from '@mui/material/styles';
import { CustomDivider } from '../Tabs/styles';
import { actions } from 'app/features/GlobalSlice/slice';
import { GridContainer } from 'app/pages/Partner/businessIntelligence/style';

function Banner(): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectorBannerNotification: any = useSelector(getBannerNotification);
  const selectorNotification = selectorBannerNotification.data || [];
  const selectedCompanyId = useSelector(getCompanyId);
  const [BannerNotification, setBannerNotification] =
    useState(selectorNotification);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [isBannerSlide, setIsBannerSlide] = useState(false);
  const onNotificationClick = (item: any): void => {
    const userId = getUserId();
    if (userId) {
      if (!item.Read) {
        setBannerNotification(
          removeFromObj(BannerNotification, 'NotificationId', [
            item.NotificationId,
          ]),
        );
        if (BannerNotification.length - 1 === bannerIndex) {
          setBannerIndex(bannerIndex - 1);
        }
        dispatch(
          actions.postReadUserBannerNotification({
            notificationId: item.NotificationId,
            companyId: selectedCompanyId,
            userId,
          }),
        );
      }
    }
  };
  const onPrev = (idx: number): any => {
    if (idx !== -1 && idx <= BannerNotification.length) {
      setBannerIndex(idx);
    }
  };
  const onNext = (idx: number): any => {
    if (idx < BannerNotification.length) {
      setBannerIndex(idx);
    }
  };

  useEffect(() => {
    if (selectorBannerNotification) {
      setBannerNotification(selectorNotification);
    }
  }, [selectorBannerNotification]);

  useEffect(() => {
    if (BannerNotification.length > 0 && !isBannerSlide) {
      const interval = setInterval(() => {
        setBannerIndex(
          bannerIndex === BannerNotification.length - 1 ? 0 : bannerIndex + 1,
        );
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [bannerIndex, isBannerSlide, BannerNotification]);

  return (
    BannerNotification &&
    BannerNotification.length > 0 &&
    BannerNotification.map((it: any, index: number) => {
      return (
        !it.Read &&
        index === bannerIndex && (
          <Wrapper
            theme={theme}
            onMouseEnter={() => {
              setIsBannerSlide(true);
            }}
            onMouseLeave={() => {
              setIsBannerSlide(false);
            }}
          >
            <ItemHeader theme={theme}>
              <Grid container justifyContent={'space-between'}>
                <Grid
                  sx={{
                    width: '75vw',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <OverflowTip text={it.Title} />
                </Grid>
                <Grid>
                  <IconsButton
                    name={'close'}
                    children={<Cancel />}
                    OnIconClick={() => {
                      onNotificationClick(it);
                    }}
                    isShowTooltip={true}
                    title="Close"
                    styleTooltip={{ width: 'auto', height: 'auto' }}
                  />
                </Grid>
              </Grid>
            </ItemHeader>
            <CustomDivider variant="fullWidth" theme={theme} />
            <Box sx={{ flexGrow: 1 }} p={1}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <IconsButton
                    name={'prev'}
                    children={<LeftArrowIcon />}
                    OnIconClick={() => {
                      onPrev(index - 1);
                    }}
                    isShowTooltip={true}
                    title="Prev"
                    styleTooltip={{
                      width: 'auto',
                      height: 'auto',
                      opacity: bannerIndex === 0 ? 0.4 : 1,
                    }}
                  />
                </Grid>
                <MessageTemplate
                  item
                  xs={11}
                  sm={10}
                  theme={theme}
                  isHighlight={isBannerSlide}
                >
                  {it.Message}
                </MessageTemplate>
                <Grid
                  item
                  xs
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <IconsButton
                    name={'next'}
                    children={<RightArrowIcon />}
                    OnIconClick={() => {
                      onNext(index + 1);
                    }}
                    isShowTooltip={true}
                    title="Next"
                    styleTooltip={{
                      width: 'auto',
                      height: 'auto',
                      opacity:
                        bannerIndex === BannerNotification.length - 1 ? 0.4 : 1,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            {BannerNotification.length > 1 && (
              <GridContainer theme={theme} container justifyContent={'center'}>
                <Grid item sx={{ flexDirection: 'row', display: 'flex' }}>
                  {Array.apply(0, Array(BannerNotification.length)).map(
                    (x, i) => {
                      return (
                        <Grid
                          container
                          flexDirection={'row'}
                          sx={{
                            paddingLeft: 1,
                            paddingRight: 1,
                          }}
                        >
                          <CustomBadge
                            badgecolor={
                              i === bannerIndex
                                ? theme.palette.mode === 'dark'
                                  ? COLORS.white
                                  : COLORS.grey
                                : theme.palette.neutral.main
                            }
                            variant="dot"
                          />
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              </GridContainer>
            )}
          </Wrapper>
        )
      );
    })
  );
}
export default Banner;
