export default function SlashIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53125 17.1716C13.6734 17.1716 17.0312 13.8138 17.0312 9.67163C17.0312 5.5295 13.6734 2.17163 9.53125 2.17163C5.38911 2.17163 2.03125 5.5295 2.03125 9.67163C2.03125 13.8138 5.38911 17.1716 9.53125 17.1716Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.22852 4.36913L14.8335 14.9741"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
