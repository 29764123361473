import { Grid } from '@mui/material';
import { PrimaryButton } from 'app/component/core/Buttons';
import { ROUTE_PATH } from 'app/pages/Router/constant';
import { useNavigate } from 'react-router';
import { CenterWrapper } from '../styles';
import { ERROR_TEXT } from 'utils/Constants/Content';

export function NotFoundPage(): JSX.Element {
  const navigate = useNavigate();

  const onGotoHome = (): void => {
    navigate(`/${ROUTE_PATH.DASHBOARD}`);
  };
  return (
    <CenterWrapper container>
      <Grid item p={4}>
        {ERROR_TEXT.PAGE_NOT_FOUND}
      </Grid>
      <Grid item>
        <PrimaryButton
          onClick={onGotoHome}
          children={'GO TO DASHBOARD'}
          fullWidth={false}
          disabled={false}
        />
      </Grid>
    </CenterWrapper>
  );
}
