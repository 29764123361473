export const STORE_KEY: any = {
  VIEW: 'view',
  MODE: 'mode',
  AD_TOKEN: 'token',
  IM_TOKEN: 'imToken',
  IMUSER_ID: 'imUserID',
  USER_TOKEN: 'userToken',
  COMPANY_ID: 'companyId',
  SITE_ID: 'SiteId',
  SITE_INFO_ID: 'SiteInfoId',
  SITE_COMPANY_ID: 'companyIdForSite',
  SESSION_EXPIRED_IN: 'expiredIn',
  NETWORK_PROJECTOR_FILTER: 'networkProjectorFilter',
  SR_SITE_DATA: 'ExSRdetail',
};
