import { useTheme } from '@mui/material/styles';

export default function MarkerIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2306_7833)">
        <path
          d="M21 10.795C21 17.795 12 23.795 12 23.795C12 23.795 3 17.795 3 10.795C3 8.40803 3.94821 6.11885 5.63604 4.43102C7.32387 2.74319 9.61305 1.79498 12 1.79498C14.3869 1.79498 16.6761 2.74319 18.364 4.43102C20.0518 6.11885 21 8.40803 21 10.795Z"
          stroke={theme.palette.icon.main}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 13.795C13.6569 13.795 15 12.4518 15 10.795C15 9.13813 13.6569 7.79498 12 7.79498C10.3431 7.79498 9 9.13813 9 10.795C9 12.4518 10.3431 13.795 12 13.795Z"
          stroke={theme.palette.icon.main}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2306_7833">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.794983)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
