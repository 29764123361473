/* eslint-disable @typescript-eslint/no-floating-promises */
import { Grid } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { LoginContainer } from './styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'app/component/layout/Loader/Loader';
import { getTokenKey, getUserId } from 'app/features/authService';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/features/GlobalSlice/slice';
import { actions as AdminSlice } from 'app/features/AdminSlice/slice';
import { getUser } from 'app/features/TokenUtils';
import { globalConfig } from 'appconfig';
import { getUserSystems } from 'app/features/GlobalSlice/Selectors';
import { ROUTE_PATH } from '../Router/constant';
import { ERROR_TEXT } from 'utils/Constants/Content';
import { isEmpty } from 'utils/CommonFn/validators';

export function Login(): JSX.Element {
  const config = globalConfig.getDetails();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { instance, inProgress } = useMsal();
  const hasSystemAccess = useSelector(getUserSystems);

  const isToken = getTokenKey();
  const authToken = !!isToken;

  const onNavigatetToHome = (): void => {
    navigate(`/${ROUTE_PATH.DASHBOARD}`, { replace: true });
  };
  const onLogout = (): void => {
    navigate(`/${ROUTE_PATH.LOGOUT}`, { replace: true });
  };
  const onLogin = async () => {
    if (inProgress === 'none') {
      await instance
        .loginRedirect({
          scopes: [config.REACT_APP_AZURE_ACTIVE_DIRECTORY_SCOPE],
        })
        .catch(() => onLogout());
    }
  };
  const onError = (message?: any) => {
    dispatch(
      AdminSlice.displayToast({
        toastMessage: message ?? ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    setTimeout(() => onLogout(), 500);
  };
  const fetchSystems = () => {
    if (!hasSystemAccess.loading && hasSystemAccess.data.length === 0) {
      dispatch(actions.fetchUserSystems({}));
    }
  };
  if (!authToken) onLogin();

  useEffect(() => {
    if (authToken) {
      if (getUserId()) {
        fetchSystems();
        onNavigatetToHome();
      } else {
        setLoading(true);
        getUser().then((result: any) => {
          setLoading(false);
          if (result?.status === 'error') {
            onError(result?.data?.message);
          } else if (result?.status === 'success') {
            const token = result?.data?.[0]?.tokens ?? null;
            if (authToken && !isEmpty(token)) {
              fetchSystems();
              onNavigatetToHome();
            } else {
              onError(result?.data?.message);
            }
          }
        });
      }
    }
  }, [authToken]);
  return (
    <>
      <LoginContainer>
        <Grid container style={{ height: '100vh' }}>
          {(!authToken || inProgress !== 'none' || loading) && <Loader />}
        </Grid>
      </LoginContainer>
    </>
  );
}

export default Login;
