/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useState } from 'react';
import {
  PaginationWrapper,
  PaginationContainer,
  MuiPagination,
  PaginationLabel,
} from './style';
import { PaginationProps } from './type';
import { useTheme } from '@mui/material';
import { CONTEXT } from 'utils/Constants/Content';

const Pagination = ({
  totalRecords,
  currentPage,
  setCurrentPage,
  loading,
  recordLimit = CONTEXT.DEFAULT_PAGE_LIMIT,
  style = null,
}: PaginationProps) => {
  const [noOfPages, setNoOfPages] = useState(CONTEXT.DEFAULT_PAGE_LIMIT);
  const [range, setRange] = useState<any>();
  const theme = useTheme();
  const getRange = (pageNo: any) => {
    const lastNo = Math.min(pageNo * recordLimit);
    const count = totalRecords < lastNo ? totalRecords : lastNo;
    return (pageNo - 1) * recordLimit + 1 + '-' + count;
  };
  useEffect(() => {
    if (totalRecords > 0) {
      setNoOfPages(Math.ceil(totalRecords / recordLimit));
      setRange(getRange(currentPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecords]);
  return (
    <PaginationWrapper container sx={style}>
      <PaginationLabel item theme={theme}>
        {!loading && totalRecords > 0 && (
          <>
            Showing {range} of {totalRecords}
          </>
        )}
      </PaginationLabel>
      {totalRecords > 0 && !loading && (
        <PaginationContainer item>
          <MuiPagination
            count={noOfPages}
            shape="rounded"
            classes={{
              root: '.root',
            }}
            page={currentPage}
            onChange={(_event, value: number) => {
              setRange(getRange(value));
              setCurrentPage(value);
            }}
          />
        </PaginationContainer>
      )}
    </PaginationWrapper>
  );
};

export default Pagination;
