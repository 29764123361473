import { useMsal } from '@azure/msal-react';
import Loader from 'app/component/layout/Loader/Loader';
import {
  clearImpersonateSession,
  getUserId,
  isImpersonateUser,
  userLogout,
} from 'app/features/authService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../Router/constant';
import { STORE_KEY } from 'store/Constant';
import { actions } from 'app/features/AdminSlice/slice';
import { useDispatch, useSelector } from 'react-redux';
import { getCloseImpersonateSession } from 'app/features/AdminSlice/Selectors';

export function Logout(): JSX.Element {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isimpersonate = isImpersonateUser() ?? false;
  const closeSessionStatus = useSelector(getCloseImpersonateSession);

  useEffect(() => {
    if (isimpersonate) {
      dispatch(
        actions.closeImpersonateUserSession({
          userIdToImpersonate: getUserId(STORE_KEY.IM_TOKEN),
          userId: getUserId(STORE_KEY.USER_TOKEN),
        }),
      );
    } else {
      const logout = async () => {
        await instance.logoutRedirect({
          onRedirectNavigate() {
            userLogout();
          },
        });
      };
      logout().catch(r => {
        userLogout();
        navigate(`/${ROUTE_PATH.LOGIN}`, { replace: true });
      });
    }
  }, []);

  useEffect(() => {
    if (isimpersonate) {
      if (closeSessionStatus.data?.success) clearImpersonateSession();
    }
  }, [isimpersonate ? closeSessionStatus : null]);

  return <Loader size={100} maxHeight={true} />;
}
