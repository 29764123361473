import { useTheme } from '@mui/material/styles';

export default function HikvisionCentralIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="200"
      height="26"
      viewBox="0 0 200 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.002 11.7375C107.565 7.27107 110.343 0.314186 116.62 0.231856H129.378L128.226 3.33981H121.476C120.159 3.33981 119.212 3.33981 117.916 3.33981C116.208 3.36039 115.241 5.45981 116.208 6.73592C117.793 9.18524 120.509 10.8936 122.196 13.4046C125.88 18.365 122.155 24.8279 116.661 24.8279H102.647L103.799 21.72H111.413C112.73 21.72 113.574 21.72 114.85 21.72C116.558 21.6994 117.504 19.6 116.62 18.3444C115.22 16.0598 112.73 13.8575 111.002 11.7375ZM98.0575 24.8691C95.5881 24.8691 93.5508 24.8691 91.1019 24.8691L100.177 0.252439H107.133L98.0575 24.8691ZM132.321 24.8691C129.852 24.8691 127.814 24.8691 125.345 24.8691L134.42 0.252439H141.376L132.321 24.8691ZM142.857 7.7033C145.286 2.57826 150.389 -0.138628 155.122 0.00544902H156.954H159.444C163.086 0.170109 165.288 3.17515 165.494 5.66563C165.535 6.26252 165.515 6.83883 165.453 7.27107C165.391 7.55922 165.329 7.82679 165.268 8.11495C164.506 11.2846 163.354 14.4955 161.851 17.3976C159.423 22.5227 154.32 25.2396 149.587 25.0955H147.755H147.735H145.265C141.623 24.9308 139.421 21.9258 139.236 19.4353C139.194 18.8384 139.194 18.2827 139.277 17.8299C139.338 17.5623 139.4 17.2742 139.462 16.986C140.182 13.8163 141.335 10.6054 142.857 7.7033ZM156.028 2.55768C153.455 2.64001 151.295 4.10136 150.369 6.50951L145.759 19.0442C145.635 19.3324 145.574 19.6617 145.594 19.991C145.594 21.3289 146.644 22.4404 147.94 22.5433H148.619C151.192 22.4609 153.353 20.9996 154.279 18.5914L158.868 6.0567C158.991 5.76854 159.053 5.43923 159.032 5.10991C159.032 3.77204 157.983 2.66059 156.666 2.55768H156.028ZM81.9238 17.1712L93.2421 0.252439H97.3373L81.5123 23.84C81.1007 24.4574 80.4216 24.8485 79.6396 24.8897H79.4955C79.475 24.8897 79.4338 24.8897 79.4132 24.8897H79.4338H79.1869H76.7586H75.565L73.6306 0.273021H80.5862L81.9238 17.1712ZM172.82 8.19728L172.799 7.88854L166.523 24.8485H162.942L172.059 0.231856H176.606L183.171 16.8625L189.324 0.231856H192.925L183.809 24.8485H180.516C179.611 24.8073 178.829 24.231 178.479 23.4283L172.82 8.19728Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M35.7658 24.869C33.2963 24.869 30.8269 24.869 28.3574 24.869L37.4532 0.252394H37.659H44.6352H44.841L35.7658 24.869ZM12.9646 9.72034H21.7723L25.2706 0.231812H32.6584L24.1388 23.3459C24.1388 23.3459 24.1388 23.3665 24.1182 23.3665C23.7684 24.231 22.9452 24.8279 21.9575 24.8485C20.0231 24.8485 18.1093 24.8485 16.1749 24.8485L20.517 13.0753H11.7299L7.38775 24.8485C4.91831 24.8485 2.46944 24.8485 0 24.8485L9.07521 0.231812H16.463L12.9646 9.72034ZM53.7721 9.49393L65.8313 0.231812H71.3464L59.0197 9.69975L66.8396 24.8485H60.5631C60.5837 24.8485 60.6043 24.8485 60.6248 24.8279C59.6576 24.8896 58.7728 24.3339 58.3612 23.5106C58.3612 23.5106 58.3612 23.49 58.3406 23.49V23.5106L53.4429 14.0015L51.5908 15.4217L48.113 24.869C45.6436 24.869 43.1741 24.869 40.7252 24.869L49.8005 0.252394H57.1882L53.7721 9.49393Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M195.621 4.32776V1.19922H197C197.288 1.19922 197.494 1.2198 197.638 1.28155C197.782 1.3433 197.905 1.44621 197.988 1.5697C198.07 1.71378 198.111 1.87844 198.111 2.0431C198.111 2.26951 198.049 2.45475 197.905 2.59883C197.761 2.74291 197.535 2.84582 197.247 2.88698C197.35 2.94873 197.432 2.98989 197.494 3.05164C197.617 3.15455 197.72 3.29863 197.844 3.46329L198.379 4.30717H197.864L197.453 3.64853C197.329 3.46329 197.226 3.31921 197.144 3.2163C197.062 3.11339 197 3.05164 196.938 3.01048C196.876 2.96931 196.815 2.94873 196.753 2.92815C196.712 2.92815 196.629 2.90756 196.527 2.90756H196.033V4.30717H195.621V4.32776ZM196.033 2.57825H196.918C197.103 2.57825 197.247 2.55766 197.37 2.5165C197.473 2.47533 197.555 2.41359 197.617 2.33126C197.679 2.24893 197.7 2.14601 197.7 2.0431C197.7 1.89902 197.638 1.77553 197.535 1.67262C197.432 1.5697 197.267 1.52854 197.02 1.52854H196.033V2.57825Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M196.918 0.149536C195.457 0.149536 194.263 1.34332 194.263 2.80468C194.263 4.26603 195.457 5.45982 196.918 5.45982C198.379 5.45982 199.572 4.26603 199.572 2.80468C199.572 1.34332 198.379 0.149536 196.918 0.149536ZM196.918 5.15108C195.621 5.15108 194.572 4.10137 194.572 2.80468C194.572 1.50798 195.621 0.458273 196.918 0.458273C198.214 0.458273 199.264 1.50798 199.264 2.80468C199.264 4.10137 198.214 5.15108 196.918 5.15108Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
