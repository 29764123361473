/* eslint-disable array-callback-return */
import { Grid } from '@mui/material';
import {
  getSiteManager,
  getViewType,
} from 'app/features/DashboardSlice/Selectors';
import { actions } from 'app/features/DashboardSlice/slice';
import { ExportIcon, ListIcon, MapIcon, RoundTick } from 'assets/component';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'styles/colors';
import { getFilterName } from 'utils/CommonFn';
import { IconsButton } from '../Buttons';
import { FILTER_MENU } from './Contstant';
import { MenuProps } from './type';
import { saveOnLocalStorage } from 'store/localStore';
import { useNavigate } from 'react-router-dom';
import { actions as adminAction } from 'app/features/AdminSlice/slice';
import { getUserPermissions } from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';
import { STORE_KEY } from 'store/Constant';
import { ROUTE_PATH } from 'app/pages/Router/constant';
interface Props {
  onhandleChange: any;
  isShowExport?: boolean;
}
const TEMP_FILTER: MenuProps[] = [
  {
    name: '',
    icon: <></>,
    isSelected: false,
  },
];

export function FilterMenu({
  onhandleChange,
  isShowExport = true,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const viewType = useSelector(getViewType);
  const navigate = useNavigate();
  const [filterMenu, setFilterMenu] = useState(TEMP_FILTER);
  const isSiteManager: any = useSelector(getSiteManager);
  const isReportAccess = getUserPermissions(USER_PERMISSIONS.REPORT_NAVIGATION);

  useEffect(() => {
    if (isSiteManager) {
      const newFilter: any = FILTER_MENU.filter(
        menu => menu.name === 'resolved',
      );
      setFilterMenu(newFilter);
      // getFilterData(FILTER_MENU, "resolved");
    } else {
      getFilterData(FILTER_MENU, viewType);
    }
  }, [isSiteManager]);

  const onChange = (selectedItem: any): void => {
    if (selectedItem !== 'resolved') {
      saveOnLocalStorage(STORE_KEY.VIEW, selectedItem);
      dispatch(actions.updateViewType(selectedItem));
    }
    getFilterData(filterMenu, selectedItem);
    onhandleChange(selectedItem);
  };

  const getFilterData = (data: any, view: any): any => {
    if (data && data.length > 0 && view) {
      const getIcon = (name: string, colorCode: string) => {
        switch (name) {
          case 'map':
            return <MapIcon color={colorCode} />;
          case 'list':
            return <ListIcon color={colorCode} />;
          case 'resolved':
            return <RoundTick color={colorCode} />;
          default:
            break;
        }
      };
      data.map((item: any) => {
        const selectedColor =
          item.name === view ? COLORS.white : COLORS.royalBlue;
        item.isSelected = false;
        item.icon = getIcon(item.name, selectedColor);

        if (item.name === view) {
          item.isSelected = true;
          item.icon = getIcon(view, selectedColor);
        }
      });
      setFilterMenu([...data]);
    }
  };
  const onExportClick = () => {
    if (isReportAccess) {
      navigate(
        { pathname: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.EXPORT_REPORT}` },
        { state: { isFilterActive: true } },
      );
    } else {
      dispatch(adminAction.checkUserAccessPermission(true));
    }
  };

  return (
    <Grid item>
      <Grid container justifyContent="flex-end" pb={1}>
        {Boolean(filterMenu) &&
          filterMenu?.map((item: MenuProps) => {
            return (
              <div style={{ marginLeft: 4 }}>
                <IconsButton
                  name={item.name}
                  key={Math.random()}
                  children={item.icon}
                  style={{
                    background:
                      item.isSelected ?? false ? COLORS.lightRed : COLORS.white,
                    borderRadius: 4,
                  }}
                  OnIconClick={onChange}
                  title={getFilterName(item.name)}
                  isShowTooltip={true}
                />
              </div>
            );
          })}
        {Boolean(filterMenu) &&
          viewType === 'list' &&
          !isSiteManager &&
          isShowExport && (
            <div style={{ marginLeft: 4 }}>
              <IconsButton
                name={'Export Data'}
                key={Math.random()}
                children={<ExportIcon />}
                style={{
                  background: COLORS.white,
                  borderRadius: 4,
                  padding: '0px',
                  opacity: isReportAccess ? 1 : 0.6,
                }}
                OnIconClick={onExportClick}
                title={'Export Data'}
                isShowTooltip={true}
              />
            </div>
          )}
      </Grid>
    </Grid>
  );
}
export default memo(FilterMenu);
