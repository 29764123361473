import CustomInputLocationFilter from 'app/component/common/LocationFilter/CustomInputLocationFilter';
import CustomInput from 'app/component/core/CustomInputs/customInputsDashboard';
import { memo, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')``;

export const LocationFilter = ({ onfilter }: any): JSX.Element => {
  const spanRef: any = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const [selectedType, setSelectedType] = useState<boolean>(false);

  const onInputChange = (): void => {
    if (spanRef.current !== null) {
      setAnchorEl(spanRef.current);
    }
  };
  const props = { spanRef, anchorEl, setAnchorEl, setSelectedType, onfilter };
  return (
    <Wrapper ref={spanRef}>
      <CustomInput
        value=""
        label="Location"
        endAdornmentType={'filter'}
        onClick={onInputChange}
        selectedTypes={selectedType}
        autofocus={false}
      />

      <CustomInputLocationFilter {...props} />
    </Wrapper>
  );
};
export default memo(LocationFilter);
