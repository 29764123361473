import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { ChronicListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import CustomTooltip from '../Tooltip';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import { COLORS } from 'styles/colors';
import { DeleteIcon } from 'assets/component';
import { getUserPermissions } from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';

function ChronicRowItem({
  key,
  rowItem,
  onEditIconClick,
  onDeleteIconClick,
}: ChronicListProps): any {
  const theme = useTheme();
  const isHasPermission = getUserPermissions(USER_PERMISSIONS.CHRONIC_PROFILE);
  return (
    <TableRow container key={key} alignContent="center" theme={theme}>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.smb_code || ''}></OverflowTip>
      </TableCell>
      <TableCell item xs={5}>
        <OverflowTip text={rowItem?.smb_rule || ''}></OverflowTip>
      </TableCell>

      <TableCell item xs={1}>
        <OverflowTip text={rowItem?.param_1 || ''}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem?.param_2 || ''}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.param_3 || ''}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <CustomTooltip title={'Edit'}>
          <div>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity: !isHasPermission ? 0.6 : 1,
              }}
              OnIconClick={() => onEditIconClick(rowItem)}
            />
          </div>
        </CustomTooltip>
        <CustomTooltip title={'Delete'}>
          <div>
            <IconsButton
              name="delete"
              children={<DeleteIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity: !isHasPermission ? 0.6 : 1,
              }}
              OnIconClick={() => onDeleteIconClick(rowItem)}
            />
          </div>
        </CustomTooltip>
      </TableCell>
    </TableRow>
  );
}
export default ChronicRowItem;
