import { Fragment } from 'react';
import CustomTooltip from '../Tooltip';
import { ButtonWrapper } from './style';
import { PrimaryButtonProps } from './type';

function PrimaryButton({
  width = 'auto',
  type = 'button',
  fullWidth = false,
  customHeight,
  children,
  className,
  style,
  onClick,
  disabled = false,
  tooltipText = '',
  placement,
}: PrimaryButtonProps) {
  return (
    <ButtonWrapper
      type={type}
      width={width}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={className}
      fullWidth={fullWidth}
      customHeight={customHeight}
    >
      <CustomTooltip title={tooltipText}>
        <Fragment>{children}</Fragment>
      </CustomTooltip>
    </ButtonWrapper>
  );
}

export default PrimaryButton;
