import { useTheme } from '@mui/material/styles';

export default function BoschAccessControlIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.3205 144.933H16.9526C10.5387 144.933 5.33905 139.734 5.33905 133.32V39.7989C5.33905 33.385 10.5387 28.1853 16.9526 28.1853H72.3205C78.7345 28.1853 83.9341 33.385 83.9341 39.7989V62.4316"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.3956 134.12H20.9772C18.6293 134.12 16.7259 132.082 16.7259 129.568V57.1556C16.7259 54.6413 18.6293 52.6034 20.9772 52.6034H68.296C70.6439 52.6034 72.5473 54.6416 72.5473 57.1556V59.9423"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.349 37.3819H40.8926"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.269 37.3819H50.8125"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.2502 73.1997L54.3551 67.1399C51.7315 66.697 49.2457 68.4647 48.803 71.0883L39.5484 125.906C39.1055 128.53 40.8732 131.016 43.4968 131.458L79.3919 137.518C82.0154 137.961 84.5013 136.193 84.9439 133.57L94.1982 78.7518C94.6415 76.1285 92.8737 73.6427 90.2502 73.1997ZM77.5421 84.9738L63.9987 82.6875C62.4059 82.4186 61.3324 80.9092 61.6014 79.3163C61.8703 77.7234 63.3797 76.65 64.9726 76.9189L78.5159 79.2053C80.1088 79.4742 81.1822 80.9836 80.9133 82.5765C80.6443 84.1693 79.135 85.2428 77.5421 84.9738Z"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.32 44.978H112.068C110.618 44.978 109.443 43.8026 109.443 42.3524V29.5904C109.443 28.1405 110.618 26.9648 112.068 26.9648H126.32C127.77 26.9648 128.945 28.1402 128.945 29.5904V42.3524C128.945 43.8026 127.77 44.978 126.32 44.978Z"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M113.343 26.9651V19.8797C113.343 16.6482 115.963 14.0288 119.194 14.0288C121.38 14.0288 123.285 15.227 124.29 17.0024"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M128.945 6.02109C125.929 4.82138 122.638 4.16191 119.194 4.16191C104.612 4.16191 92.7902 15.9832 92.7902 30.5657C92.7902 36.1307 94.5132 41.2922 97.4528 45.5493L94.3837 54.3896L103.581 51.859C107.955 55.0708 113.352 56.9695 119.194 56.9695C133.777 56.9695 145.598 45.1482 145.598 30.5657"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M137.655 22.3945L146.596 20.6241"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M133.548 14.8022L139.244 7.68573"
        stroke={theme.palette.icon.menu}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
