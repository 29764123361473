import { useTheme } from '@mui/material/styles';

export default function OnlineManualIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15H40C45.3043 15 50.3914 17.1071 54.1421 20.8579C57.8929 24.6086 60 29.6957 60 35V105C60 101.022 58.4196 97.2064 55.6066 94.3934C52.7936 91.5804 48.9782 90 45 90H10V15Z"
        stroke={theme.palette.icon.main}
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M110 15H80C74.6957 15 69.6086 17.1071 65.8579 20.8579C62.1071 24.6086 60 29.6957 60 35V105C60 101.022 61.5804 97.2064 64.3934 94.3934C67.2064 91.5804 71.0218 90 75 90H110V15Z"
        stroke={theme.palette.icon.main}
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
