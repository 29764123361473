import { lazy, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { DashboardContainer } from './styles';
const SideBar = lazy(async () => await import('./Sidebar'));
const Navbar = lazy(async () => await import('./Navbar'));
const CustomDrawer = lazy(async () => await import('./Drawer'));
const CustomBreadcrumb = lazy(async () => await import('../core/Breadcrumb'));

export function Layout({ children, mode, onChange, theme }: any): JSX.Element {
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const hideShow = () => {
    setIsShowSideBar(!isShowSideBar);
  };
  return (
    <DashboardContainer container spacing={2.5}>
      <Grid item sm={12} lg={2} md={2}>
        {isSmallerScreen ? (
          <CustomDrawer
            toggleDetailsDrawer={isSmallerScreen && isShowSideBar}
            children={<SideBar theme={theme} />}
            setToggleDetailsDrawer={hideShow}
          />
        ) : (
          <SideBar theme={theme} />
        )}
      </Grid>
      <Grid item xs={12} sm={12} lg={10} md={12}>
        <Grid container py={isSmallerScreen ? 0 : 3}>
          <Navbar
            mode={mode}
            setMode={onChange}
            theme={theme}
            hideShow={hideShow}
          />
        </Grid>
        {!isSmallerScreen && <CustomBreadcrumb />}
        <Grid container spacing={2} pr={isSmallerScreen ? 0 : 2}>
          {children}
        </Grid>
      </Grid>
    </DashboardContainer>
  );
}
