import { useTheme } from '@mui/material/styles';

export default function VisualInsightsIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3917 63.535L25.8517 47.07L37.3167 58.535C37.7806 58.9997 38.3317 59.3684 38.9382 59.62C39.5448 59.8715 40.195 60.001 40.8517 60.001C41.5084 60.001 42.1586 59.8715 42.7652 59.62C43.3717 59.3684 43.9228 58.9997 44.3867 58.535L86.9917 15.925L95.8517 25V0H71.4767L80.0217 8.76L40.8517 47.93L29.3917 36.465C28.9278 36.0003 28.3767 35.6316 27.7702 35.38C27.1636 35.1285 26.5134 34.999 25.8567 34.999C25.2 34.999 24.5498 35.1285 23.9432 35.38C23.3367 35.6316 22.7856 36.0003 22.3217 36.465L2.3217 56.465C1.38416 57.4025 0.857452 58.6741 0.857452 60C0.857452 61.3259 1.38416 62.5975 2.3217 63.535C3.25924 64.4725 4.53082 64.9992 5.8567 64.9992C7.18258 64.9992 8.45416 64.4725 9.3917 63.535ZM50.8517 60H60.8517V95H50.8517V60ZM75.8517 62.11V45H65.8517V70.135C68.4307 66.6362 71.8774 63.8702 75.8517 62.11ZM80.8517 60.505C84.1519 59.8399 87.5515 59.8399 90.8517 60.505V35H80.8517V60.505ZM119.387 111.465L103.027 95.105C104.86 92.052 105.836 88.5611 105.852 85C105.852 81.0444 104.679 77.1776 102.481 73.8886C100.283 70.5996 97.1599 68.0362 93.5054 66.5224C89.8508 65.0087 85.8295 64.6126 81.9499 65.3843C78.0703 66.156 74.5066 68.0608 71.7096 70.8579C68.9125 73.6549 67.0077 77.2186 66.236 81.0982C65.4643 84.9778 65.8604 88.9991 67.3741 92.6537C68.8879 96.3082 71.4513 99.4318 74.7403 101.629C78.0293 103.827 81.8961 105 85.8517 105C89.5517 105 92.9817 103.925 95.9567 102.175L112.317 118.535C112.78 119.001 113.331 119.371 113.937 119.623C114.544 119.875 115.195 120.005 115.852 120.005C116.509 120.005 117.159 119.875 117.766 119.623C118.373 119.371 118.923 119.001 119.387 118.535C119.851 118.071 120.22 117.52 120.472 116.913C120.723 116.307 120.853 115.657 120.853 115C120.853 114.343 120.723 113.693 120.472 113.087C120.22 112.48 119.851 111.929 119.387 111.465ZM70.8517 85C70.8517 76.73 77.5817 70 85.8517 70C94.1217 70 100.852 76.73 100.852 85C100.852 93.27 94.1217 100 85.8517 100C77.5817 100 70.8517 93.27 70.8517 85Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
