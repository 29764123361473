import { useTheme } from '@mui/material/styles';

export default function ServiceRequestIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 103 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.6125 25.5731C61.6887 26.5156 61.1713 27.7826 61.1713 29.1023C61.1713 30.422 61.6887 31.689 62.6125 32.6315L70.6792 40.6981C71.6216 41.6219 72.8887 42.1393 74.2083 42.1393C75.528 42.1393 76.7951 41.6219 77.7375 40.6981L96.7446 21.6911C99.2797 27.2933 100.047 33.5351 98.9451 39.5847C97.8428 45.6342 94.9231 51.2042 90.575 55.5523C86.2269 59.9004 80.6569 62.8201 74.6074 63.9224C68.5578 65.0246 62.316 64.257 56.7138 61.7219L21.8758 96.5598C19.8701 98.5655 17.1498 99.6923 14.3133 99.6923C11.4768 99.6923 8.75654 98.5655 6.75084 96.5598C4.74514 94.5541 3.61835 91.8338 3.61835 88.9973C3.61835 86.1608 4.74514 83.4405 6.75084 81.4348L41.5888 46.5969C39.0536 40.9946 38.286 34.7528 39.3883 28.7033C40.4905 22.6537 43.4102 17.0838 47.7583 12.7357C52.1064 8.38755 57.6764 5.46782 63.726 4.36558C69.7755 3.26334 76.0173 4.03093 81.6196 6.56605L62.6629 25.5227L62.6125 25.5731Z"
        stroke={theme.palette.icon.main}
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
