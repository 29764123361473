import { useTheme } from '@mui/material/styles';

export default function PaymentIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      xmlns="
http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="-4 -2 20 20"
      fill="none"
    >
      <path
        d="M6 1V17"
        stroke={theme.palette.icon.main}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1667 3.90906H3.91667C3.14312 3.90906 2.40125 4.17724 1.85427 4.6546C1.30729 5.13197 1 5.77942 1 6.45451C1 7.12961 1.30729 7.77706 1.85427 8.25442C2.40125 8.73179 3.14312 8.99997 3.91667 8.99997H8.08333C8.85688 8.99997 9.59875 9.26815 10.1457 9.74551C10.6927 10.2229 11 10.8703 11 11.5454C11 12.2205 10.6927 12.868 10.1457 13.3453C9.59875 13.8227 8.85688 14.0909 8.08333 14.0909H1"
        stroke={theme.palette.icon.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
