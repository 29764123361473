import { useTheme } from '@mui/material/styles';

export default function DashboardIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.9366 8.12727C17.404 4.84944 14.6199 2.4109 11.3005 2.31491C11.1426 2.30872 10.9888 2.36555 10.8729 2.47286C10.757 2.58017 10.6886 2.72916 10.6826 2.88699V2.88699V2.94039L11.0564 8.53154C11.0809 8.90792 11.4045 9.19402 11.781 9.17227L17.3874 8.79851C17.5454 8.78683 17.6922 8.71263 17.7953 8.59236C17.8984 8.47209 17.9492 8.31568 17.9366 8.15778V8.12727Z"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.41784 5.64056C7.77447 5.55807 8.13942 5.74054 8.28741 6.07534C8.32617 6.15409 8.34948 6.23954 8.35606 6.32706C8.43234 7.4102 8.59252 9.78244 8.68405 11.0639C8.69968 11.2947 8.80666 11.5098 8.98131 11.6616C9.15596 11.8133 9.38388 11.8892 9.61464 11.8724V11.8724L14.321 11.5826C14.5311 11.57 14.7371 11.6447 14.8902 11.7892C15.0433 11.9336 15.1299 12.1349 15.1295 12.3454V12.3454C14.9388 15.1873 12.8969 17.5632 10.1159 18.179C7.33493 18.7949 4.4809 17.5032 3.10816 15.0075C2.69836 14.2891 2.43885 13.495 2.34538 12.6734C2.30542 12.4212 2.29009 12.1657 2.29961 11.9106C2.30771 8.87558 4.4392 6.26053 7.41022 5.64056"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
