import { Modal } from '@mui/material';
import styled from 'styled-components';
import { CustomModalProps } from './type';

const StyledModal = styled(props => <Modal {...props} />)(({ theme }) => ({
  '&.MuiModal-root': {
    zIndex: 999,
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));

const ModalComponent = ({
  modalOpen,
  children,
  setModalOpen = () => {},
}: CustomModalProps): JSX.Element => {
  return (
    <StyledModal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-testid="simple-modal"
    >
      <>{children}</>
    </StyledModal>
  );
};

export default ModalComponent;
