import { useTheme } from '@mui/material/styles';

export default function PlusIcon({
  width = '24',
  height = '24',
}: any): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2275 15.545L12.2275 10.2855L12.2275 4.99992"
        stroke={theme.palette.icon.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95491 10.2725L12.2144 10.2725L17.5 10.2725"
        stroke={theme.palette.icon.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
