export default function FireIntrusionIcon({
  color = '#ffffff',
}: any): JSX.Element {
  return (
    <svg
      xmlns="
        http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M2.10001 19.5V13.5C2.10001 9.08172 5.68173 5.5 10.1 5.5C14.5183 5.5 18.1 9.08172 18.1 13.5V19.5H19.1V21.5H1.10001V19.5H2.10001ZM4.10001 13.5H6.10001C6.10001 11.2909 7.89087 9.5 10.1 9.5V7.5C6.7863 7.5 4.10001 10.1863 4.10001 13.5ZM9.1 0H11.1V4.5H9.1V0ZM19.1 3.63898L20.5142 5.0532L17.1711 7.84315L15.7569 6.42893L19.1 3.63898ZM-0.000147343 4.64481L1.41406 3.23059L4.44315 6.42893L3.02894 7.84315L-0.000147343 4.64481Z"
        fill={color}
      />
    </svg>
  );
}
