import { Badge, BadgeProps, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const CustomLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}));
export const CustomBadge = styled(Badge)<any>(({ badgecolor }) => ({
  '& .MuiBadge-badge': {
    color: badgecolor,
    backgroundColor: badgecolor,
  },
}));
export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    color: COLORS.white,
    width: '1.563rem',
    height: '1.563rem',
    background: COLORS.lightTintRed,
    borderRadius: '1.5rem',
  },
}));
export const NotifyContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.customBackground.main,
  overflow: 'auto',
  maxHeight: '380px',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },
  // Firefox
  'scrollbar-color': theme.palette.customBackground.containerScroll,
  'scrollbar-width': 'auto',
  // IE11
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    '-ms-overflow-style': 'scrollbar',
    'scrollbar-base-color': theme.palette.customBackground.containerScroll,
    'scrollbar-face-color': theme.palette.customBackground.containerScroll,
    'scrollbar-arrow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-shadow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-dark-shadow-color':
      theme.palette.customBackground.containerScroll,
  },
}));
