import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { configerStore } from 'store/configerStore';
import { App } from './app';
import { envConfigUrl, globalConfig } from 'appconfig';
import axios from 'axios';
import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

const store = configerStore();
const ROOT_MAIN = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

axios
  .post(envConfigUrl)
  .then((response: any) => {
    const config = response.data;
    ISSApp(config);
  })
  .catch(e => {
    ISSApp(process.env);
    console.error(e);
  });

const ISSApp = (config: any) => {
  if (config) {
    globalConfig.updateConfig(config);
    const configuration: Configuration = {
      auth: {
        clientId: config?.REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID,
        authority: config?.REACT_APP_AZURE_ACTIVE_DIRECTORY_SIGN_IN_AUTHORITY,
        knownAuthorities: [
          config?.REACT_APP_AZURE_ACTIVE_DIRECTORY_AUTHORITY_DOMAIN,
        ],
        redirectUri: '/login',
        postLogoutRedirectUri: '/',
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: any, message: any, containsPii = true) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                break;
              default:
            }
          },
        },
      },
    };
    const msalInstance = new PublicClientApplication(configuration);
    ROOT_MAIN.render(
      <Provider store={store}>
        {/* <React.StrictMode> */}
        <BrowserRouter>
          <App pca={msalInstance} />
        </BrowserRouter>
        {/* </React.StrictMode> */}
      </Provider>,
    );
  }
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
