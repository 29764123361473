import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled(FormControlLabel)``;

export interface Props {
  label: string;
  boxSize?: 'small' | 'medium' | undefined;
  isChecked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelStyle?: any;
  checkBoxStyle?: any;
  isShowCheckbox?: boolean;
}

function CustomCheckbox({
  label,
  boxSize = 'small',
  isChecked,
  handleChange,
  labelStyle = { fontSize: '0.666rem' },
  checkBoxStyle = {},
  isShowCheckbox = true,
}: Props) {
  return (
    <Wrapper
      label={<Typography sx={labelStyle}>{label}</Typography>}
      control={
        <Checkbox
          checked={isChecked}
          size={boxSize}
          onChange={handleChange}
          sx={[
            checkBoxStyle,
            { visibility: isShowCheckbox ? 'visible' : 'hidden' },
          ]}
        />
      }
    />
  );
}
export default CustomCheckbox;
