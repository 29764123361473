import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import EditIcon from 'assets/component/EditIcon';
import { IconsButton } from '../Buttons';
import CustomTooltip from '../Tooltip';
import { Grid } from '@mui/material';
import { COLORS } from 'styles/colors';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { DATE_FORMAT_1 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function PermissionGroupRowItem({
  key,
  rowItem,
  tableName,
  onIconsClick,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};
  return (
    <>
      <TableRow
        container
        key={key}
        onClick={handleClick}
        alignItems="center"
        theme={theme}
      >
        <TableCell item xs={4}>
          <OverflowTip text={rowItem.Name}></OverflowTip>
        </TableCell>
        <TableCell item xs={3}>
          <OverflowTip
            text={getFormatedDate(rowItem.CreatedOn, DATE_FORMAT_1)}
          ></OverflowTip>
        </TableCell>
        <TableCell item xs={3}>
          <OverflowTip
            text={getFormatedDate(rowItem.UpdatedOn, DATE_FORMAT_1)}
          ></OverflowTip>
        </TableCell>
        <TableCell item xs={1}>
          <Grid container justifyContent="space-around">
            <CustomTooltip title={'Edit'}>
              <IconsButton
                name="edit"
                children={<EditIcon />}
                style={{
                  background: COLORS.iconBgColor,
                  borderRadius: 4,
                }}
                OnIconClick={onIconsClick}
              />
            </CustomTooltip>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
}
export default PermissionGroupRowItem;
