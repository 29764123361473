import { useTheme } from '@mui/material/styles';
export default function RightArrowIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9278_10940)">
        <path
          d="M6.5 3.5L12.5 9.5L6.5 15.5"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9278_10940">
          <rect
            width="19"
            height="19"
            stroke={theme.palette.icon.main}
            transform="matrix(0 1 1 0 0 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
