/* eslint-disable @typescript-eslint/no-floating-promises */
import { Grid } from '@mui/material';
import { LoginContainer } from '../LoginPage/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'app/component/layout/Loader/Loader';
import { clearImpersonateSession, isLoggedIn } from 'app/features/authService';
import { ROUTE_PATH } from '../Router/constant';
import { useDispatch, useSelector } from 'react-redux';
import { actions as adminAction } from 'app/features/AdminSlice/slice';
import { getImpersonateUser } from 'app/features/AdminSlice/Selectors';
import { getFromLocalStorage } from 'store/localStore';
import ModalComponent from 'app/component/core/Modal';
import { AlertPopup } from 'app/component/core/Modal/AlertPopup';
import { STORE_KEY } from 'store/Constant';
import { isEmpty } from 'utils/CommonFn/validators';

const ImpersonateUser = (): JSX.Element => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isImpersonate, setIsImpersonate] = useState(false);
  const dispatch = useDispatch();
  const impersonateUser = useSelector(getImpersonateUser);

  const onPermissionModalClose = () => {
    setIsImpersonate(false);
    clearImpersonateSession();
  };
  useEffect(() => {
    if (impersonateUser?.data?.impersonate) {
      dispatch(adminAction.updateimpersonateUser({ data: [], loading: false }));
      setLoading(false);
      navigate(`/${ROUTE_PATH.DASHBOARD}`);
    } else if (impersonateUser?.status === 'error') {
      setIsImpersonate(true);
    }
  }, [impersonateUser]);

  useEffect(() => {
    const imUserID: any = getFromLocalStorage(STORE_KEY.IMUSER_ID) ?? '';
    if (isLoggedIn() && !isEmpty(imUserID)) {
      dispatch(
        adminAction.fetchImpersonateUser({ userIdToImpersonate: imUserID }),
      );
    } else {
      navigate(`/${ROUTE_PATH.LOGIN}`, { replace: true });
    }
  }, []);

  return (
    <>
      <LoginContainer>
        <Grid container style={{ height: '100vh' }}>
          {(impersonateUser.loading ?? loading) && <Loader />}
        </Grid>
      </LoginContainer>
      <ModalComponent
        modalOpen={isImpersonate}
        children={
          <AlertPopup
            message={impersonateUser?.data}
            onOkClick={onPermissionModalClose}
          />
        }
        setModalOpen={onPermissionModalClose}
      />
    </>
  );
};

export default ImpersonateUser;
