import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Title = styled(Grid)`
  color: theme.palette.neutral.main,
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 15px;
`;

export const Header = styled(Grid)`
  color: theme.palette.neutral.main,
  font-size: 0.75rem;
  padding-bottom: 15px;
`;

export const Container = styled(Grid)(({ theme }) => ({
  width: '100%',
  background: theme.palette.customBackground.primary,
  borderRadius: '0.5rem',
}));

export const GridContainer = styled(Grid)<any>(({ theme }) => ({
  padding: '0.5rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '100%',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.containerScroll,
  'scrollbar-width': 'auto',
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    '-ms-overflow-style': 'scrollbar',
    'scrollbar-base-color': theme.palette.customBackground.containerScroll,
    'scrollbar-face-color': theme.palette.customBackground.containerScroll,
    'scrollbar-arrow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-shadow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-dark-shadow-color':
      theme.palette.customBackground.containerScroll,
  },
}));

export const ItemGrid = styled(Grid)<any>(({ theme }) => ({
  padding: '0.5rem',
}));

export const InsightContainer = styled(Grid)<any>(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  borderRadius: '0.625rem',
}));
