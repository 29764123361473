import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';
import { isEmpty } from 'utils/CommonFn/validators';
import CustomTooltip from '../Tooltip';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { CONTEXT } from 'utils/Constants/Content';
import { getFormatedDate } from 'utils/CommonFn';
import { DATE_FORMAT_1 } from 'utils/Constants';

function NetowrkRowItem({
  key,
  rowItem,
  tableName,
  onSiteHandleClick,
  source,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  const onSiteClick = (e: any) => {
    if (source !== CONTEXT.SITE_MANAGER) {
      onSiteHandleClick(e, rowItem.SiteId);
    }
  };
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item sm={false} md={2} theme={theme}>
        <CustomTooltip title={rowItem.Name} placement="top">
          <span
            className={`name ${source !== CONTEXT.SITE_MANAGER ? 'link' : ''}`}
            onClick={e => onSiteClick(e)}
          >
            {rowItem.Name}
          </span>
        </CustomTooltip>
      </TableCell>
      <TableCell item sm={false} md={2} theme={theme}>
        <OverflowTip text={rowItem.CurrentStatus}></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={3} theme={theme}>
        <OverflowTip
          text={getFormatedDate(rowItem.StatusChangeTime, DATE_FORMAT_1)}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={3} theme={theme}>
        <OverflowTip
          text={
            !isEmpty(rowItem.StatusChangeTime)
              ? moment(rowItem.StatusChangeTime, 'YYYYMMDD').fromNow()
              : '-'
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip text={rowItem.Chronic.toString()}></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={rowItem.SwitchStatus === null ? '-' : rowItem.SwitchStatus}
        ></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default NetowrkRowItem;
