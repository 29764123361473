import { useTheme } from '@mui/material/styles';
export default function WarningIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7458 3.85996L2.27583 18C2.1012 18.3024 2.0088 18.6453 2.00782 18.9945C2.00684 19.3437 2.09732 19.6871 2.27026 19.9905C2.44319 20.2939 2.69256 20.5467 2.99354 20.7238C3.29452 20.9009 3.63663 20.9961 3.98583 21H20.9258C21.275 20.9961 21.6171 20.9009 21.9181 20.7238C22.2191 20.5467 22.4685 20.2939 22.6414 19.9905C22.8143 19.6871 22.9048 19.3437 22.9038 18.9945C22.9029 18.6453 22.8105 18.3024 22.6358 18L14.1658 3.85996C13.9876 3.56607 13.7366 3.32308 13.437 3.15444C13.1375 2.98581 12.7996 2.89722 12.4558 2.89722C12.1121 2.89722 11.7742 2.98581 11.4746 3.15444C11.1751 3.32308 10.9241 3.56607 10.7458 3.85996Z"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4558 9V13"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4558 17H12.4658"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
