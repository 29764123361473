import { styled, Grid } from '@mui/material';
// import background from "assets/images/background.png";
import { COLORS } from 'styles/colors';

export const LoginContainer = styled('div')`
  background-image: url('https://ovepublicassets.blob.core.windows.net/images/ove-background-image.jpeg');
  width: 100vw;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
`;

export const LoginSection = styled(Grid)`
  justfiy-content: center;
  background: transparent;
  height: 100%;
`;
export const LoginLeftSection = styled(LoginSection)`
  background: linear-gradient(
    90deg,
    #050811 3.12%,
    #060c18 68.87%,
    rgba(6, 12, 24, 0) 98.64%
  );
  color: ${COLORS.white};
`;
export const Logo = styled('img')`
  width: 'auto';
`;
export const Title = styled(Grid)`
  color: ${COLORS.white};
  font-size: 0.875rem;
  font-weight: 700;
  margin: 2.708rem 0 0.896rem;
`;
