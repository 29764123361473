import React from 'react';
import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { Grid, Checkbox } from '@mui/material';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import CustomTooltip from '../Tooltip';
import { COLORS } from 'styles/colors';
import { SiteCSListProps } from './type';
import {
  CheckboxIcon,
  CustomUncheckboxIcon,
  InfoCircleIconSolidWhite,
} from 'assets/component';
import { useNavigate } from 'react-router';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function SiteCSRowItem({
  key,
  rowItem,
  tableName,
  showSiteRemark,
  hideShowSiteData,
  onSiteHandleClick,
}: SiteCSListProps): any {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClick = (): any => {};

  const onEditIconClick = (): void => {
    navigate(`editSite/${rowItem.Id}`);
  };

  const onInfoIconClick = (): void => {
    navigate(`viewSiteDetails/${rowItem.Id}`);
  };

  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.CompanyNumber || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={4}>
        <OverflowTip
          text={
            <span
              className="name link"
              onClick={(e: any) => onSiteHandleClick(e, rowItem?.Id)}
            >
              {rowItem?.Name}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <span>
          <Checkbox
            key={rowItem.Id}
            checked={rowItem.IsHidden}
            icon={<CustomUncheckboxIcon />}
            checkedIcon={<CheckboxIcon />}
            onChange={e => hideShowSiteData(e, rowItem.Id)}
          />
        </span>
      </TableCell>
      <TableCell item xs={2}>
        {rowItem.HasRemark ? (
          <>
            <span
              style={{ color: COLORS.royalBlue, textDecoration: 'underline' }}
              onClick={() => showSiteRemark(rowItem.Id)}
            >
              View
            </span>
          </>
        ) : (
          <>{'NA'}</>
        )}
      </TableCell>
      <TableCell item xs={1}>
        <Grid item>
          <CustomTooltip title={'Information'}>
            <IconsButton
              name="info"
              children={<InfoCircleIconSolidWhite />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
              }}
              OnIconClick={onInfoIconClick}
            />
          </CustomTooltip>
        </Grid>
        <Grid item sx={{ marginLeft: '1rem' }}>
          <CustomTooltip title={'Edit'}>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
              }}
              OnIconClick={onEditIconClick}
            />
          </CustomTooltip>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
export default SiteCSRowItem;
