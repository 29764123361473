import { useTheme } from '@mui/material/styles';
export default function IPConfigureIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="288"
      height="35"
      viewBox="0 0 288 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_12325_900)">
        <path
          d="M51.8 34.2C41.8 34.2 36.7 28.7 36.7 17.6V17.5C36.7 6.5 41.8 1 51.8 1H63.7999V6.8H50.3C47.7 6.8 45.7 7.7 44.4 9.4C43.1 11.1 42.5 13.9 42.5 17.6C42.5 24.8 45.1 28.3 50.3 28.3H63.7999V34.1H51.8V34.2Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M83.0999 1C93.0999 1 98.0999 6.5 98.1999 17.5V17.6C98.1999 28.6 93.1999 34.2 83.0999 34.2H80.4999C70.4999 34.2 65.3999 28.7 65.3999 17.6V17.5C65.5999 6.5 70.5999 1 80.5999 1H83.0999ZM79.0999 6.8C76.4999 6.8 74.4999 7.7 73.1999 9.4C71.8999 11.1 71.2999 13.9 71.2999 17.6C71.2999 24.8 73.8999 28.3 79.0999 28.3H84.6999C89.8999 28.3 92.4999 24.7 92.4999 17.6C92.4999 13.8 91.7999 11.1 90.4999 9.4C89.1999 7.7 87.1999 6.8 84.5999 6.8H79.0999Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M133.5 26.3C133.5 27.6 133.3 28.7 132.8 29.7C132.4 30.7 131.7 31.6 131 32.3C130.2 33 129.3 33.6 128.3 34C127.3 34.4 126.1 34.6 125 34.6C123.3 34.6 121.7 34.1 120.4 33.2C119.1 32.3 118.1 31 117.6 29.4L111 9.00002C110.8 8.30002 110.6 7.80002 110.3 7.40002C110 7.00002 109.6 6.80002 109 6.80002C107.4 6.80002 106.5 7.80002 106.5 9.70002V34.2H100.3V9.00002C100.3 7.80002 100.5 6.70002 100.9 5.70002C101.3 4.70002 101.9 3.80002 102.7 3.10002C103.5 2.40002 104.4 1.80002 105.4 1.50002C106.4 1.10002 107.6 0.900024 108.8 0.900024C110.5 0.900024 112 1.30002 113.4 2.20002C114.7 3.10002 115.7 4.40002 116.2 6.00002L122.9 26.5C123.1 27.2 123.4 27.7 123.7 28C124 28.3 124.4 28.5 125 28.5C126.6 28.5 127.5 27.5 127.5 25.6V1.00002H133.7V26.3H133.5Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M161.1 6.80002H150.3C147.8 6.80002 145.7 7.50002 144.2 8.80002C142.6 10.1 141.8 12 141.7 14.6H161.2V20.4H141.7V34.2H135.9V15.3C135.9 13.1 136.2 11.1 136.8 9.30002C137.4 7.50002 138.3 6.00002 139.6 4.80002C140.8 3.60002 142.4 2.60002 144.2 1.90002C146 1.20002 148.2 0.900024 150.6 0.900024H161.2V6.80002H161.1Z"
          fill={theme.palette.icon.menu}
        />
        <path d="M162.9 1H168.7V34.2H162.9V1Z" fill={theme.palette.icon.menu} />
        <path
          d="M185.7 34.2C175.7 34.2 170.6 28.7 170.6 17.6V17.5C170.6 6.5 175.7 1 185.7 1H197.7V6.8H184.2C181.6 6.8 179.6 7.7 178.3 9.4C177 11.1 176.4 13.9 176.4 17.6C176.4 24.8 179 28.3 184.2 28.3H191.1C191.6 28.3 192 28.2 192.5 28C192.9 27.8 193.3 27.5 193.7 27.1C194 26.7 194.3 26.3 194.5 25.8C194.7 25.3 194.8 24.8 194.8 24.3C194.8 23.2 194.5 22.3 193.8 21.5C193.1 20.7 192.3 20.3 191.2 20.3H186V14.5H191.5C192.8 14.5 194 14.7 195.1 15.2C196.2 15.7 197.2 16.4 198 17.3C198.8 18.2 199.5 19.2 199.9 20.5C200.4 21.7 200.6 23.1 200.6 24.5C200.6 25.7 200.4 26.9 199.9 28C199.4 29.1 198.7 30.2 197.9 31.1C197 32 196.1 32.7 195 33.3C193.9 33.8 192.7 34.1 191.5 34.1H185.7V34.2Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M230.3 21.6C230.3 23.5 230 25.2 229.4 26.8C228.8 28.3 228 29.7 226.8 30.7C225.6 31.8 224.2 32.6 222.4 33.3C220.7 33.9 218.6 34.2 216.3 34.2C214 34.2 212 33.9 210.3 33.3C208.6 32.7 207.1 31.8 205.9 30.7C204.7 29.6 203.8 28.3 203.2 26.7C202.6 25.2 202.3 23.5 202.3 21.6V1H208.1V21.5C208.1 23.5 208.8 25.2 210.3 26.5C211.8 27.8 213.8 28.4 216.4 28.4H216.5C219.1 28.4 221.1 27.8 222.4 26.5C223.7 25.2 224.4 23.6 224.4 21.5V1H230.2V21.6H230.3Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M249.2 1C251 1 252.6 1.3 253.9 1.8C255.2 2.3 256.3 3 257.1 3.9C257.9 4.8 258.6 5.8 259 7C259.4 8.2 259.6 9.4 259.6 10.8C259.6 12 259.4 13.1 259 14.3C258.6 15.5 258 16.5 257.2 17.4C256.4 18.3 255.3 19.1 254 19.6C252.7 20.2 251.1 20.4 249.2 20.4H246.5L259.9 27.9V34.8L238.5 21.8V34.2H232.7V1H249.2ZM238.5 14.8H250.1C251.2 14.8 252.1 14.4 252.8 13.6C253.5 12.8 253.9 11.9 253.9 10.8C253.9 10.3 253.8 9.8 253.6 9.3C253.4 8.8 253.1 8.4 252.8 8C252.5 7.6 252.1 7.4 251.6 7.1C251.2 6.9 250.7 6.8 250.2 6.8H238.6V14.8H238.5Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M288 6.8H270.5C270 6.8 269.5 6.9 269.1 7.1C268.7 7.3 268.3 7.6 267.9 7.9C267.6 8.3 267.3 8.7 267.1 9.1C266.9 9.6 266.8 10.1 266.8 10.6C266.8 11.7 267.2 12.6 267.9 13.4C268.6 14.2 269.5 14.5 270.6 14.5H288V20.3H270.5C270 20.3 269.5 20.4 269.1 20.6C268.7 20.8 268.3 21.1 267.9 21.4C267.6 21.8 267.3 22.2 267.1 22.6C266.9 23.1 266.8 23.6 266.8 24.1C266.8 25.3 267.2 26.3 267.9 27.1C268.6 27.9 269.5 28.3 270.6 28.3H288V34.1H271.4C269.5 34.1 267.8 33.8 266.5 33.3C265.2 32.7 264.1 32 263.3 31.1C262.5 30.2 261.9 29.1 261.5 27.9C261.1 26.7 260.9 25.5 260.9 24.3C260.9 23 261.2 21.7 261.7 20.4C262.2 19.2 263.2 18.2 264.4 17.4C263.1 16.6 262.2 15.6 261.7 14.4C261.2 13.1 260.9 11.9 260.9 10.7C260.9 9.3 261.1 8 261.5 6.8C261.9 5.6 262.6 4.6 263.4 3.7C264.2 2.8 265.3 2.2 266.6 1.7C267.9 1.2 269.5 1 271.3 1H288V6.8Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M7.7 1H13.5L5.8 34.2H0L7.7 1Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M10.5 24.2C11.2 21 12.7 18.6 15 17C17.3 15.4 20 14.6 23.2 14.6H30.2C31.3 14.6 32.2 14.2 33.2 13.5C34.1 12.7 34.7 11.8 34.9 10.7C35 10.2 35 9.7 34.9 9.2C34.8 8.7 34.6 8.3 34.4 8C34.1 7.6 33.8 7.4 33.4 7.2C33 7 32.5 6.9 32 6.9H14.5L15.8 1H32.4C34.2 1 35.7 1.3 36.9 1.8C38.1 2.3 39 3 39.7 3.9C40.2999 4.8 40.7 5.8 40.9 7C41 8.2 40.8999 9.4 40.5999 10.8C40.2999 12 39.9 13.1 39.2 14.3C38.6 15.5 37.7 16.5 36.7 17.4C35.7 18.3 34.4 19.1 32.9 19.6C31.4 20.2 29.8 20.4 27.9 20.4H20.8C19.8 20.4 18.8 20.8 18 21.6C17.1 22.4 16.6 23.3 16.3 24.4L14 34.2H8.19995L10.5 24.2Z"
          fill={theme.palette.icon.menu}
        />
      </g>
      <defs>
        <clipPath id="clip0_12325_900">
          <rect width="288" height="35" fill={theme.palette.icon.menu} />
        </clipPath>
      </defs>
    </svg>
  );
}
