export default function NetworkTowerIcon(): JSX.Element {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.94702C9.95 5.94702 9.5 6.39702 9.5 6.94702C9.5 7.49702 9.95 7.94702 10.5 7.94702C11.05 7.94702 11.5 7.49702 11.5 6.94702C11.5 6.39702 11.05 5.94702 10.5 5.94702ZM8.5 6.94702C8.5 5.84702 9.4 4.94702 10.5 4.94702C11.6 4.94702 12.5 5.84702 12.5 6.94702C12.5 8.04702 11.6 8.94702 10.5 8.94702C9.4 8.94702 8.5 8.04702 8.5 6.94702Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26 2.09708C6.45 2.29708 6.45 2.60708 6.26 2.80708C5.14 3.90708 4.51 5.39708 4.51 6.94708C4.51 8.49708 5.14 9.98708 6.26 11.0871C6.46 11.2771 6.46 11.5971 6.26 11.7971C6.07 11.9971 5.75 11.9971 5.55 11.7971C4.24 10.5171 3.5 8.76708 3.5 6.94708C3.5 5.12708 4.24 3.37708 5.55 2.08708C5.75 1.89708 6.06 1.89708 6.26 2.08708V2.09708ZM14.74 2.09708C14.93 1.89708 15.25 1.89708 15.45 2.09708C16.76 3.38708 17.5 5.12708 17.5 6.95708C17.5 8.78708 16.76 10.5271 15.45 11.8171C15.25 12.0071 14.94 12.0071 14.74 11.8171C14.55 11.6171 14.55 11.3071 14.74 11.1071C15.86 10.0071 16.49 8.51708 16.49 6.96708C16.49 5.41708 15.86 3.92708 14.74 2.82708C14.54 2.63708 14.54 2.31708 14.74 2.11708V2.09708ZM8.1 3.89708C8.29 4.09708 8.29 4.40708 8.1 4.60708C7.78 4.91708 7.53 5.27708 7.36 5.68708C7.19 6.08708 7.1 6.51708 7.1 6.95708C7.1 7.39708 7.19 7.82708 7.36 8.22708C7.53 8.62708 7.78 8.99708 8.1 9.30708C8.3 9.49708 8.3 9.81708 8.1 10.0171C7.91 10.2171 7.59 10.2171 7.39 10.0171C6.98 9.61708 6.65 9.13708 6.43 8.61708C6.21 8.08708 6.09 7.52708 6.09 6.95708C6.09 6.38708 6.2 5.82708 6.43 5.29708C6.65 4.76708 6.98 4.29708 7.39 3.89708C7.59 3.70708 7.9 3.70708 8.1 3.89708ZM12.9 3.89708C13.09 3.69708 13.41 3.69708 13.61 3.89708C14.02 4.29708 14.35 4.77708 14.57 5.29708C14.79 5.82708 14.91 6.38708 14.91 6.95708C14.91 7.52708 14.8 8.08708 14.57 8.61708C14.35 9.14708 14.02 9.61708 13.61 10.0171C13.41 10.2071 13.1 10.2071 12.9 10.0171C12.71 9.81708 12.71 9.50708 12.9 9.30708C13.22 8.99708 13.47 8.63708 13.64 8.22708C13.81 7.82708 13.9 7.39708 13.9 6.95708C13.9 6.51708 13.81 6.08708 13.64 5.68708C13.47 5.28708 13.22 4.91708 12.9 4.60708C12.7 4.41708 12.7 4.09708 12.9 3.89708Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5001 10.147C10.6801 10.147 10.8401 10.247 10.9301 10.397L16.5601 20.147C16.6501 20.297 16.6501 20.497 16.5601 20.647C16.4701 20.797 16.3101 20.897 16.1301 20.897H4.87006C4.69006 20.897 4.53006 20.797 4.44006 20.647C4.35006 20.497 4.35006 20.297 4.44006 20.147L10.0701 10.397C10.1601 10.247 10.3201 10.147 10.5001 10.147ZM10.5001 11.647L5.74006 19.897H15.2701L10.5101 11.647H10.5001Z"
        fill="white"
      />
    </svg>
  );
}
