import { useTheme } from '@mui/material/styles';

export default function OpenProjectIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.99984 1.66675L1.6665 5.83341L9.99984 10.0001L18.3332 5.83341L9.99984 1.66675Z"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.6665 14.1667L9.99984 18.3334L18.3332 14.1667"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.6665 10L9.99984 14.1667L18.3332 10"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
