import { useTheme } from '@mui/material/styles';

export default function CradlepointIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="234"
      height="73"
      viewBox="0 0 234 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M167.72 26.8507L145.203 8.70226C144.043 7.76591 142.436 7.76591 141.275 8.70226C139.575 10.0714 139.575 12.7781 141.275 14.1512L163.792 32.2998C164.357 32.756 165.045 33 165.756 33C168.855 33 170.183 28.8376 167.72 26.8507Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M145.722 26.9263L134.197 17.6936C133.038 16.7688 131.433 16.7688 130.273 17.6936C128.576 19.0459 128.576 21.7194 130.273 23.0755L141.798 32.3084C142.363 32.759 143.05 33 143.76 33C146.855 33 148.181 28.8888 145.722 26.9263Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M124.693 26.8144L123.314 25.7064C122.128 24.7645 120.487 24.7645 119.302 25.7064C117.566 27.0836 117.566 29.8064 119.302 31.1874L120.681 32.2957C121.259 32.7546 121.961 33 122.687 33C125.851 33 127.208 28.813 124.693 26.8144Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M212.696 26.9894L184.999 5.53173C183.68 4.51268 181.849 4.51268 180.53 5.53173L171.6 12.4267L156.302 0.686418C155.119 -0.228806 153.482 -0.228806 152.299 0.686418C150.567 2.02464 150.567 4.67033 152.299 6.01242L186.421 32.3156C186.997 32.7615 187.698 33 188.422 33C191.579 33 192.932 28.9315 190.423 26.9894L177.039 16.6375L182.763 12.2267L208.693 32.3156C209.269 32.7615 209.97 33 210.694 33C213.852 33 215.205 28.9315 212.696 26.9894Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M11.3091 41C15.9141 41 18.9638 43.1245 20.6256 45.732C21.6637 47.3622 20.4165 49.4719 18.4391 49.4719C17.5226 49.4719 16.6594 48.9998 16.2297 48.2143C15.3133 46.5361 13.5336 45.448 11.2673 45.448C7.55587 45.448 5.19823 48.3691 5.19823 52.5222C5.19823 56.6751 7.55587 59.552 11.2673 59.552C13.5336 59.552 15.3133 58.4639 16.2297 56.7857C16.6594 56.0002 17.5226 55.5281 18.4391 55.5281C20.4165 55.5281 21.6637 57.6378 20.6256 59.268C18.9638 61.8719 15.9141 64 11.3091 64C4.62783 64 0 58.8769 0 52.5222C0 46.1673 4.62783 41.0038 11.3091 41.0038V41Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M37.7476 45.8194C33.8479 45.7964 31.5284 47.7504 31.5284 52.1434V61.5835C31.5284 62.9178 30.3846 64 28.9743 64H28.5541C27.1436 64 26 62.9178 26 61.5835V43.6248C26 42.2903 27.1436 41.2083 28.5541 41.2083C29.9644 41.2083 31.1079 42.2903 31.1079 43.6248V44.3702C31.9525 42.9862 33.8521 41.1471 37.6668 41.0018C39.0773 40.9484 38.9963 42.0762 38.9963 42.0762V44.6113C38.9963 44.6113 39.1337 45.8309 37.7437 45.8194H37.7476Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M41 57.3473C41 53.6229 43.6545 51.2074 49.5193 50.4035L55.9432 49.5149V49.1756C55.9432 46.5057 54.2318 45.1522 51.1896 45.1522C49.0607 45.1522 47.6366 45.8678 46.9281 47.1141C46.4881 47.8885 45.7015 48.4123 44.8029 48.4123H44.7619C42.9276 48.4123 41.7307 46.4209 42.6926 44.872C44.0871 42.6262 46.7714 41 51.3162 41C57.826 41 60.9056 44.175 60.9056 49.216V59.3387C60.9056 59.818 60.9355 60.3418 60.9876 60.858C61.1292 62.263 60.0443 63.4911 58.6164 63.4911H56.7112C56.6701 63.4911 56.4129 62.3885 56.4129 60.6109C54.9142 62.8568 52.3453 64 48.7922 64C43.2668 64 41 61.1198 41 57.351V57.3473ZM50.0338 54.0432C46.9095 54.5079 45.8804 55.651 45.8804 57.1776C45.8804 59.2539 47.3793 60.0578 49.8176 60.0578C53.9299 60.0578 55.9394 57.5169 55.9394 54.5521V53.1544L50.03 54.0432H50.0338Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M85.1964 31H85.5893C86.9204 31 88 32.0671 88 33.3833V61.0963C88 62.4125 86.9204 63.4796 85.5893 63.4796C84.258 63.4796 83.1787 62.4125 83.1787 61.0963V60.2744C81.733 62.6123 79.063 64 75.5121 64C68.8104 64 65 58.6716 65 52.2647C65 45.8576 68.8104 40.4879 75.5121 40.4879C79.0594 40.4879 81.5118 42.0453 82.782 43.9534V33.387C82.782 32.071 83.8614 31.0039 85.1927 31.0039L85.1964 31ZM76.6106 45.0319C72.6704 45.0319 70.218 48.1506 70.218 52.2647C70.218 56.3789 72.6704 59.4522 76.6106 59.4522C80.5507 59.4522 83.0071 56.2921 83.0071 52.2647C83.0071 48.2373 80.6003 45.0319 76.6106 45.0319Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M95 61.5785V33.4215C95 32.0842 96.0351 31 97.3117 31H97.6883C98.9649 31 100 32.0842 100 33.4215V61.5785C100 62.9158 98.9649 64 97.6883 64H97.3117C96.0351 64 95 62.9158 95 61.5785Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M110.602 53.9627C110.295 53.9627 110.049 54.2356 110.101 54.5344C110.665 57.6811 112.876 59.6805 116.237 59.6805C118.202 59.6805 120.282 59.0018 121.712 57.5594C122.227 57.0393 122.862 56.6519 123.602 56.6519H123.631C125.495 56.6519 126.63 58.795 125.461 60.23C123.523 62.613 120.061 64 116.106 64C109.373 64 105 59.0422 105 52.4797C105 45.9172 109.459 41 116.065 41C122.672 41 127 45.9578 127 52.3948V52.4208C127 53.2728 126.298 53.9627 125.435 53.9627H110.598H110.602ZM110.725 50.2369H121.342C121.656 50.2369 121.906 49.9528 121.839 49.6503C121.23 46.9244 119.158 45.1094 116.069 45.1094C112.981 45.1094 110.919 46.9502 110.236 49.6356C110.157 49.9419 110.407 50.2333 110.729 50.2333L110.725 50.2369Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M134.802 73H134.406C133.077 73 132 71.9328 132 70.617V43.9033C132 42.5875 133.077 41.5203 134.406 41.5203C135.735 41.5203 136.812 42.5875 136.812 43.9033V44.7253C138.296 42.4291 140.965 41 144.463 41C151.197 41 155 46.324 155 52.7751C155 59.2265 151.197 64.5089 144.463 64.5089C140.965 64.5089 138.475 62.9931 137.208 61.0437V70.6131C137.208 71.9292 136.13 72.9961 134.802 72.9961V73ZM143.412 59.9654C147.349 59.9654 149.796 56.8058 149.796 52.779C149.796 48.7522 147.349 45.4606 143.412 45.4606C139.476 45.4606 137.032 48.6203 137.032 52.779C137.032 56.9377 139.434 59.9654 143.412 59.9654Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M158 52.5203C158 46.3341 162.437 41 169.48 41C176.522 41 181 46.3376 181 52.5203C181 58.7028 176.563 64 169.48 64C162.396 64 158 58.7064 158 52.5203ZM169.48 59.5513C173.192 59.5513 175.924 56.755 175.924 52.5203C175.924 48.2855 173.192 45.445 169.48 45.445C165.767 45.445 163.076 48.2413 163.076 52.5203C163.076 56.7994 165.808 59.5513 169.48 59.5513Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M188.31 41H188.69C189.966 41 191 42.0919 191 43.4386V61.5614C191 62.9079 189.966 64 188.69 64H188.31C187.034 64 186 62.9079 186 61.5614V43.4386C186 42.0919 187.034 41 188.31 41Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M202.757 44.5077C203.708 42.7311 205.994 41 209.668 41C215.109 41 218 44.6396 218 49.8787V61.6162C218 62.9327 216.936 64 215.624 64H215.233C213.92 64 212.856 62.9327 212.856 61.6162V50.1539C212.856 47.578 210.803 45.3602 208.235 45.4204C205.099 45.4921 203.144 47.5176 203.144 50.9612V61.6162C203.144 62.9327 202.08 64 200.767 64H200.376C199.064 64 198 62.9327 198 61.6162V43.9004C198 42.5842 199.064 41.5166 200.376 41.5166C201.689 41.5166 202.753 42.5842 202.753 43.9004V44.5077H202.757Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M231.183 64C226.512 64 224.482 61.6542 224.482 57.4787V45.7985H223.092C221.936 45.7985 221 44.8928 221 43.7757C221 42.6585 221.936 41.7528 223.092 41.7528H224.482V38.0226C224.482 36.9055 225.418 36 226.574 36H227.633C228.788 36 229.724 36.9055 229.724 38.0226V41.7528H231.908C233.064 41.7528 234 42.6585 234 43.7757C234 44.8928 233.064 45.7985 231.908 45.7985H229.724V56.9665C229.724 58.4103 230.945 59.6054 232.438 59.5645C232.458 59.5645 234.127 59.4756 233.912 61.8583C233.912 61.8583 233.734 64 231.183 64Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
