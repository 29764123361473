/* eslint-disable react-hooks/exhaustive-deps */
import { Popover, Grid, useTheme, Box } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconsButton, PrimaryButton } from '../../core/Buttons';
import { COLORS } from 'styles/colors';
import CustomInput from '../../core/CustomInputs/customInputsDashboard';
import { MultipleSelectCheckmarks } from '../../core/CustomSelectWithoutLable/multiselectCheckbox';
import { getDashboardFilter } from 'app/features/DashboardSlice/Selectors';
import { useSelector } from 'react-redux';
import { getExportStateList } from 'app/features/GlobalSlice/Selectors';
import { isEmpty } from 'utils/CommonFn/validators';
import { checkIEVersion, manageDropdownDataForIE } from 'utils/CommonFn';
import { CustomMultiselectIE } from '../../core/CustomMultiselectIE';
import { ClearIcon } from 'assets/component';
export const Wrapper = styled(Popover)(({ theme }) => ({
  [theme.breakpoints.down('xxl')]: {
    width: '80%',
  },
}));

function CustomInputLocationFilter({
  anchorEl,
  setAnchorEl,
  setSelectedType,
  onfilter,
}: any) {
  const theme = useTheme();
  const dashboardFilter: any = useSelector(getDashboardFilter);
  const selectorStateData: any = useSelector(getExportStateList);
  const { locationValue } = dashboardFilter;
  const open = Boolean(anchorEl);
  const [locationFilter, setLocationFilter] = useState<any>(locationValue);

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const updateState = (state: any): any => {
    if (state.length > 0) {
      return state.map((st: any) => {
        return { key: st.code, value: st.code };
      });
    }
    return [];
  };
  const state = updateState(selectorStateData?.data);
  const stateOptionIE: any = manageDropdownDataForIE(state);

  const handleChangeState = (e: any): void => {
    let selectedVal = e.target.value || [];
    let newValue: any = [];
    if (selectedVal[selectedVal.length - 1] === 'all') {
      if (
        locationFilter.state.length > 0 &&
        locationFilter.state.length === state.length
      ) {
        newValue = [];
        selectedVal = [];
      } else {
        newValue = state.map((o: any) => o.value);
        selectedVal = state.map((o: any) => o.key);
      }
    } else {
      newValue = selectedVal.map((val: any) => {
        const obj = state.find((o: any) => o.key === val);
        return obj.value;
      });
    }
    setLocationFilter({ ...locationFilter, state: newValue });
  };
  const handleChangeStateIE = (item: any): void => {
    const newValue = item.map((o: any) => o.value);
    setLocationFilter({ ...locationFilter, state: newValue });
  };

  const onApplyClick = () => {
    setSelectedType(iFilterActive(locationFilter));
    const updateFilter: any = {
      locationValue: {
        ...locationValue,
        ...locationFilter,
        isFilterApplied: true,
      },
    };
    onfilter(updateFilter);
    setAnchorEl(null);
  };
  const updateLocationFilter = (e: any) => {
    const searchName = e.target.name;
    const searchText = e.target.value;
    const data: any = JSON.parse(JSON.stringify(locationFilter));
    switch (searchName) {
      case 'Site Name':
        data.siteName = searchText;
        break;
      case 'Address':
        data.address = searchText;
        break;
      case 'City':
        data.city = searchText;
        break;
      case 'Zip':
        data.zipcode = searchText;
        break;
    }
    setLocationFilter({ ...locationFilter, ...data });
  };
  const onClearClick = () => {
    const resetObj: any = {
      locationValue: {
        ...locationValue,
        address: '',
        city: '',
        siteName: '',
        state: [],
        zipcode: '',
        isFilterApplied: false,
      },
    };
    setLocationFilter(resetObj.locationValue);
    if (iFilterActive(locationValue) && iFilterActive(locationFilter)) {
      onfilter(resetObj);
    }
  };
  const iFilterActive = (data: any): any => {
    const isStateSelected = data?.state?.length || 0;
    if (isStateSelected > 0) {
      return true;
    } else {
      for (const i in data) {
        if (typeof data[i] !== 'object') {
          if (!isEmpty(data[i]) && i !== 'isFilterApplied') {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onApplyClick();
      handleClose();
    }
  };
  useEffect(() => {
    if (locationValue) {
      setLocationFilter(locationValue);
      setSelectedType(iFilterActive(locationValue));
    }
  }, [dashboardFilter]);

  return (
    <Wrapper
      id={open ? 'simple-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          borderRadius: '0.5rem',
        },
      }}
      theme={theme}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          p={2}
          sx={{
            backgroundColor: theme.palette.customBackground.main,
            borderRadius: '8px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={2} lg={2}>
              <CustomInput
                label="Site Name"
                onTextChange={updateLocationFilter}
                value={locationFilter.siteName}
                showEndAdornment={false}
                customStyle={{ paddingBottom: 0 }}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <CustomInput
                label="Address"
                onTextChange={updateLocationFilter}
                value={locationFilter.address}
                showEndAdornment={false}
                customStyle={{ paddingBottom: 0 }}
                autofocus={false}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <CustomInput
                label="City"
                onTextChange={updateLocationFilter}
                value={locationFilter.city}
                showEndAdornment={false}
                customStyle={{ paddingBottom: 0 }}
                autofocus={false}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              {!checkIEVersion() ? (
                <MultipleSelectCheckmarks
                  options={state}
                  handleChange={handleChangeState}
                  value={locationFilter.state}
                  placeholder="State"
                  isSelectAll={true}
                />
              ) : (
                <CustomMultiselectIE
                  options={stateOptionIE}
                  key={'state'}
                  handleChange={handleChangeStateIE}
                  value={locationFilter.state}
                  placeholder={'State'}
                  hasMultiselect={true}
                />
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <CustomInput
                label="Zip"
                onTextChange={updateLocationFilter}
                value={locationFilter.zipcode}
                showEndAdornment={false}
                type="number"
                customStyle={{ paddingBottom: 0 }}
                autofocus={false}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <Grid container alignItems={'center'} spacing={{ lg: 1 }}>
                <Grid item xs={6}>
                  <PrimaryButton
                    style={{
                      padding: '0.563rem 1.25rem',
                      backgroundColor: COLORS.royalBlue,
                      width: '100%',
                    }}
                    children={'Apply'}
                    disabled={!iFilterActive(locationFilter)}
                    fullWidth={false}
                    onClick={() => {
                      onApplyClick();
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IconsButton
                    name={'ClearFilter'}
                    children={<ClearIcon color={theme.palette.icon.main} />}
                    style={{
                      background: theme.palette.primary.main,
                      borderRadius: 4,
                      padding: '6px',
                    }}
                    styleTooltip={{
                      height: 'auto',
                    }}
                    title="Clear"
                    isShowTooltip={true}
                    OnIconClick={onClearClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
}
export default memo(CustomInputLocationFilter);
