import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { DATE_FORMAT_1 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function ServiceTripExportRowItem({
  key,
  rowItem,
  tableName,
  isAutoCells,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      {isAutoCells ? (
        Object.keys(rowItem).length > 0 &&
        Object.keys(rowItem).map((key: any, value: any) => {
          const count = Object.keys(rowItem).length <= 12 ? 2 : 1;
          return (
            <TableCell item xs={count}>
              <OverflowTip
                text={
                  key === 'CreatedOn'
                    ? getFormatedDate(rowItem.CreatedOn, DATE_FORMAT_1)
                    : key === 'ScheduledDate'
                    ? getFormatedDate(
                        rowItem.ScheduledDate,
                        DATE_FORMAT_1,
                        '',
                        'utc',
                      )
                    : rowItem[key] || '-'
                }
              ></OverflowTip>
            </TableCell>
          );
        })
      ) : (
        <>
          <TableCell item xs={1}>
            <OverflowTip text={rowItem.Site ? rowItem.Site : '-'}></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.CompanyNumber ? rowItem?.CompanyNumber : ''}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.Status === null ? '-' : rowItem?.Status}
            ></OverflowTip>
          </TableCell>

          <TableCell item xs={1}>
            <OverflowTip
              text={
                rowItem?.ServiceRequestNumber === null
                  ? '-'
                  : rowItem?.ServiceRequestNumber
              }
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.ServiceRequest?.SRTitle ?? '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip text={rowItem?.WorkOrderNumber}></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.Type === null ? '-' : rowItem?.Type}
            ></OverflowTip>
          </TableCell>

          <TableCell item xs={1}>
            <OverflowTip
              text={getFormatedDate(rowItem?.CreatedOn, DATE_FORMAT_1)}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={getFormatedDate(
                rowItem?.ScheduledDate,
                DATE_FORMAT_1,
                '',
                'utc',
              )}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.ServiceRequest?.Resource ?? '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.Address ? rowItem.Address : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.City ? rowItem.City : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem?.State ? rowItem.State : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip text={rowItem?.Zip ? rowItem.Zip : '-'}></OverflowTip>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
export default ServiceTripExportRowItem;
