import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectSlice = (state: RootState) =>
  state && state.global != null ? state.global : initialState;

export const getCompanyList = createSelector(
  [selectSlice],
  state => state.companyList,
);

export const getCompanySiteList = createSelector(
  [selectSlice],
  state => state.getCompanySiteList,
);
export const getBusinessIntelligence = createSelector(
  [selectSlice],
  state => state.urls.BusinessIntelligence,
);
export const getBusinessSecurity = createSelector(
  [selectSlice],
  state => state.urls.BusinessSecurity,
);
export const getBusinessSecurityCombined = createSelector(
  [selectSlice],
  state => state.urls.BusinessSecurityCombined,
);
export const getNetworkVoices = createSelector(
  [selectSlice],
  state => state.urls.NetworkVoices,
);
export const getVideoVerifiedAlarm = createSelector(
  [selectSlice],
  state => state.urls.VideoVerifiedAlarm,
);
export const getHelpSupport = createSelector(
  [selectSlice],
  state => state.urls.HelpSupport,
);
export const getAnyaltics = createSelector(
  [selectSlice],
  state => state.insightAnyaltics,
);
export const getServiceRequestList = createSelector(
  [selectSlice],
  state => state.sericeRequestSiteList,
);
export const getAffectedSystemList = createSelector(
  [selectSlice],
  state => state.affectedSystemList,
);
export const getSubmitRequestStatus = createSelector(
  [selectSlice],
  state => state.serviceRequest,
);
export const getProfileSetting = createSelector(
  [selectSlice],
  state => state.userProfileViewSetting,
);
export const getProfileInfo = createSelector(
  [selectSlice],
  state => state.userProfileInfo,
);
export const getSubmitIdeaStatus = createSelector(
  [selectSlice],
  state => state.submitIdeaInfo,
);

export const getExportData = createSelector(
  [selectSlice],
  state => state.exportData,
);

export const getSiteListExportData = createSelector(
  [selectSlice],
  state => state.exportDataSiteInfo,
);

export const getNetworkListExportData = createSelector(
  [selectSlice],
  state => state.exportDataNetworkInfo,
);

export const getServiceRequestListExportData = createSelector(
  [selectSlice],
  state => state.exportDataServiceRequestInfo,
);

export const getServiceTripListExportData = createSelector(
  [selectSlice],
  state => state.exportDataServiceTripInfo,
);

export const getSystemListExportData = createSelector(
  [selectSlice],
  state => state.exportDataSystemInfo,
);
// export const getSecurityEventExportData = createSelector(
//   [selectSlice],
//   state => state.exportDataSecurityEventInfo,
// );

export const getExportDataShowListonSearch = createSelector(
  [selectSlice],
  state => state.exportDataShowListonSearch,
);

export const getJitterAnalyticsData = createSelector(
  [selectSlice],
  state => state.jitterAnalyticsData,
);
export const getMOSAnalyticsData = createSelector(
  [selectSlice],
  state => state.mosAnalyticsData,
);
export const getPlossAnalyticsData = createSelector(
  [selectSlice],
  state => state.plossAnalyticsData,
);
export const getWuplink1AnalyticsData = createSelector(
  [selectSlice],
  state => state.wuplink1AnalyticsData,
);
export const getWuplink2AnalyticsData = createSelector(
  [selectSlice],
  state => state.wuplink2AnalyticsData,
);
export const getNotificationList = createSelector(
  [selectSlice],
  state => state.userNotifcationList,
);
export const getGlobalNotificationList = createSelector(
  [selectSlice],
  state => state.globalUserNotifcationList,
);
export const getDownloadSupport = createSelector(
  [selectSlice],
  state => state.downloadSupport,
);
export const getUserSystems = createSelector(
  [selectSlice],
  state => state.userSystems,
);

export const getGlobalSearch = createSelector(
  [selectSlice],
  state => state.globalSearchList,
);
export const getExportStateList = createSelector(
  [selectSlice],
  state => state.exportStateList,
);
export const getAnalyticsTimerFilter = createSelector(
  [selectSlice],
  state => state.analyticsTimeFilter,
);
export const getBannerNotification = createSelector(
  [selectSlice],
  state => state.bannerNotifcationList,
);
export const getAnalyticsTimeRange = createSelector(
  [selectSlice],
  state => state.siteAnalyticsTimeRange,
);
export const getNewReleaseNotificationList = createSelector(
  [selectSlice],
  state => state.newReleaseNotifcationList,
);
export const getExportAllActivity = createSelector(
  [selectSlice],
  state => state.exportAllActivty,
);
export const getSecurityManagerFilter = createSelector(
  [selectSlice],
  state => state.securityManagerFilter,
);
export const getExportOpenCloseActivity = createSelector(
  [selectSlice],
  state => state.exportOpenCloseActivty,
);
export const getsecurityManagerSiteList = createSelector(
  [selectSlice],
  state => state.securityManagerSiteList,
);
export const getsecurityManagerSiteGroupList = createSelector(
  [selectSlice],
  state => state.securityManagerSiteGroupList,
);
export const getsecurityManagerUserContact = createSelector(
  [selectSlice],
  state => state.exportUserContanct,
);
export const getELearningUrls = createSelector(
  [selectSlice],
  state => state.elearningUrls,
);
export const getOnlineManuals = createSelector(
  [selectSlice],
  state => state.onlineManuals,
);
export const getTermsCondition = createSelector(
  [selectSlice],
  state => state.termsConditions,
);
export const getAcceptAgreement = createSelector(
  [selectSlice],
  state => state.acceptAgreement,
);
