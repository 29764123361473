import { useTheme } from '@mui/material/styles';

export default function FilterSliderIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clip-path="url(#clip0_9411_10521)">
        <path
          d="M2.83333 14.875V9.91669"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.83333 7.08333V2.125"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.5 14.875V8.5"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.5 5.66667V2.125"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1667 14.875V11.3333"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1667 8.5V2.125"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.708328 9.91669H4.95833"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.375 5.66669H10.625"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0417 11.3333H16.2917"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9411_10521">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
