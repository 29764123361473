import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { ViewGroupsCSListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function ViewGroupsCSRowItem({
  key,
  rowItem,
  tableName,
}: ViewGroupsCSListProps): any {
  const theme = useTheme();

  return (
    <TableRow container key={key} alignItems="center" theme={theme}>
      <TableCell item xs={4}>
        <OverflowTip text={rowItem?.Name || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={4}>
        <OverflowTip text={rowItem?.MasterCompanyId || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={4}>
        <OverflowTip
          text={rowItem?.IsAllGroup?.toString() || '-'}
        ></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default ViewGroupsCSRowItem;
