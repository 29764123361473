/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { RoundTick, FailRoundIcon } from 'assets/component';
import { COLORS } from 'styles/colors';
import { CONTEXT } from 'utils/Constants/Content';
import { isEmpty } from 'utils/CommonFn/validators';

function BulkUploadedStatusRowItem({
  key,
  rowItem,
  onIconClick,
  customRowStyle = {},
  source,
}: any): any {
  const theme = useTheme();
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Success':
        return <RoundTick color={COLORS.lightGreen} />;
      case 'Failed':
        return <FailRoundIcon />;
      default:
        break;
    }
  };
  return (
    <TableRow
      container
      key={key}
      alignContent="center"
      theme={theme}
      sx={{ ...customRowStyle }}
    >
      <TableCell item xs={4}>
        <OverflowTip
          text={
            source === CONTEXT.USER
              ? !isEmpty(rowItem.email)
                ? rowItem?.email
                : '-'
              : !isEmpty(rowItem.siteGroupName)
              ? rowItem?.siteGroupName
              : '-'
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        {rowItem.status && (
          <span
            style={{
              display: 'flex',
              padding: '0.313rem',
              alignItems: 'center',
            }}
          >
            {getStatusIcon(rowItem.status)}
          </span>
        )}
        <OverflowTip text={rowItem.status || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={6}>
        <OverflowTip text={rowItem.remark || '-'}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default BulkUploadedStatusRowItem;
