import { useTheme } from '@mui/material/styles';
export default function DmpVirtualKeypadIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="121"
      height="46"
      viewBox="0 0 121 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11274_1840)">
        <path
          d="M118.087 39.924C116.842 39.924 115.905 38.934 115.905 37.6908C115.905 36.4632 116.842 35.4564 118.053 35.4564C119.297 35.4564 120.218 36.4632 120.218 37.7064C120.218 38.934 119.297 39.9408 118.087 39.924ZM118.087 34.8936C116.468 34.8936 115.189 36.1212 115.189 37.6908C115.189 39.258 116.468 40.5036 118.07 40.5036C119.672 40.5036 120.934 39.258 120.934 37.6908C120.934 36.1212 119.672 34.8936 118.087 34.8936Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M117.95 37.5708H117.644V36.7692C117.712 36.7524 117.831 36.7344 118.001 36.7344C118.411 36.7344 118.599 36.9048 118.599 37.1616C118.599 37.4508 118.309 37.5708 117.95 37.5708ZM118.718 37.7748V37.7412C119.042 37.638 119.296 37.4172 119.296 37.0752C119.301 36.9599 119.277 36.8452 119.226 36.7414C119.176 36.6376 119.1 36.5478 119.007 36.48C118.82 36.342 118.581 36.258 118.053 36.258C117.591 36.258 117.235 36.3096 116.979 36.342V39.1392H117.626V38.0304H117.933C118.292 38.0304 118.462 38.1672 118.514 38.4564C118.599 38.7972 118.649 39.0372 118.735 39.1392H119.416C119.366 39.0372 119.296 38.8836 119.212 38.4564C119.126 38.0808 118.99 37.8612 118.718 37.7748ZM101.721 1.14481V1.13281H82.3457V10.5732L108.971 10.5636V12.8148L82.3457 12.8256V15.2784H111.831V17.5416H82.3457V19.9956H114.333V22.2576H82.3457V24.72H111.809V26.9688H82.3457V29.4276H109.123V31.6908H82.3457V45.1824H97.4106V40.512H101.565V40.5036C112.295 40.338 120.945 31.5924 120.945 20.8224C120.945 10.104 112.379 1.39321 101.721 1.14481ZM61.3338 12.3996L54.4193 1.13521H41.6465V10.5696H43.7165V12.8232H41.6465V15.2796H46.5305V17.5416H41.6465V19.9956H49.4525V22.2588H41.6465V24.7116H52.3577V26.9748H41.6465V29.4288H55.2485V31.6908H41.6465V40.5072H81.0197V1.13521H68.2481L61.3338 12.3996ZM0.946146 24.7116H39.9293C40.0925 23.9112 40.1993 23.0904 40.2593 22.2588H0.946146V24.7116ZM20.9813 1.14481V1.13281H0.946146V10.5624L37.4201 10.572C35.6855 7.75271 33.2722 5.41286 30.4006 3.7661C27.5291 2.11935 24.2908 1.21816 20.9813 1.14481ZM0.946146 40.506H20.8241V40.4976C24.036 40.4527 27.1889 39.6277 30.011 38.0935C32.8331 36.5593 35.24 34.3619 37.0241 31.6908H0.946146V40.506ZM38.6093 12.8232H0.944946V15.2796H39.5177C39.2707 14.4413 38.9672 13.6206 38.6093 12.8232ZM0.946146 29.4276H38.3249C38.7113 28.638 39.0461 27.8184 39.3245 26.9748H0.946146V29.4276ZM0.946146 17.5416V19.9956H40.2977C40.2632 19.173 40.177 18.3534 40.0397 17.5416H0.946146Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M101.721 1.14481V1.13281H82.3457V10.5732L108.971 10.5636V12.8148L82.3457 12.8256V15.2784H111.831V17.5416H82.3457V19.9956H114.333V22.2576H82.3457V24.72H111.809V26.9688H82.3457V29.4276H109.123V31.6908H82.3457V45.1824H97.4106V40.512H101.565V40.5036C112.295 40.338 120.945 31.5924 120.945 20.8224C120.945 10.104 112.379 1.39321 101.721 1.14481ZM61.3338 12.3996L54.4193 1.13521H41.6465V10.5696H43.7165V12.8232H41.6465V15.2796H46.5305V17.5416H41.6465V19.9956H49.4525V22.2588H41.6465V24.7116H52.3577V26.9748H41.6465V29.4288H55.2485V31.6908H41.6465V40.5072H81.0197V1.13521H68.2481L61.3338 12.3996ZM0.946146 24.7116H39.9293C40.0925 23.9112 40.1993 23.0904 40.2593 22.2588H0.946146V24.7116ZM20.9813 1.14481V1.13281H0.946146V10.5624L37.4201 10.572C35.6855 7.75271 33.2722 5.41286 30.4006 3.7661C27.5291 2.11935 24.2908 1.21816 20.9813 1.14481ZM0.946146 40.506H20.8241V40.4976C24.036 40.4527 27.1889 39.6277 30.011 38.0935C32.8331 36.5593 35.24 34.3619 37.0241 31.6908H0.946146V40.506ZM38.6093 12.8232H0.944946V15.2796H39.5177C39.2707 14.4413 38.9672 13.6206 38.6093 12.8232ZM0.946146 29.4276H38.3249C38.7113 28.638 39.0461 27.8184 39.3245 26.9748H0.946146V29.4276ZM0.946146 17.5416V19.9956H40.2977C40.2632 19.173 40.177 18.3534 40.0397 17.5416H0.946146Z"
          fill={theme.palette.icon.menu}
        />
      </g>
      <defs>
        <clipPath id="clip0_11274_1840">
          <rect
            width="120"
            height="44.4"
            fill={theme.palette.icon.menu}
            transform="translate(0.944946 0.95752)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
