import { Grid, useMediaQuery } from '@mui/material';
import { IconsButton } from 'app/component/core/Buttons';
import { useTheme } from '@mui/material/styles';
import { COLORS } from 'styles/colors';
import { DeleteIcon, PlusSolidWhiteIcon } from 'assets/component';

interface Props {
  label: string;
  style?: React.CSSProperties;
  iconName: any;
  oniconClick?: any;
  isSelected?: boolean;
}

function LabelWithIconButton({
  label,
  style,
  iconName,
  oniconClick,
  isSelected = false,
}: Props): JSX.Element {
  const theme = useTheme();

  const onIconClick = (e: any): any => {
    oniconClick(e);
  };

  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={{
        fontSize: useMediaQuery(theme.breakpoints.up('xl'))
          ? '0.875rem'
          : '0.75rem',
        padding: '5px 0.625rem 5px 0.875rem',
        marginBottom: '0.5rem',
        borderRadius: '0.5rem',
        alignItems: 'center',
        backgroundColor:
          theme.palette.mode === 'dark' ? COLORS.gulfBlue : COLORS.solitude,
        transition: 'background-color .8s ease-out',
        ':hover': {
          background:
            theme.palette.mode === 'dark'
              ? COLORS.haitiViolet
              : COLORS.titanWhite,
        },
      }}
      data-testid="label-icon-button"
    >
      <Grid>{label}</Grid>
      <Grid>
        {isSelected && iconName === 'delete' ? (
          <>
            <IconsButton
              style={{
                backgroundColor: COLORS.iconBgColor,
                scale: '0.9',
                padding: '6px',
                borderRadius: 4,
              }}
              children={<DeleteIcon />}
              name={'delete'}
              OnIconClick={onIconClick}
            />
          </>
        ) : (
          <IconsButton
            style={{
              backgroundColor: COLORS.iconBgColor,
              scale: '0.8',
              padding: '6px',
              borderRadius: 4,
            }}
            children={<PlusSolidWhiteIcon />}
            name={'add'}
            OnIconClick={onIconClick}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default LabelWithIconButton;
