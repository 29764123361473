export const isEmpty = (value: any): boolean => {
  if (value === 'null' || value == null || value === undefined || value === '')
    return true;
  return false;
};

export const isEmail = (value: any): boolean => {
  if (!isEmpty(value)) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return true;
    }
  }
  return false;
};
export const isOnlyDigits = (value: any): boolean => {
  if (!isEmpty(value)) {
    if (/^[0-9]*$/i.test(value)) {
      return true;
    }
  }
  return false;
};
