import React, { useState } from 'react';
import {
  CustomTabs,
  CustomTab,
  CustomDivider,
} from 'app/pages/Dashboard/component/Tabs/styles';
import { TABS_USER_MANAGEMENT } from 'utils/Constants/index';
import { TabPanelProps } from 'app/pages/Dashboard/component/Tabs/types';
import { Grid } from '@mui/material';
import { PersonalDetails, Permissions } from '../Tabs/AddNewuserTabPanel';
import { useTheme } from '@mui/material/styles';
import UserProfile from './profile';
import { COLORS } from 'styles/colors';

interface Props {
  theme: any;
}
export function EditUserProfile({ theme }: Props): JSX.Element {
  const [value, setValue] = useState(1);
  const [tabs] = useState(TABS_USER_MANAGEMENT);
  const themes = useTheme();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, index, ...other } = props;

    return (
      <Grid
        sx={{ overflow: 'hidden' }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Grid item p={3} sx={{ width: '100%' }}>
            {children}
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <>
      <Grid
        container
        justifyContent={'center'}
        item
        sx={{
          backgroundColor: theme.palette.customBackground.primary,
          width: '35%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: ' translate(-50%, -50%)',
        }}
      >
        <Grid container justifyContent="center">
          <UserProfile
            role={'Custom Access'}
            title={'Chrysanthemum Joseph'}
            contact={'+1 987-987-9876'}
            email={'Chrysanthemum.Joseph1990@hotmail.com'}
            theme={theme}
          />
        </Grid>
        <Grid
          style={{
            width: '100%',
            marginTop: '15px',
            marginBottom: '5px',
            border: `2px solid ${COLORS.white}`,
          }}
        ></Grid>
        <CustomTabs value={value} onChange={handleChange} theme={themes}>
          {tabs?.map((item, index) => {
            return (
              <CustomTab
                key={index}
                currentindex={index}
                tabslength={tabs.length - 1}
                value={item.id}
                label={item.label}
                theme={themes}
              />
            );
          })}
        </CustomTabs>
        <CustomDivider variant="fullWidth" theme={themes} />

        <TabPanel value={value} index={1}>
          <PersonalDetails />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Permissions theme={themes} />
        </TabPanel>
      </Grid>
    </>
  );
}
