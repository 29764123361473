export default function DeleteIcon(): JSX.Element {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.50684 4.40015H3.84017H14.5068"
        stroke="white"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1732 4.40019V13.7335C13.1732 14.0871 13.0327 14.4263 12.7827 14.6763C12.5326 14.9264 12.1935 15.0669 11.8398 15.0669H5.17318C4.81956 15.0669 4.48042 14.9264 4.23037 14.6763C3.98032 14.4263 3.83984 14.0871 3.83984 13.7335V4.40019M5.83984 4.40019V3.06685C5.83984 2.71323 5.98032 2.37409 6.23037 2.12404C6.48042 1.874 6.81956 1.73352 7.17318 1.73352H9.83984C10.1935 1.73352 10.5326 1.874 10.7827 2.12404C11.0327 2.37409 11.1732 2.71323 11.1732 3.06685V4.40019"
        stroke="white"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.17383 7.73352V11.7335"
        stroke="white"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83984 7.73352V11.7335"
        stroke="white"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
