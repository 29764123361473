import { useTheme } from '@mui/material/styles';

export default function HelpfulDownloadsIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.8333 85.8516L61 106.018L81.1667 85.8516"
        stroke={theme.palette.icon.menu}
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61 60.6431V106.018"
        stroke={theme.palette.icon.menu}
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M105.77 91.347C110.153 88.2647 113.44 83.8659 115.154 78.7889C116.868 73.7119 116.919 68.2209 115.301 63.1128C113.682 58.0046 110.478 53.545 106.154 50.3812C101.829 47.2174 96.6084 45.514 91.25 45.5182H84.8975C83.3812 39.6087 80.544 34.1203 76.5995 29.466C72.6551 24.8117 67.7062 21.1128 62.1253 18.6479C56.5445 16.183 50.4773 15.0163 44.3803 15.2355C38.2833 15.4548 32.3155 17.0543 26.9262 19.9137C21.5368 22.7732 16.8664 26.8179 13.2664 31.7435C9.66651 36.6691 7.23088 42.3472 6.14294 48.3504C5.05499 54.3535 5.34307 60.5252 6.9855 66.4009C8.62792 72.2766 11.5819 77.7031 15.625 82.272"
        stroke={theme.palette.icon.menu}
        stroke-width="7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
