/* eslint-disable @typescript-eslint/prefer-includes */
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { COLORS } from 'styles/colors';
import { CircularProgress, InputAdornment, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { checkIEVersion } from 'utils/CommonFn';
import { CheckboxIcon, CustomUncheckboxIcon } from 'assets/component';

interface Props {
  value?: any;
  handleChange?: (event: any) => void;
  options: [
    {
      value: number;
      label?: string;
    },
  ];
  label?: string;
  className?: string;
  placeholder: string;
  isDisabled?: boolean;
  isSelectAll?: boolean;
  style?: any;
  loading?: boolean;
}

export function MultipleSelectCheckmarks({
  value,
  handleChange,
  options,
  label,
  className,
  placeholder,
  isDisabled = false,
  isSelectAll = false,
  style = null,
  loading = false,
}: Props): JSX.Element {
  const theme = useTheme();
  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          size="small"
          multiple
          displayEmpty
          value={value}
          name={placeholder ?? ''}
          onChange={handleChange}
          renderValue={selected => {
            if (selected.length === 0) {
              return <p>{placeholder}</p>;
            }

            return selected.join(', ');
          }}
          sx={{
            fontSize: useMediaQuery(theme.breakpoints.up('xl'))
              ? '0.875rem'
              : '0.75rem',
            borderRadius: '0.5rem',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,

            '& .MuiSelect-nativeInput': {
              svg: {
                margin: '5px',
              },
            },
            '&.MuiInputBase-root': {
              cursor: 'pointer',
              paddingRight: '0.625rem',
              svg: {
                fill: theme.palette.text.primary,
              },
            },
          }}
          style={style}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? COLORS.gulfBlue
                    : COLORS.deeplightGrey,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                '& .MuiMenuItem-root': {
                  cursor: 'default',
                  fontSize: useMediaQuery(theme.breakpoints.up('xl'))
                    ? '0.875rem'
                    : '0.75rem',
                  padding: '4px 0.625rem 4px 0.625rem',
                  margin: '2px',
                  '&.Mui-selected': {
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? COLORS.haitiViolet
                        : COLORS.linkWaterBlue,
                    borderRadius: '0.5rem',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? COLORS.haitiViolet
                        : COLORS.linkWaterBlue,
                  },
                },
                '& .MuiMenu-list': {
                  paddingBottom: '4px',
                },
                '& .MuiTypography-root': {
                  fontSize: useMediaQuery(theme.breakpoints.up('xl'))
                    ? '0.875rem'
                    : '0.75rem',
                },

                overflow: 'auto',
                maxHeight: '40%',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-track': {
                  borderRadius: '0.625rem',
                },

                '::-webkit-scrollbar-thumb': {
                  background:
                    theme.palette.mode === 'dark'
                      ? COLORS.painiViolet
                      : COLORS.linkWaterBlue,
                  borderRadius: '0.625rem',
                },
                'scrollbar-color':
                  theme.palette.mode === 'dark'
                    ? COLORS.painiViolet
                    : COLORS.linkWaterBlue,
                'scrollbar-width': 'auto',
              },
            },
            variant:
              value.length > 0 && value.length === options.length
                ? 'menu'
                : 'selectedMenu',
          }}
          endAdornment={
            loading && (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            )
          }
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* Select label item */}
          {!checkIEVersion() ? (
            <MenuItem disabled value={value}>
              {placeholder}
            </MenuItem>
          ) : !value ? (
            <option disabled value={placeholder}>
              {placeholder}
            </option>
          ) : null}
          {isSelectAll && (
            <MenuItem value={'all'} key={Math.random()}>
              <Checkbox
                checked={value.length > 0 && value.length === options.length}
                icon={<CustomUncheckboxIcon />}
                checkedIcon={<CheckboxIcon />}
              />
              <ListItemText primary={'Select All'} />
            </MenuItem>
          )}
          {options.map((option: any, index: number) => (
            <MenuItem value={option.key} key={index}>
              <Checkbox
                checked={value.includes(option.key)}
                icon={<CustomUncheckboxIcon />}
                checkedIcon={<CheckboxIcon />}
              />
              <ListItemText primary={option.key} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
