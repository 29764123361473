import { CustomTooltipWrapper } from './style';
import { CustomTooltipProps } from './type';

function CustomTooltip({
  children,
  title,
  placement = 'bottom',
  disable = false,
}: CustomTooltipProps) {
  return (
    <CustomTooltipWrapper
      title={title}
      placement={placement}
      arrow
      disableHoverListener={disable}
      data-testid="custom-tooltip"
    >
      <div className="name">{children}</div>
    </CustomTooltipWrapper>
  );
}
export default CustomTooltip;
