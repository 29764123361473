import axios from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  getTokenKey,
  updateStorage,
} from './authService';
import { globalConfig } from 'appconfig';
import { API_POINTS } from './Constant';
import { STORE_KEY } from 'store/Constant';

let isAccessToken = false;
let isAuthlogin = false;
let isAPIRequest: any = [];
const isTokenErrors: any = [
  'jwt malformed',
  'jwt expired',
  'invalid token',
  'invalid signature',
];

export const isTokenExpired = (error: any): any => {
  return error && error.status === 401;
};
export const resetTokenRequest = async (error: any) => {
  try {
    const { REACT_APP_BASE_API } = globalConfig.getDetails();
    const { response: errorResponse } = error;
    const resetToken: any = await getRefreshToken();
    const { type, token }: any = await getAccessToken();
    if (!isAuthlogin && (!resetToken || !token)) {
      isAuthlogin = true;
      getUser()
        .then((res: any) => {
          if (res?.status === 'success') {
            const userNewtoken = res?.data?.[0].tokens || {};
            if (userNewtoken && userNewtoken?.success) {
              onGetAccessToken(userNewtoken?.token_info?.access_token);
            }
          }
        })
        .catch((error: any): any => {
          console.log(error);
          return Promise.reject(error);
        });
    }
    const isRequest = new Promise(resolve => {
      addAPIRequest((accessToken: any) => {
        errorResponse.config.headers.Authorization = `${type} ${accessToken} `;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAccessToken && resetToken) {
      isAccessToken = true;
      try {
        const response: any = await axios({
          method: 'post',
          url: `${REACT_APP_BASE_API}${API_POINTS.GET_REFRESH_TOKEN} `,
          data: {
            grantType: 'refresh_token',
            token: resetToken,
          },
          headers: {
            Authorization: resetToken,
          },
        });
        if (!response.data.success) {
          return await Promise.reject(error);
        }
        const newToken = response.data.token_info;
        await setToken(response.data);
        isAccessToken = false;
        onGetAccessToken(newToken.access_token);
      } catch (error: any) {
        const errorRes = error.response;
        if (isTokenErrors.includes(errorRes.data.error)) {
          getUser()
            .then((res: any) => {
              if (res?.status === 'success') {
                const userNewtoken = res?.data?.[0].tokens || {};
                if (userNewtoken && userNewtoken?.success) {
                  onGetAccessToken(userNewtoken?.token_info?.access_token);
                }
              }
            })
            .catch((error: any): any => {
              console.log(error);
              return Promise.reject(error);
            });
        }
        return await Promise.reject(error);
      }
    }
    return await isRequest;
  } catch (error) {
    return await Promise.reject(error);
  }
};
export const onGetAccessToken = (accessToken: any): void => {
  isAPIRequest.forEach((callback: any) => callback(accessToken));
  isAPIRequest = [];
};
export const addAPIRequest = (callback: any): void => {
  isAPIRequest.push(callback);
};
export const getUser = async (): Promise<any> => {
  try {
    const { REACT_APP_BASE_API } = globalConfig.getDetails();
    const url = `${REACT_APP_BASE_API}${API_POINTS.GET_LOGIN_USER_DETAILS}`;
    const config = {
      method: 'get',
      headers: {
        Authorization: getTokenKey(),
      },
    };
    return await axios
      .get(url, config)
      .then(async function (response: any) {
        const userData: any = response?.data?.data ?? [];
        const token = userData[0]?.tokens;
        if (token) {
          setToken(token);
        }
        return { data: userData, status: 'success' };
      })
      .catch(function (error: any) {
        return { data: error || [], status: 'error' };
      });
  } catch (error) {
    return null;
  }
};
export const setToken = (newToken: any): any => {
  updateStorage(STORE_KEY.USER_TOKEN, JSON.stringify(newToken));
};
