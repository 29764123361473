import { call, takeLatest, put } from '@redux-saga/core/effects';
import { actions } from './slice';
import { actions as adminAction } from '../AdminSlice/slice';
import {
  getCustomerList,
  getCompanySiteList,
  getBusinessIntelligence,
  getBusinessSecurity,
  getVideoVerifiedAlarm,
  getNetworkVoices,
  getHelpSupport,
  getServiceReqByCause,
  getServiceReqBySubject,
  getNetworkTrend,
  getCurrentSiteStatus,
  getServiceRequestSite,
  getAffectedSystem,
  postServiceRequest,
  getUserProfileInfo,
  getUserProfileViewSetting,
  postUpdateUserProfileInfo,
  postSubmitAnIdea,
  postExportDataSite,
  postExportDataSystem,
  postExportDataNetwork,
  postExportDataServiceRequest,
  postExportDataServiceTrip,
  getJitterAnalytics,
  getMOSAnalytics,
  getLatencyPacketLossAnalytics,
  getUserNotification,
  postUserNotification,
  getW2UplinkAnalytics,
  getWU1plinkAnalytics,
  getHelpDownloadSupport,
  getUserSystems,
  getGlobalSearch,
  getExportStateList,
  getBusinessSecurityCombined,
  postExportSMAllActivity,
  postExportSMOpenCloseActivity,
  postExportSMSites,
  getExportSMSiteGroupList,
  postExportSMUserContact,
  getHelpELearning,
  getOnlineManuals,
  // postExportDataSecurityEvent,
  getTermsCondition,
  patchUserAgreement,
} from './api';
import { ResponseProps } from '../DashboardSlice/types';
import { saveOnLocalStorage } from 'store/localStore';
import moment from 'moment-timezone';
import { CONTEXT, ERROR_TEXT } from 'utils/Constants/Content';
import { FILE_EXPORT_DATE_FORMAT, FILE_EXPORT_NAME } from 'utils/Constants';
import { getUserId } from '../authService';
import { STORE_KEY } from 'store/Constant';
import { setToken } from '../TokenUtils';

function* fetchCompanyList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      const userID = getUserId();
      if (userID) {
        yield put(
          actions.updateCompanySiteList({
            loading: false,
            companySiteList: [],
          }),
        );
        response = yield call(getCustomerList, payload);
        if (response?.data && Array.isArray(response?.data?.companies)) {
          yield put(
            actions.updateCompanyList({
              loading: false,
              companiesList: response?.data?.companies,
              companyTypesList: response?.data?.companyTypes,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCompanyList({
        loading: false,
        companiesList: [],
      }),
    );
  }
}
function* fetchCompanySiteList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      const userID = getUserId();
      if (userID) {
        response = yield call(getCompanySiteList, payload);
        saveOnLocalStorage(STORE_KEY.SITE_COMPANY_ID, '');
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateCompanySiteList({
              loading: false,
              companySiteList: response.data,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCompanySiteList({
        loading: false,
        companySiteList: [],
      }),
    );
  }
}
function* fetchBusinessIntelligences(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getBusinessIntelligence, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateBusinessIntelligence({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateBusinessIntelligence({
            loading: false,
            data: response.data,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateBusinessIntelligence({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchBusinessSecurityCombined(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getBusinessSecurityCombined, payload);
      if (response && typeof response.data === 'object') {
        yield put(
          actions.updateBusinessSecurityCombined({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateBusinessSecurityCombined({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchBusinessSecurity(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getBusinessSecurity, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateBusinessSecurity({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateBusinessSecurity({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchNetworkVoices(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getNetworkVoices, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateNetworkVoices({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNetworkVoices({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchVideoVerifiedAlarm(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getVideoVerifiedAlarm, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateVideoVerifiedAlarm({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateVideoVerifiedAlarm({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchHelpSupport(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getHelpSupport, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateHelpSupport({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateHelpSupport({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchDownloadSupport(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getHelpDownloadSupport);
      if (response && Object.keys(response.data).length) {
        yield put(
          actions.updateHelpDownloadSupport({
            loading: false,
            data: response?.data?.helpful_downloads || [],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateHelpDownloadSupport({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateHelpDownloadSupport({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchServiceRequestByCause(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getServiceReqByCause, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateServiceRequestByCause({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateServiceRequestByCause({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateServiceRequestByCause({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchServiceRequestBySubject(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getServiceReqBySubject, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateServiceRequestBySubject({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateServiceRequestBySubject({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateServiceRequestBySubject({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchCurrentSiteStatus(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getCurrentSiteStatus, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateCurrentSiteStatus({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateCurrentSiteStatus({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCurrentSiteStatus({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchNetworkCountByDate(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getNetworkTrend, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateNetworkCountByDate({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateNetworkCountByDate({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNetworkCountByDate({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserProfileViewSetting(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getUserProfileViewSetting, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateUserProfileViewSetting({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateUserProfileViewSetting({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserProfileViewSetting({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserProfileInfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getUserProfileInfo, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateUserProfileInfo({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateUserProfileInfo({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserProfileInfo({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postUpdateUserPrfileInfo(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      const callback = payload.onSuccessCallback;
      delete payload.onSuccessCallback;
      response = yield call(postUpdateUserProfileInfo, payload);
      if (response && response?.data) {
        yield put(
          adminAction.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateUserProfileInfo({
            loading: false,
            data: [],
          }),
        );
        yield put(actions.fetchUserProfileInfo({}));
        if (callback) {
          callback(response);
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchServiceRequestSiteList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getServiceRequestSite, payload);
      if (response?.data) {
        yield put(
          actions.updateFetchServiceRequestList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateFetchServiceRequestList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchAffectedSystemList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getAffectedSystem, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateAffectedSystemList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateAffectedSystemList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* postServiceRequestApi(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postServiceRequest, payload);
      if (response?.data?.length > 0) {
        const SrNumber = response?.data[0].SrNumber;
        yield put(
          adminAction.displayToast({
            toastMessage: `Service Request ${SrNumber} successfully created.`,
            toastType: 'success',
          }),
        );
        yield put(actions.updateServiceRequest({ status: 'fulfilled' }));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(actions.updateServiceRequest({ status: 'error' }));
    if (error?.response?.status === 400) {
      yield put(
        adminAction.displayToast({
          toastMessage: error?.response?.data?.error
            ? error?.response?.data?.error
            : ERROR_TEXT.API_ERROR,
          toastType: 'error',
        }),
      );
    } else {
      yield put(
        adminAction.displayToast({
          toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
          toastType: 'error',
        }),
      );
    }
  }
}
function* postSubmitIdea(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      const callback = payload.onSuccessCallback;
      delete payload.onSuccessCallback;
      response = yield call(postSubmitAnIdea, payload);
      if (response && response?.data) {
        yield put(
          adminAction.displayToast({
            toastMessage: 'Idea was submitted Successfully',
            toastType: 'success',
          }),
        );
        yield put(
          actions.updatePatchSubmitIdea({
            loading: false,
          }),
        );

        if (callback) {
          callback(response);
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(actions.updatePatchSubmitIdea({ loading: false }));
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
/* Common function for export excel */
function exportUtils(response: any, fileName: any) {
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(new Blob([response.data]), `${fileName}.xlsx`);
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
}

function* postExportDataSiteReport(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      const mode = payload.mode;
      response = yield call(postExportDataSite, {
        ...payload,
        limit: 10,
      });
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.SITE}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        // response.headers["content-disposition"].split("filename=")[1];
        exportUtils(response, fileName);
        yield put(
          actions.updateExportSiteList({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (response?.data?.data) {
        yield put(
          actions.updateExportSiteList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    // yield put(actions.updatePatchSite({ loading: false }));
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* postExportDataSystemReport(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      const mode = payload.mode;
      const callback = payload.onSuccessCallback;
      delete payload.onSuccessCallback;
      response = yield call(postExportDataSystem, {
        ...payload,
        limit: 10,
      });
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.SYSTEM}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        // response.headers["content-disposition"].split("filename=")[1];
        exportUtils(response, fileName);
        yield put(
          actions.updateExportSystemList({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (response && response?.data?.data) {
        yield put(
          actions.updateExportSystemList({
            loading: false,
            data: response?.data,
          }),
        );
        if (callback) {
          callback(response);
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* postExportDataNetworkReport(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      const mode = payload.mode;
      delete payload.onSuccessCallback;
      response = yield call(postExportDataNetwork, {
        ...payload,
        limit: 10,
      });
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.NETWORK}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        // response.headers["content-disposition"].split("filename=")[1];
        exportUtils(response, fileName);
        yield put(
          actions.updateExportNetworkList({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (response && response?.data?.data) {
        yield put(
          actions.updateExportNetworkList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* postExportDataSRReport(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      const mode = payload.mode;
      delete payload.onSuccessCallback;
      response = yield call(postExportDataServiceRequest, {
        ...payload,
        limit: 10,
      });
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.SERVICE_REQUEST}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        // response.headers["content-disposition"].split("filename=")[1];
        exportUtils(response, fileName);
        yield put(
          actions.updateExportSRList({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (response && response?.data?.data) {
        yield put(
          actions.updateExportSRList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* postExportDataSTReport(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      const mode = payload.mode;
      delete payload.onSuccessCallback;
      response = yield call(postExportDataServiceTrip, {
        ...payload,
        limit: 10,
      });
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.SERVICE_TRIPS}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        // response.headers["content-disposition"].split("filename=")[1];
        exportUtils(response, fileName);
        yield put(
          actions.updateExportSTList({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (response && response?.data?.data) {
        yield put(
          actions.updateExportSTList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
// function* postExportDataSEReport(request: any) {
//   let response: { headers: Record<string, string>; data: any };
//   const { payload }: any = request;

//   try {
//     if (payload) {
//       const mode = payload.mode;
//       delete payload.onSuccessCallback;
//       response = yield call(postExportDataSecurityEvent, {
//         ...payload,
//         limit: 10,
//       });
//       if (
//         response.headers['content-type'] ===
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//       ) {
//         const fileName = `${FILE_EXPORT_NAME.SECURITY_EVENT}_${moment().format(
//           FILE_EXPORT_DATE_FORMAT,
//         )}`;
//         exportUtils(response, fileName);
//         yield put(
//           actions.updatePostExportSecurityEvent({
//             loading: false,
//             status: mode,
//           }),
//         );
//         yield put(
//           adminAction.displayToast({
//             toastMessage: 'Export Successfull',
//             toastType: 'success',
//           }),
//         );
//       } else if (response && response?.data) {
//         yield put(
//           actions.updatePostExportSecurityEvent({
//             loading: false,
//             data: response?.data,
//           }),
//         );
//       } else {
//         console.log('=== API Failed to respond ===');
//       }
//     } else {
//       console.log('=== Missing Payload ===');
//     }
//   } catch (error: any) {
//     yield put(
//       adminAction.displayToast({
//         toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
//         toastType: 'error',
//       }),
//     );
//   }
// }
function* fetchUserNotification(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      const isGlobal = payload.isGlobal ?? false;
      delete payload.isGlobal;
      response = yield call(getUserNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        if (isGlobal) {
          yield put(
            actions.updateGlobalUserNotificationList({
              loading: false,
              data: response.data.companyNotificationData,
              recordCount: response.data.recordCount,
            }),
          );
        } else {
          yield put(
            actions.updateUserNotificationList({
              loading: false,
              data: response.data.companyNotificationData,
              recordCount: response.data.recordCount,
            }),
          );
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
  }
}
function* fetchNewReleaseNotification(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getUserNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateNewReleaseNotificationList({
            loading: false,
            data: response.data.companyNotificationData,
            recordCount: response.data.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
  }
}
function* fetchUserBannerNotification(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getUserNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateBannerNotificationList({
            loading: false,
            data: response.data.companyNotificationData,
          }),
        );
      } else {
        yield put(
          actions.updateBannerNotificationList({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateBannerNotificationList({
        loading: false,
        data: [],
      }),
    );
    console.log(error);
  }
}
function* postReadUserNotification(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;
      response = yield call(postUserNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateReadUserNotification({
            loading: false,
            data: [],
          }),
        );
        if (callback) {
          callback(response);
        }
      } else {
        yield put(
          actions.updateReadUserNotification({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateReadUserNotification({
        loading: false,
        data: [],
      }),
    );
    console.log(error);
  }
}
function* postReadUserBannerNotification(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      const companyId = payload.companyId;
      response = yield call(postUserNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateReadUserBannerNotification({
            loading: false,
            data: [],
          }),
        );
        yield put(
          actions.fetchUserBannerNotification({
            recordCountRequire: false,
            unreadOnly: true,
            notificationType: CONTEXT.BANNER,
            companyId,
          }),
        );
      } else {
        yield put(
          actions.updateReadUserBannerNotification({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateReadUserBannerNotification({
        loading: false,
        data: [],
      }),
    );
    console.log(error);
  }
}

function* fetchJitterAnalyticsInfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getJitterAnalytics, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateJitterAnalyticsInfo({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateJitterAnalyticsInfo({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchMOSAnalyticsInfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getMOSAnalytics, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateMOSAnalyticsInfo({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateMOSAnalyticsInfo({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchPlossAnalyticsInfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getLatencyPacketLossAnalytics, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updatePlossAnalyticsInfo({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatePlossAnalyticsInfo({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchWlan1AnalyticsInfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getWU1plinkAnalytics, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateWlan1AnalyticsInfo({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateWlan1AnalyticsInfo({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchWlan2AnalyticsInfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getW2UplinkAnalytics, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateWlan2AnalyticsInfo({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateWlan2AnalyticsInfo({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserSystems() {
  let response: ResponseProps;
  try {
    const userID = getUserId();
    if (userID) {
      response = yield call(getUserSystems);
      if (response && Object.keys(response.data).length) {
        yield put(
          actions.updateUserSystems({
            loading: false,
            data: [response.data],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserSystems({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchGlobalSearchList(request: any) {
  const { payload } = request;
  let response: ResponseProps;
  try {
    response = yield call(getGlobalSearch, payload);
    if (response && Array.isArray(response.data)) {
      yield put(
        actions.updateGlobalSearchList({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateGlobalSearchList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchExportStateList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getExportStateList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateExportStateList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateExportStateList({
            loading: false,
            data: response.data,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateExportStateList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchExportSMAllActivity(request: any) {
  let response: {
    headers: Record<string, string>;
    data: any;
    recordCount: any;
  };
  const { payload }: any = request;
  try {
    if (payload) {
      const mode = payload.mode;
      response = yield call(postExportSMAllActivity, payload);
      const responseObj: any = response?.data ?? {};

      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.SM_All_ACTIVITY}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        exportUtils(response, fileName);
        yield put(
          actions.updateExportAllActivity({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (Object.keys(responseObj).length > 0) {
        yield put(
          actions.updateExportAllActivity({
            loading: false,
            data: responseObj?.data,
            recordCount: responseObj?.recordCount,
          }),
        );
      } else {
        yield put(
          actions.updateExportAllActivity({
            loading: false,
            data: [],
            recordCount: 0,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateExportAllActivity({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchExportSMOpenCloseActivity(request: any) {
  let response: {
    headers: Record<string, string>;
    data: any;
    recordCount: any;
  };
  const { payload } = request;
  try {
    if (payload) {
      const mode = payload.mode;
      response = yield call(postExportSMOpenCloseActivity, payload);
      const responseObj: any = response?.data ?? {};
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${
          FILE_EXPORT_NAME.SM_OPEN_CLOSE_ACTIVITY
        }_${moment().format(FILE_EXPORT_DATE_FORMAT)}`;
        exportUtils(response, fileName);
        yield put(
          actions.updateExportOpenCloseActivity({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (Object.keys(responseObj).length > 0) {
        yield put(
          actions.updateExportOpenCloseActivity({
            loading: false,
            data: responseObj?.data,
            recordCount: responseObj.recordCount,
          }),
        );
      } else {
        yield put(
          actions.updateExportOpenCloseActivity({
            loading: false,
            data: [],
            recordCount: 0,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateExportOpenCloseActivity({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchExportSMSiteList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(postExportSMSites, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateExportSMSiteList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateExportSMSiteList({
            loading: false,
            data: response.data,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateExportSMSiteList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchExportSMSiteGroupList(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getExportSMSiteGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateExportSMSiteGroupList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateExportSMSiteGroupList({
            loading: false,
            data: response.data,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateExportSMSiteGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchExportSMUserContact(request: any) {
  let response: {
    headers: Record<string, string>;
    data: any;
    recordCount: any;
  };
  const { payload }: any = request;
  try {
    if (payload) {
      const mode = payload.mode;
      response = yield call(postExportSMUserContact, payload);
      const responseObj: any = response?.data ?? {};

      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.SM_USER_CONTACT}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        exportUtils(response, fileName);
        yield put(
          actions.updateExportUserContact({
            loading: false,
            status: mode,
          }),
        );
        yield put(
          adminAction.displayToast({
            toastMessage: 'Export Successfull',
            toastType: 'success',
          }),
        );
      } else if (Object.keys(responseObj).length > 0) {
        yield put(
          actions.updateExportUserContact({
            loading: false,
            data: responseObj?.data,
            recordCount: responseObj?.recordCount,
          }),
        );
      } else {
        yield put(
          actions.updateExportUserContact({
            loading: false,
            data: [],
            recordCount: 0,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      adminAction.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateExportUserContact({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchELearninginfo(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getHelpELearning);
      if (response && response.data.length > 0) {
        yield put(
          actions.updateELearninginfo({
            loading: false,
            data: response?.data || [],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateELearninginfo({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateELearninginfo({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchOnlineManualsData(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getOnlineManuals);
      if (response && response.data.length > 0) {
        yield put(
          actions.updateOnlineManualsInfo({
            loading: false,
            data: response?.data || [],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateOnlineManualsInfo({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateOnlineManualsInfo({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchTermsConditionData(request: any) {
  let response: ResponseProps;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getTermsCondition);
      if (response?.data) {
        yield put(
          actions.updateTermsConditionInfo({
            loading: false,
            data: response?.data || [],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateTermsConditionInfo({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateTermsConditionInfo({
        loading: false,
        data: [],
      }),
    );
  }
}
function* updateUserAcceptAgreement(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(patchUserAgreement, payload);
      if (response && Object.keys(response?.data).length > 0) {
        const token = response?.data || null;
        if (token && token?.success) {
          setToken(token);
          yield put(
            actions.updatePatchAcceptAgreement({
              loading: false,
              data: token?.success,
            }),
          );
        }
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updatePatchAcceptAgreement({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updatePatchAcceptAgreement({
        loading: false,
        data: [],
      }),
    );
  }
}

export function* globalSaga() {
  yield takeLatest(actions.fetchCompanyList, fetchCompanyList);
  yield takeLatest(actions.fetchCompanySiteList, fetchCompanySiteList);
  yield takeLatest(
    actions.fetchBusinessIntelligence,
    fetchBusinessIntelligences,
  );
  yield takeLatest(actions.fetchBusinessSecurity, fetchBusinessSecurity);
  yield takeLatest(
    actions.fetchBusinessSecurityCombined,
    fetchBusinessSecurityCombined,
  );
  yield takeLatest(actions.fetchNetworkVoices, fetchNetworkVoices);
  yield takeLatest(actions.fetchVideoVerifiedAlarm, fetchVideoVerifiedAlarm);
  yield takeLatest(actions.fetchHelpSupport, fetchHelpSupport);
  yield takeLatest(
    actions.fetchServiceRequestByCause,
    fetchServiceRequestByCause,
  );
  yield takeLatest(
    actions.fetchServiceRequestBySubject,
    fetchServiceRequestBySubject,
  );
  yield takeLatest(actions.fetchCurrentSiteStatus, fetchCurrentSiteStatus);
  yield takeLatest(actions.fetchNetworkCountByDate, fetchNetworkCountByDate);
  yield takeLatest(
    actions.fetchServiceRequestSiteList,
    fetchServiceRequestSiteList,
  );
  yield takeLatest(actions.fetchAffectedSystemList, fetchAffectedSystemList);
  yield takeLatest(actions.postServiceRequest, postServiceRequestApi);
  yield takeLatest(
    actions.fetchUserProfileViewSetting,
    fetchUserProfileViewSetting,
  );
  yield takeLatest(actions.fetchUserProfileInfo, fetchUserProfileInfo);
  yield takeLatest(actions.fetchUpdateUserPrfileInfo, postUpdateUserPrfileInfo);
  yield takeLatest(actions.postSubmitAnIdea, postSubmitIdea);
  yield takeLatest(actions.postExportDataSite, postExportDataSiteReport);
  yield takeLatest(actions.postExportDataSystem, postExportDataSystemReport);
  yield takeLatest(actions.postExportDataNetwork, postExportDataNetworkReport);
  yield takeLatest(
    actions.postExportDataServiceRequest,
    postExportDataSRReport,
  );
  yield takeLatest(actions.postExportDataServiceTrip, postExportDataSTReport);
  yield takeLatest(actions.fetchJitterAnalyticsInfo, fetchJitterAnalyticsInfo);
  yield takeLatest(actions.fetchMOSAnalyticsInfo, fetchMOSAnalyticsInfo);
  yield takeLatest(actions.fetchPlossAnalyticsInfo, fetchPlossAnalyticsInfo);
  yield takeLatest(actions.fetchWlan1AnalyticsInfo, fetchWlan1AnalyticsInfo);
  yield takeLatest(actions.fetchWlan2AnalyticsInfo, fetchWlan2AnalyticsInfo);
  yield takeLatest(actions.fetchUserNotification, fetchUserNotification);
  yield takeLatest(actions.postReadUserNotification, postReadUserNotification);
  yield takeLatest(actions.fetchHelpDownloadSupport, fetchDownloadSupport);
  yield takeLatest(actions.fetchUserSystems, fetchUserSystems);
  yield takeLatest(actions.fetchGlobalSearchList, fetchGlobalSearchList);
  yield takeLatest(actions.fetchExportStatesList, fetchExportStateList);
  yield takeLatest(actions.fetchExportAllActivity, fetchExportSMAllActivity);
  yield takeLatest(actions.fetchExportSMSiteList, fetchExportSMSiteList);
  yield takeLatest(actions.fetchExportUserContact, fetchExportSMUserContact);
  yield takeLatest(
    actions.fetchExportSMSiteGroupList,
    fetchExportSMSiteGroupList,
  );
  yield takeLatest(
    actions.fetchExportOpenCloseActivity,
    fetchExportSMOpenCloseActivity,
  );
  yield takeLatest(
    actions.fetchUserBannerNotification,
    fetchUserBannerNotification,
  );
  yield takeLatest(
    actions.postReadUserBannerNotification,
    postReadUserBannerNotification,
  );
  yield takeLatest(
    actions.fetchNewReleaseNotification,
    fetchNewReleaseNotification,
  );
  yield takeLatest(actions.fetchELearninginfo, fetchELearninginfo);
  yield takeLatest(actions.fetchOnlineManualsInfo, fetchOnlineManualsData);
  // yield takeLatest(actions.postExportSecurityEvent, postExportDataSEReport);
  yield takeLatest(actions.fetchTermsConditonInfo, fetchTermsConditionData);
  yield takeLatest(
    actions.fetchPatchAcceptAgreement,
    updateUserAcceptAgreement,
  );
}
