import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { ContainerProps, Props } from './types';
import { Grid } from '@mui/material';
import { isEmpty } from 'utils/CommonFn/validators';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props: ContainerProps) => (props.maxHeight ? '100vh' : 'auto')};
`;

const Loader = ({
  title = '',
  maxHeight = false,
  text = '',
  size = 100,
  customStyle,
  type,
}: Props) => (
  <Container maxHeight={maxHeight} style={{ ...customStyle }}>
    <CircularProgress
      variant="indeterminate"
      size={size}
      sx={{
        color: type === 'map' ? COLORS.lightTintRed : COLORS.iconBgColor,
      }}
    />
    {!isEmpty(text) && (
      <Grid item m={3}>
        {text}
      </Grid>
    )}
  </Container>
);

export default Loader;
