import { Wrapper } from './style';
import { LinkButtonProps } from './type';

function LinkButton({
  children,
  className,
  style,
  onLinkClick,
}: LinkButtonProps) {
  return (
    <Wrapper
      style={style}
      className={className}
      onClick={onLinkClick}
      data-testid="btn-link"
    >
      {children}
    </Wrapper>
  );
}

export default LinkButton;
