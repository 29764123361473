import { useTheme } from '@mui/material/styles';

export default function FortinetIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="644"
      height="72"
      viewBox="0 0 644 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_12321_760)">
        <path
          d="M369.679 15.3164V0H387.377V71.7382H369.679V15.3164ZM291.755 0H360.038V15.4473H327.943V71.8691H310.377V15.4473H291.755V0ZM644 0V15.4473H611.906V71.8691H594.34V15.4473H575.717V0H644ZM0 0H76.6038V15.4473H17.6981V30.7636H67.8868V46.2109H17.6981V71.7382H0V0ZM494.623 0H565.943V15.4473H512.321V28.2764H556.302V43.7236H512.321V56.5527H566.868V72H494.623V0ZM481.019 20.2909V71.7382H463.321V20.2909C463.321 17.5418 461.208 15.4473 458.434 15.4473H418.811V71.8691H401.113V0H460.547C471.774 0 481.019 9.16364 481.019 20.2909ZM264.547 0H204.189V71.7382H221.887V46.2109H261.509C263.623 46.3418 265.34 48.0436 265.34 50.1382V71.8691H283.038V49.8764C283.038 46.08 280.925 42.8073 277.755 40.9745C281.585 37.44 283.962 32.3345 283.962 26.8364V19.2436C284.094 8.64 275.377 0 264.547 0ZM266.396 26.8364C266.396 28.9309 264.679 30.7636 262.434 30.7636H221.887V15.4473H262.434C264.547 15.4473 266.396 17.1491 266.396 19.3745V26.8364Z"
          fill={theme.palette.icon.menu}
        />
        <path
          d="M190.453 26.7055V45.2945H161.132V26.7055H190.453ZM124.019 71.7382H153.208V53.1491H124.019V71.7382ZM190.453 15.5782C190.453 6.93818 183.453 0 174.736 0H161.264V18.5891H190.453V15.5782ZM124.019 0V18.5891H153.208V0H124.019ZM86.7736 26.7055V45.2945H115.962V26.7055H86.7736ZM161.132 71.7382H174.604C183.321 71.7382 190.321 64.8 190.321 56.16V53.1491H161.132V71.7382ZM86.7736 56.16C86.7736 64.8 93.7736 71.7382 102.491 71.7382H115.962V53.1491H86.7736V56.16ZM86.7736 15.5782V18.5891H115.962V0H102.491C93.7736 0 86.7736 6.93818 86.7736 15.5782Z"
          fill={theme.palette.icon.menu}
        />
      </g>
      <defs>
        <clipPath id="clip0_12321_760">
          <rect width="644" height="72" fill={theme.palette.icon.menu} />
        </clipPath>
      </defs>
    </svg>
  );
}
