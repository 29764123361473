import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { RoundTick } from 'assets/component';
import { COLORS } from 'styles/colors';
import CustomTooltip from '../Tooltip';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { CUSTOM_ROW_WIDTH } from 'utils/Constants';

function SiteRowItem({ key, rowItem, tableName, onSiteHandleClick }: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell
        item
        sm={false}
        md={2.4}
        theme={theme}
        width={CUSTOM_ROW_WIDTH}
      >
        <CustomTooltip title={rowItem.Name} placement="top">
          <span
            className="name link"
            onClick={e => onSiteHandleClick(e, rowItem.Id)}
          >
            {rowItem.Name}
          </span>
        </CustomTooltip>
      </TableCell>

      <TableCell
        item
        sm={false}
        md={1.5}
        theme={theme}
        width={CUSTOM_ROW_WIDTH}
      >
        <OverflowTip
          text={rowItem.Address ? rowItem.Address : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip text={rowItem.City ? rowItem.City : '-'}></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip text={rowItem.State ? rowItem.State : '-'}></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1.2} theme={theme}>
        <OverflowTip
          text={rowItem.ServiceLevel === null ? '-' : rowItem.ServiceLevel}
        ></OverflowTip>
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.IPNETW ? <RoundTick color={COLORS.lightGreen} /> : '-'}
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.BURG ? <RoundTick color={COLORS.lightGreen} /> : '-'}
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.VOIP ? <RoundTick color={COLORS.lightGreen} /> : '-'}
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.CCTV ? <RoundTick color={COLORS.lightGreen} /> : '-'}
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.FIRE ? <RoundTick color={COLORS.lightGreen} /> : '-'}
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.ACCESS_CONTROL ? <RoundTick color={COLORS.lightGreen} /> : '-'}
      </TableCell>
      <TableCell
        item
        sm={false}
        md={0.7}
        theme={theme}
        sx={{ justifyContent: 'center' }}
      >
        {rowItem.FIRE_INTRUSION_COMBO ? (
          <RoundTick color={COLORS.lightGreen} />
        ) : (
          '-'
        )}
      </TableCell>
    </TableRow>
  );
}
export default SiteRowItem;
