import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer } from './slice';
import { globalSaga } from './saga';

function GlobalSlice() {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: globalSaga });

  return <></>;
}

export default GlobalSlice;
