import { Grid } from '@mui/material';
import { CHARTS_DATA } from 'utils/Constants';
import { CustomBadge } from './Charts/style';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

export const TextWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem !important',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.875rem !important',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.25rem !important',
  },
}));

export function Labels({
  labelCount = {},
  customStyle = {},
  mapData = null,
}: any): JSX.Element {
  const theme = useTheme();
  const chartData: any = mapData ?? CHARTS_DATA;
  return (
    <>
      {chartData.map((it: any) => {
        return (
          <>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {<CustomBadge badgecolor={it.fill} variant="dot" />}
            </Grid>
            <TextWrapper theme={theme} item sx={{ ...customStyle }}>
              {it.label}
              {labelCount[it.label.toLowerCase()] >= 0
                ? `(${labelCount[it.label.toLowerCase()]})`
                : ''}
            </TextWrapper>
          </>
        );
      })}
    </>
  );
}

export default Labels;
