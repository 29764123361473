import { Grid } from '@mui/material';
import { PrimaryButton } from 'app/component/core/Buttons';
import Inputs from 'app/component/core/CustomInputs';
import { COLORS } from 'styles/colors';

export function PersonalDetails({ oncancel }: any): JSX.Element {
  return (
    <>
      <Grid container direction="column" xs={12} md={12} lg={12} p={2}>
        {/* <Grid container item direction="row" justifyContent="flex-end">
          <Grid style={{ paddingRight: "0.625rem" }}>
            <PrimaryButton
              children={"Remove User"}
              disabled={false}
              fullWidth={false}
            />
          </Grid>

          <Grid style={{ paddingRight: "0.625rem" }}>
            <PrimaryButton
              children={"Suspend User"}
              disabled={false}
              fullWidth={false}
            />
          </Grid>
          <PrimaryButton
            children={"Send Password Change"}
            disabled={false}
            fullWidth={false}
          />
        </Grid> */}
        <div>Personal Information</div>

        <Grid container item direction="row" mt={2}>
          <Inputs label="User Name" />
          <Inputs label="Contact Number" />
          <Inputs label="Password" />
          <Inputs label="Email Address" />
          <Inputs label="Alternate Number" />
          <Inputs label="Confirm Password" />
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          spacing={2}
          mt={3}
        >
          <PrimaryButton
            style={{
              background: COLORS.white,
              marginRight: '0.625rem',
              color: COLORS.jaguar,
            }}
            children={'Cancel'}
            disabled={false}
            fullWidth={false}
            onClick={oncancel}
          />
          <PrimaryButton children={'Save'} disabled={true} fullWidth={false} />
        </Grid>
      </Grid>
    </>
  );
}

export default PersonalDetails;
