import { useTheme } from '@mui/material/styles';
import { COLORS } from 'styles/colors';

export default function HelpLogoIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="248"
      height="58"
      viewBox="0 0 248 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M239.719 32.9318H205.818C204.181 32.9318 203.001 31.3751 203.44 29.7999L208.351 12.1449C208.647 11.0762 209.623 10.335 210.729 10.335H244.63C246.267 10.335 247.447 11.8916 247.009 13.4669L242.098 31.1218C241.801 32.1905 240.825 32.9318 239.719 32.9318Z"
        fill={COLORS.lightTintRed}
      />
      <path
        d="M214.318 24.691C213.892 24.4439 213.571 24.0918 213.342 23.6346C213.114 23.1775 213.002 22.6401 213.002 22.0223C213.002 21.3984 213.114 20.861 213.342 20.3977C213.571 19.9405 213.892 19.5823 214.3 19.3228C214.713 19.0695 215.201 18.9398 215.757 18.9398C216.313 18.9398 216.801 19.0634 217.209 19.3105C217.617 19.5576 217.932 19.9035 218.154 20.3421C218.377 20.7868 218.482 21.3058 218.482 21.8926V22.3374H213.682V21.4169H217.821L217.154 22.2756V21.7443C217.154 21.3613 217.092 21.0401 216.974 20.7868C216.857 20.5336 216.69 20.3421 216.486 20.2124C216.283 20.0826 216.042 20.0209 215.77 20.0209C215.498 20.0209 215.251 20.0888 215.041 20.2185C214.831 20.3483 214.664 20.5459 214.54 20.8054C214.423 21.0648 214.361 21.3799 214.361 21.7505V22.2818C214.361 22.6401 214.423 22.9489 214.54 23.2022C214.658 23.4555 214.831 23.647 215.053 23.7829C215.276 23.9188 215.541 23.9867 215.844 23.9867C216.073 23.9867 216.276 23.9497 216.449 23.8755C216.622 23.8014 216.764 23.7087 216.876 23.5975C216.987 23.4802 217.067 23.3566 217.11 23.2269L217.123 23.1899H218.408L218.395 23.2516C218.346 23.474 218.259 23.6964 218.13 23.9126C218 24.1288 217.827 24.3265 217.611 24.4995C217.394 24.6724 217.135 24.8083 216.832 24.9133C216.53 25.0184 216.196 25.0678 215.813 25.0678C215.226 25.0678 214.72 24.9442 214.3 24.6971L214.318 24.691Z"
        fill={COLORS.white}
      />
      <path
        d="M219.983 24.6972C219.618 24.4501 219.34 24.098 219.143 23.6409C218.945 23.1837 218.846 22.6401 218.846 22.01C218.846 21.3738 218.945 20.8302 219.143 20.373C219.34 19.9159 219.624 19.5638 219.983 19.3167C220.341 19.0696 220.773 18.946 221.261 18.946C221.682 18.946 222.046 19.0449 222.367 19.2487C222.688 19.4526 222.929 19.7244 223.096 20.0765H223.127V17.3029C223.127 17.1052 223.288 16.9446 223.485 16.9446H224.14C224.338 16.9446 224.498 17.1052 224.498 17.3029V24.5798C224.498 24.7775 224.338 24.9381 224.14 24.9381H223.127V23.9374H223.096C222.929 24.2833 222.688 24.5551 222.373 24.7528C222.058 24.9505 221.688 25.0493 221.261 25.0493C220.767 25.0493 220.335 24.9257 219.97 24.6787L219.983 24.6972ZM222.441 23.6718C222.658 23.5173 222.831 23.2949 222.954 23.0108C223.078 22.7266 223.139 22.393 223.139 22.01C223.139 21.6209 223.078 21.2873 222.954 21.0031C222.831 20.719 222.658 20.5028 222.441 20.3483C222.225 20.1939 221.972 20.1136 221.688 20.1136C221.404 20.1136 221.138 20.1877 220.922 20.3421C220.706 20.4966 220.539 20.7128 220.421 20.9908C220.304 21.2688 220.248 21.6085 220.248 21.9977C220.248 22.393 220.304 22.7266 220.421 23.0108C220.539 23.2949 220.706 23.5173 220.922 23.6656C221.138 23.82 221.391 23.8941 221.688 23.8941C221.984 23.8941 222.225 23.8138 222.441 23.6594V23.6718Z"
        fill={COLORS.white}
      />
      <path
        d="M226.685 26.7913C226.302 26.6492 225.993 26.4516 225.765 26.2045C225.536 25.9574 225.394 25.6794 225.339 25.3705L225.326 25.3211H226.679L226.704 25.3644C226.778 25.5373 226.932 25.6732 227.161 25.7906C227.389 25.9018 227.686 25.9574 228.038 25.9574C228.489 25.9574 228.835 25.8462 229.076 25.6238C229.317 25.4014 229.434 25.1049 229.434 24.7281V23.8818H229.409C229.304 24.0918 229.156 24.271 228.983 24.4254C228.804 24.5798 228.594 24.6972 228.353 24.7775C228.118 24.864 227.853 24.9072 227.575 24.9072C227.081 24.9072 226.654 24.7899 226.29 24.5489C225.925 24.308 225.647 23.9683 225.45 23.5235C225.252 23.0849 225.153 22.5536 225.153 21.9483C225.153 21.3243 225.252 20.7869 225.456 20.3421C225.66 19.8974 225.938 19.5514 226.308 19.3105C226.679 19.0696 227.105 18.946 227.599 18.946C227.877 18.946 228.131 18.9955 228.365 19.0881C228.6 19.1808 228.804 19.3105 228.977 19.4773C229.15 19.6441 229.298 19.8418 229.409 20.0642H229.44V19.0634H230.46C230.657 19.0634 230.818 19.224 230.818 19.4217V24.7713C230.818 25.2161 230.707 25.6053 230.478 25.9388C230.25 26.2724 229.934 26.5319 229.521 26.7172C229.107 26.9025 228.613 26.9952 228.044 26.9952C227.525 26.9952 227.074 26.9211 226.685 26.779V26.7913ZM228.755 23.6223C228.971 23.4679 229.138 23.2517 229.255 22.9737C229.372 22.6957 229.434 22.3621 229.434 21.9853C229.434 21.5961 229.372 21.2688 229.249 20.9908C229.125 20.7128 228.958 20.4966 228.742 20.3421C228.526 20.1877 228.279 20.1136 227.995 20.1136C227.711 20.1136 227.445 20.1877 227.223 20.3421C227 20.4966 226.833 20.7066 226.716 20.9846C226.599 21.2626 226.537 21.59 226.537 21.9791C226.537 22.3683 226.599 22.7019 226.716 22.9799C226.833 23.2579 227 23.4741 227.223 23.6285C227.445 23.7829 227.698 23.8571 227.995 23.8571C228.291 23.8571 228.532 23.7829 228.748 23.6285L228.755 23.6223Z"
        fill={COLORS.white}
      />
      <path
        d="M232.72 24.691C232.294 24.4439 231.973 24.0918 231.744 23.6346C231.516 23.1775 231.405 22.6401 231.405 22.0223C231.405 21.3984 231.516 20.861 231.744 20.3977C231.973 19.9405 232.294 19.5823 232.702 19.3228C233.116 19.0695 233.604 18.9398 234.16 18.9398C234.716 18.9398 235.204 19.0634 235.612 19.3105C236.019 19.5576 236.334 19.9035 236.557 20.3421C236.779 20.7868 236.884 21.3058 236.884 21.8926V22.3374H232.084V21.4169H236.223L235.556 22.2756V21.7443C235.556 21.3613 235.494 21.0401 235.377 20.7868C235.259 20.5336 235.093 20.3421 234.889 20.2124C234.685 20.0826 234.444 20.0209 234.172 20.0209C233.9 20.0209 233.653 20.0888 233.443 20.2185C233.233 20.3483 233.066 20.5459 232.943 20.8054C232.826 21.0648 232.764 21.3799 232.764 21.7505V22.2818C232.764 22.6401 232.826 22.9489 232.943 23.2022C233.06 23.4555 233.233 23.647 233.456 23.7829C233.678 23.9188 233.944 23.9867 234.246 23.9867C234.475 23.9867 234.679 23.9497 234.852 23.8755C235.025 23.8014 235.167 23.7087 235.278 23.5975C235.389 23.4802 235.469 23.3566 235.513 23.2269L235.525 23.1899H236.81L236.798 23.2516C236.748 23.474 236.662 23.6964 236.532 23.9126C236.402 24.1288 236.229 24.3265 236.013 24.4995C235.797 24.6724 235.537 24.8083 235.235 24.9133C234.932 25.0184 234.598 25.0678 234.215 25.0678C233.629 25.0678 233.122 24.9442 232.702 24.6971L232.72 24.691Z"
        fill={COLORS.white}
      />
      <path
        d="M61.4955 31.9495C59.8461 30.992 58.5613 29.6329 57.6532 27.8539C56.7389 26.081 56.288 23.9683 56.288 21.5097V21.4665C56.288 19.0264 56.7451 16.9199 57.6655 15.1408C58.586 13.3679 59.8709 12.0027 61.5202 11.0452C63.1696 10.0877 65.1031 9.61206 67.3146 9.61206C69.5261 9.61206 71.4843 10.0877 73.1399 11.0452C74.8016 12.0027 76.0865 13.3617 77.0069 15.1285C77.9274 16.8952 78.3845 19.0079 78.3845 21.4603V21.5035C78.3845 23.9745 77.9273 26.0933 77.0193 27.8724C76.105 29.6453 74.8263 31.0043 73.1769 31.9557C71.5276 32.9008 69.5817 33.3764 67.3393 33.3764C65.0969 33.3764 63.151 32.9008 61.5017 31.9433L61.4955 31.9495ZM70.3044 28.1689C71.1384 27.5821 71.7808 26.7234 72.2318 25.5929C72.6827 24.4625 72.9113 23.1035 72.9113 21.5097V21.4665C72.9113 19.885 72.6828 18.5384 72.2195 17.4141C71.7623 16.296 71.1075 15.4373 70.2674 14.8505C69.4273 14.2636 68.4389 13.9671 67.3084 13.9671C66.178 13.9671 65.2266 14.2636 64.3865 14.8505C63.5464 15.4373 62.8916 16.296 62.4283 17.4141C61.9588 18.5322 61.7302 19.885 61.7302 21.4665V21.5097C61.7302 23.1035 61.965 24.4625 62.4283 25.5929C62.8916 26.7234 63.5464 27.5759 64.3865 28.1689C65.2266 28.7558 66.215 29.0523 67.3455 29.0523C68.476 29.0523 69.4643 28.7558 70.2983 28.1689H70.3044Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M80.3056 10.0939H84.2344C84.6236 10.0939 84.9387 10.409 84.9387 10.7981V13.6644H85.0684C85.6553 12.4289 86.5386 11.4467 87.7061 10.7116C88.8736 9.97653 90.3253 9.61206 92.0488 9.61206C94.563 9.61206 96.5027 10.3719 97.8741 11.8977C99.2455 13.4173 99.9312 15.5053 99.9312 18.143V32.2151C99.9312 32.6043 99.6161 32.9193 99.227 32.9193H95.2796C94.8904 32.9193 94.5754 32.6043 94.5754 32.2151V19.2241C94.5754 17.5994 94.1924 16.3454 93.4326 15.462C92.6727 14.5787 91.5299 14.1401 90.0103 14.1401C88.991 14.1401 88.1015 14.3748 87.3417 14.8381C86.5819 15.3076 85.9888 15.9501 85.575 16.7778C85.1611 17.6056 84.951 18.5693 84.951 19.675V32.2151C84.951 32.6043 84.636 32.9193 84.2468 32.9193H80.318C79.9288 32.9193 79.6138 32.6043 79.6138 32.2151V10.7919C79.6138 10.4028 79.9288 10.0877 80.318 10.0877L80.3056 10.0939Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M106.164 31.9369C104.521 30.9733 103.261 29.6081 102.384 27.8414C101.506 26.0746 101.068 23.9867 101.068 21.5713V21.5466C101.068 19.1498 101.506 17.0556 102.39 15.2642C103.273 13.4789 104.515 12.089 106.115 11.0945C107.715 10.1061 109.611 9.60571 111.792 9.60571C113.972 9.60571 115.838 10.0814 117.426 11.0389C119.013 11.9964 120.23 13.3307 121.089 15.0542C121.941 16.7777 122.374 18.7853 122.374 21.0833V22.1026C122.374 22.4917 122.059 22.8068 121.669 22.8068H104.428C104.039 22.8068 103.724 22.4917 103.724 22.1026V19.9405C103.724 19.5513 104.039 19.2362 104.428 19.2362H118.371C118.958 19.2362 119.291 19.9096 118.927 20.3729L118.488 20.935C118.074 21.4663 117.222 21.176 117.222 20.5026C117.222 19.0077 116.993 17.766 116.53 16.7777C116.073 15.7893 115.436 15.0418 114.633 14.5476C113.83 14.0534 112.904 13.8063 111.854 13.8063C110.803 13.8063 109.846 14.0596 109.018 14.5723C108.19 15.0851 107.548 15.8387 107.078 16.8456C106.609 17.8525 106.38 19.0695 106.38 20.5088V22.5782C106.38 23.9743 106.615 25.1604 107.078 26.1426C107.548 27.1248 108.209 27.8784 109.074 28.4035C109.932 28.9286 110.964 29.188 112.156 29.188C113.046 29.188 113.83 29.0459 114.504 28.768C115.177 28.49 115.733 28.1255 116.172 27.6807C116.61 27.236 116.913 26.7541 117.086 26.2352L117.129 26.087H122.127L122.083 26.3217C121.898 27.1989 121.552 28.0576 121.052 28.8977C120.551 29.7378 119.872 30.4976 119.025 31.1709C118.179 31.8443 117.172 32.3817 116.011 32.7771C114.85 33.1724 113.528 33.3701 112.045 33.3701C109.759 33.3701 107.795 32.8883 106.152 31.9246L106.164 31.9369Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M122.139 10.0937H126.321C126.636 10.0937 126.908 10.2976 127 10.6003L132.152 27.9525H132.282L137.434 10.6003C137.521 10.2976 137.799 10.0937 138.114 10.0937H142.166C142.654 10.0937 142.994 10.5755 142.833 11.0327L135.297 32.4558C135.198 32.74 134.932 32.9253 134.63 32.9253H129.725C129.428 32.9253 129.156 32.7338 129.058 32.4558L121.478 11.0327C121.317 10.5755 121.657 10.0875 122.145 10.0875L122.139 10.0937Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M145.038 10.0939H148.986C149.375 10.0939 149.69 10.4089 149.69 10.7981V32.2213C149.69 32.6104 149.375 32.9255 148.986 32.9255H145.038C144.649 32.9255 144.334 32.6104 144.334 32.2213V10.7981C144.334 10.4089 144.649 10.0939 145.038 10.0939Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M156.182 31.9369C154.539 30.9733 153.279 29.6081 152.402 27.8414C151.525 26.0746 151.086 23.9867 151.086 21.5713V21.5466C151.086 19.1498 151.531 17.0556 152.408 15.2642C153.291 13.4789 154.533 12.089 156.133 11.0945C157.733 10.1061 159.629 9.60571 161.81 9.60571C163.991 9.60571 165.856 10.0814 167.444 11.0389C169.031 11.9964 170.248 13.3307 171.107 15.0542C171.959 16.7777 172.392 18.7853 172.392 21.0833V22.1026C172.392 22.4917 172.077 22.8068 171.688 22.8068H154.447C154.057 22.8068 153.742 22.4917 153.742 22.1026V19.9405C153.742 19.5513 154.057 19.2362 154.447 19.2362H168.389C168.976 19.2362 169.309 19.9096 168.945 20.3729L168.506 20.935C168.092 21.4663 167.24 21.176 167.24 20.5026C167.24 19.0077 167.011 17.766 166.548 16.7777C166.091 15.7893 165.455 15.0418 164.652 14.5476C163.849 14.0534 162.922 13.8063 161.872 13.8063C160.822 13.8063 159.864 14.0596 159.036 14.5723C158.209 15.0851 157.566 15.8387 157.097 16.8456C156.627 17.8525 156.399 19.0695 156.399 20.5088V22.5782C156.399 23.9743 156.633 25.1604 157.097 26.1426C157.566 27.1248 158.227 27.8784 159.092 28.4035C159.951 28.9286 160.982 29.188 162.174 29.188C163.064 29.188 163.849 29.0459 164.522 28.768C165.195 28.49 165.751 28.1255 166.19 27.6807C166.628 27.236 166.931 26.7541 167.104 26.2352L167.147 26.087H172.145L172.102 26.3217C171.916 27.1989 171.57 28.0576 171.07 28.8977C170.57 29.7378 169.89 30.4976 169.044 31.1709C168.197 31.8443 167.19 32.3817 166.029 32.7771C164.868 33.1724 163.546 33.3701 162.063 33.3701C159.778 33.3701 157.813 32.8883 156.17 31.9246L156.182 31.9369Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M172.744 10.0939H176.636C176.969 10.0939 177.253 10.3224 177.328 10.6498L181.059 27.6315H181.164L185.488 10.6313C185.568 10.3163 185.852 10.1001 186.174 10.1001H190.201C190.522 10.1001 190.807 10.3163 190.887 10.6313L195.23 27.6376H195.335L199.066 10.656C199.14 10.3348 199.424 10.1001 199.758 10.1001H203.563C204.026 10.1001 204.366 10.5448 204.242 10.9896L198.46 32.4128C198.38 32.7216 198.096 32.9378 197.781 32.9378H193.222C192.901 32.9378 192.623 32.7216 192.542 32.4128L188.181 16.2404H188.076L183.734 32.4128C183.653 32.7216 183.369 32.9378 183.048 32.9378H178.551C178.229 32.9378 177.952 32.7216 177.871 32.4189L172.052 10.9958C171.929 10.5448 172.268 10.1062 172.732 10.1062L172.744 10.0939Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M40.3074 0H7.25226C3.24314 0 0 3.2493 0 7.25224V34.7478C0 38.7569 3.24931 42 7.25226 42H40.3074C44.3165 42 47.5596 38.7507 47.5596 34.7478V7.25224C47.5596 3.24312 44.3103 0 40.3074 0ZM8.95104 34.4142H7.71556V7.85144H8.95104V34.4142ZM17.5993 34.4142H15.1284V7.85144H17.5993V34.4142ZM27.4832 34.4142H23.7767V7.85144H27.4832V34.4142ZM39.8379 34.4142H33.6605V7.85144H39.8379V34.4142Z"
        fill={COLORS.lightTintRed}
      />
      <path
        d="M170.328 48.1818C170.328 49.4091 170.107 50.4697 169.664 51.3636C169.22 52.2576 168.612 52.947 167.84 53.4318C167.067 53.9167 166.184 54.1591 165.192 54.1591C164.2 54.1591 163.317 53.9167 162.544 53.4318C161.772 52.947 161.164 52.2576 160.72 51.3636C160.277 50.4697 160.056 49.4091 160.056 48.1818C160.056 46.9545 160.277 45.8939 160.72 45C161.164 44.1061 161.772 43.4167 162.544 42.9318C163.317 42.447 164.2 42.2045 165.192 42.2045C166.184 42.2045 167.067 42.447 167.84 42.9318C168.612 43.4167 169.22 44.1061 169.664 45C170.107 45.8939 170.328 46.9545 170.328 48.1818ZM168.965 48.1818C168.965 47.1742 168.796 46.3239 168.459 45.6307C168.126 44.9375 167.673 44.4129 167.101 44.0568C166.533 43.7008 165.897 43.5227 165.192 43.5227C164.487 43.5227 163.849 43.7008 163.277 44.0568C162.709 44.4129 162.256 44.9375 161.919 45.6307C161.586 46.3239 161.419 47.1742 161.419 48.1818C161.419 49.1894 161.586 50.0398 161.919 50.733C162.256 51.4261 162.709 51.9508 163.277 52.3068C163.849 52.6629 164.487 52.8409 165.192 52.8409C165.897 52.8409 166.533 52.6629 167.101 52.3068C167.673 51.9508 168.126 51.4261 168.459 50.733C168.796 50.0398 168.965 49.1894 168.965 48.1818Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M173.857 48.75V54H172.516V45.2727H173.811V46.6364H173.925C174.13 46.1932 174.44 45.8371 174.857 45.5682C175.273 45.2955 175.811 45.1591 176.47 45.1591C177.061 45.1591 177.578 45.2803 178.022 45.5227C178.465 45.7614 178.809 46.125 179.056 46.6136C179.302 47.0985 179.425 47.7121 179.425 48.4545V54H178.084V48.5455C178.084 47.8598 177.906 47.3258 177.55 46.9432C177.194 46.5568 176.705 46.3636 176.084 46.3636C175.656 46.3636 175.273 46.4564 174.936 46.642C174.603 46.8277 174.34 47.0985 174.147 47.4545C173.953 47.8106 173.857 48.2424 173.857 48.75Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M183.216 42.3636V54H181.875V42.3636H183.216Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M185.672 54V45.2727H187.013V54H185.672ZM186.354 43.8182C186.093 43.8182 185.867 43.7292 185.678 43.5511C185.492 43.3731 185.399 43.1591 185.399 42.9091C185.399 42.6591 185.492 42.4451 185.678 42.267C185.867 42.089 186.093 42 186.354 42C186.615 42 186.839 42.089 187.024 42.267C187.214 42.4451 187.309 42.6591 187.309 42.9091C187.309 43.1591 187.214 43.3731 187.024 43.5511C186.839 43.7292 186.615 43.8182 186.354 43.8182Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M190.81 48.75V54H189.469V45.2727H190.764V46.6364H190.878C191.083 46.1932 191.393 45.8371 191.81 45.5682C192.227 45.2955 192.764 45.1591 193.424 45.1591C194.014 45.1591 194.532 45.2803 194.975 45.5227C195.418 45.7614 195.763 46.125 196.009 46.6136C196.255 47.0985 196.378 47.7121 196.378 48.4545V54H195.037V48.5455C195.037 47.8598 194.859 47.3258 194.503 46.9432C194.147 46.5568 193.658 46.3636 193.037 46.3636C192.609 46.3636 192.227 46.4564 191.889 46.642C191.556 46.8277 191.293 47.0985 191.1 47.4545C190.907 47.8106 190.81 48.2424 190.81 48.75Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M202.487 54.1818C201.647 54.1818 200.921 53.9962 200.311 53.625C199.705 53.25 199.237 52.7273 198.908 52.0568C198.582 51.3826 198.419 50.5985 198.419 49.7045C198.419 48.8106 198.582 48.0227 198.908 47.3409C199.237 46.6553 199.696 46.1212 200.283 45.7386C200.874 45.3523 201.563 45.1591 202.351 45.1591C202.806 45.1591 203.255 45.2348 203.698 45.3864C204.141 45.5379 204.544 45.7841 204.908 46.125C205.272 46.4621 205.561 46.9091 205.777 47.4659C205.993 48.0227 206.101 48.7083 206.101 49.5227V50.0909H199.374V48.9318H204.737C204.737 48.4394 204.639 48 204.442 47.6136C204.249 47.2273 203.972 46.9223 203.612 46.6989C203.256 46.4754 202.836 46.3636 202.351 46.3636C201.817 46.3636 201.355 46.4962 200.965 46.7614C200.578 47.0227 200.281 47.3636 200.073 47.7841C199.864 48.2045 199.76 48.6553 199.76 49.1364V49.9091C199.76 50.5682 199.874 51.1269 200.101 51.5852C200.332 52.0398 200.652 52.3864 201.061 52.625C201.47 52.8598 201.946 52.9773 202.487 52.9773C202.84 52.9773 203.158 52.928 203.442 52.8295C203.73 52.7273 203.978 52.5758 204.186 52.375C204.395 52.1705 204.556 51.9167 204.669 51.6136L205.965 51.9773C205.828 52.4167 205.599 52.803 205.277 53.1364C204.955 53.4659 204.558 53.7235 204.084 53.9091C203.611 54.0909 203.078 54.1818 202.487 54.1818Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M212.823 54V42.3636H214.232V47.5455H220.436V42.3636H221.845V54H220.436V48.7955H214.232V54H212.823Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M228.144 54.1818C227.303 54.1818 226.577 53.9962 225.968 53.625C225.362 53.25 224.894 52.7273 224.564 52.0568C224.238 51.3826 224.076 50.5985 224.076 49.7045C224.076 48.8106 224.238 48.0227 224.564 47.3409C224.894 46.6553 225.352 46.1212 225.939 45.7386C226.53 45.3523 227.219 45.1591 228.007 45.1591C228.462 45.1591 228.911 45.2348 229.354 45.3864C229.797 45.5379 230.201 45.7841 230.564 46.125C230.928 46.4621 231.218 46.9091 231.434 47.4659C231.649 48.0227 231.757 48.7083 231.757 49.5227V50.0909H225.03V48.9318H230.394C230.394 48.4394 230.295 48 230.098 47.6136C229.905 47.2273 229.629 46.9223 229.269 46.6989C228.913 46.4754 228.492 46.3636 228.007 46.3636C227.473 46.3636 227.011 46.4962 226.621 46.7614C226.235 47.0227 225.937 47.3636 225.729 47.7841C225.521 48.2045 225.416 48.6553 225.416 49.1364V49.9091C225.416 50.5682 225.53 51.1269 225.757 51.5852C225.988 52.0398 226.309 52.3864 226.718 52.625C227.127 52.8598 227.602 52.9773 228.144 52.9773C228.496 52.9773 228.814 52.928 229.098 52.8295C229.386 52.7273 229.634 52.5758 229.843 52.375C230.051 52.1705 230.212 51.9167 230.326 51.6136L231.621 51.9773C231.485 52.4167 231.255 52.803 230.934 53.1364C230.612 53.4659 230.214 53.7235 229.74 53.9091C229.267 54.0909 228.735 54.1818 228.144 54.1818Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M235.138 42.3636V54H233.797V42.3636H235.138Z"
        fill={theme.palette.customBackground.logo}
      />
      <path
        d="M237.594 57.2727V45.2727H238.889V46.6591H239.049C239.147 46.5076 239.283 46.3144 239.458 46.0795C239.636 45.8409 239.889 45.6288 240.219 45.4432C240.552 45.2538 241.003 45.1591 241.571 45.1591C242.306 45.1591 242.954 45.3428 243.514 45.7102C244.075 46.0777 244.513 46.5985 244.827 47.2727C245.141 47.947 245.299 48.7424 245.299 49.6591C245.299 50.5833 245.141 51.3845 244.827 52.0625C244.513 52.7367 244.077 53.2595 243.52 53.6307C242.963 53.9981 242.321 54.1818 241.594 54.1818C241.033 54.1818 240.585 54.089 240.247 53.9034C239.91 53.714 239.651 53.5 239.469 53.2614C239.287 53.0189 239.147 52.8182 239.049 52.6591H238.935V57.2727H237.594ZM238.912 49.6364C238.912 50.2955 239.009 50.8769 239.202 51.3807C239.395 51.8807 239.677 52.2727 240.049 52.5568C240.42 52.8371 240.874 52.9773 241.412 52.9773C241.973 52.9773 242.441 52.8295 242.816 52.5341C243.194 52.2348 243.478 51.8333 243.668 51.3295C243.861 50.822 243.958 50.2576 243.958 49.6364C243.958 49.0227 243.863 48.4697 243.674 47.9773C243.488 47.4811 243.206 47.089 242.827 46.8011C242.452 46.5095 241.98 46.3636 241.412 46.3636C240.867 46.3636 240.408 46.5019 240.037 46.7784C239.666 47.0511 239.386 47.4337 239.196 47.9261C239.007 48.4148 238.912 48.9848 238.912 49.6364Z"
        fill={theme.palette.customBackground.logo}
      />
    </svg>
  );
}
