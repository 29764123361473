export default function PermissionSaveIcon(): JSX.Element {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.932129"
        y="0.900391"
        width="18"
        height="18"
        rx="4"
        fill="#13388E"
      />
      <path
        d="M14.0099 15.1431H5.85451C5.54552 15.1431 5.24918 15.0204 5.03069 14.8019C4.8122 14.5834 4.68945 14.2871 4.68945 13.9781V5.82265C4.68945 5.51366 4.8122 5.21732 5.03069 4.99883C5.24918 4.78034 5.54552 4.65759 5.85451 4.65759H12.2623L15.175 7.57024V13.9781C15.175 14.2871 15.0522 14.5834 14.8337 14.8019C14.6153 15.0204 14.3189 15.1431 14.0099 15.1431Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8453 15.1431V10.4829H7.02002V15.1431"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02002 4.65759V7.57024H11.6803"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
