import { Grid } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const DashboardContainer = styled(Grid)`
  width: "auto",
  height: "100vh",
  fontFamily: "Inter, Sans-serif",
  fontStyle: "normal",
`;
export const Title = styled('h4')`
  font-weight: 700;
  font-size: 18px;
  color: ${COLORS.black};
`;
export const TabsContainer = styled(Grid)<any>(({ theme }) => ({
  background: theme.palette.customBackground.main,
  borderRadius: '0.5rem',
}));

export const CardContainer = styled('div')<any>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  background: theme.palette.customBackground.main,
  borderRadius: '0.5rem',
  padding: '1rem',
}));
