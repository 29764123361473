import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { DATE_FORMAT_1 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function NetworkOutagesRowItem({
  key,
  rowItem,
  tableName,
  onSiteHandleClick = () => {},
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={3}>
        <OverflowTip
          text={
            <span
              className="name link"
              onClick={e => onSiteHandleClick(e, rowItem?.Id)}
            >
              {rowItem.Name}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem.CurrentStatus}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem.Chronic.toString()}></OverflowTip>
      </TableCell>
      <TableCell item xs={3}>
        <OverflowTip
          text={getFormatedDate(rowItem.StatusChangeTime, DATE_FORMAT_1)}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={rowItem.SwitchStatus === null ? '-' : rowItem.SwitchStatus}
        ></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default NetworkOutagesRowItem;
