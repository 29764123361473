/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer } from './slice';
import { securityManagerSaga } from './saga';

function SecurityManagerSlice() {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: securityManagerSaga });

  return <></>;
}

export default SecurityManagerSlice;
