import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const CustomTooltipWrapper = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme, ...props }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.jaguar,
    color: COLORS.white,
    maxWidth: props?.title?.length > 1000 ? '50rem !important' : 'auto',
    fontSize: '0.625rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.jaguar,
  },
}));
