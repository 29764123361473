import OverflowTip from '../CustomFixedListView/OverflowTip';
import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';

function SystemExportRowItem({
  key,
  rowItem,
  tableName,
  isAutoCells,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      {isAutoCells ? (
        Object.keys(rowItem).length > 0 &&
        Object.keys(rowItem).map((key: any, value: any) => {
          const count = Object.keys(rowItem).length <= 12 ? 3 : 1;
          return (
            <TableCell item xs={count}>
              {key === 'SiteName' ? (
                <OverflowTip
                  text={rowItem.SiteName ? rowItem.SiteName : '-'}
                ></OverflowTip>
              ) : (
                rowItem[key] || '-'
              )}
            </TableCell>
          );
        })
      ) : (
        <>
          <TableCell item xs={3}>
            <OverflowTip
              text={rowItem.SystemNumber ? rowItem.SystemNumber : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={3}>
            <OverflowTip text={rowItem.Type ? rowItem.Type : '-'}></OverflowTip>
          </TableCell>
          <TableCell item xs={3}>
            <OverflowTip
              text={rowItem.SiteName ? rowItem.SiteName : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={3}>
            <OverflowTip
              text={rowItem.Status ? rowItem.Status : '-'}
            ></OverflowTip>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
export default SystemExportRowItem;
