import { useTheme } from '@mui/material/styles';

export default function TelephoneIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.4626 17.4158C35.1901 13.7719 33.5531 10.3648 30.8784 7.87513C28.2037 5.38544 24.6882 3.99637 21.0342 3.98535C17.3801 3.99637 13.8646 5.38544 11.1899 7.87513C8.51523 10.3648 6.87818 13.7719 6.60573 17.4158C5.90215 17.6646 5.29305 18.1254 4.86236 18.7348C4.43167 19.3443 4.2006 20.0723 4.20099 20.8185V28.0327C4.20099 28.9894 4.58102 29.9069 5.25749 30.5833C5.93395 31.2598 6.85143 31.6398 7.8081 31.6398H11.4152C11.7341 31.6398 12.0399 31.5132 12.2654 31.2877C12.4909 31.0622 12.6176 30.7564 12.6176 30.4375V18.4138C12.6176 18.0949 12.4909 17.7891 12.2654 17.5636C12.0399 17.3381 11.7341 17.2114 11.4152 17.2114H9.09463C9.44224 14.2891 10.8496 11.5957 13.05 9.64145C15.2504 7.68723 18.0912 6.60789 21.0342 6.60789C23.9771 6.60789 26.8179 7.68723 29.0183 9.64145C31.2188 11.5957 32.6261 14.2891 32.9737 17.2114H30.6531C30.3342 17.2114 30.0284 17.3381 29.8029 17.5636C29.5774 17.7891 29.4507 18.0949 29.4507 18.4138V30.4375C29.4492 30.7366 29.5593 31.0255 29.7594 31.2478C29.9595 31.4702 30.2353 31.6099 30.5329 31.6398C30.2557 32.9978 29.5179 34.2182 28.4442 35.0946C27.3706 35.971 26.0272 36.4496 24.6413 36.4493H23.4389V35.2469C23.4389 34.9281 23.3122 34.6222 23.0867 34.3967C22.8612 34.1713 22.5554 34.0446 22.2365 34.0446H17.427C17.1082 34.0446 16.8023 34.1713 16.5768 34.3967C16.3514 34.6222 16.2247 34.9281 16.2247 35.2469V37.6517C16.2247 37.9706 16.3514 38.2764 16.5768 38.5019C16.8023 38.7274 17.1082 38.8541 17.427 38.8541H24.6413C26.6635 38.8517 28.6172 38.1213 30.1451 36.7966C31.6729 35.4718 32.6728 33.6413 32.9617 31.6398H33.0578C34.3334 31.6398 35.5567 31.1331 36.4587 30.2312C37.3606 29.3292 37.8673 28.1059 37.8673 26.8304V20.8185C37.8677 20.0723 37.6366 19.3443 37.2059 18.7348C36.7753 18.1254 36.1662 17.6646 35.4626 17.4158Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
