import { Grid } from '@mui/material';
import { PrimaryButton } from 'app/component/core/Buttons';
import { ErrorBoundary } from 'react-error-boundary';
import { CenterWrapper, ErrorMessage } from '../styles';
import { ERROR_TEXT } from 'utils/Constants/Content';

export function ErrorsBoundary({ children }: any): JSX.Element {
  const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
    return (
      <CenterWrapper container role="alert">
        <Grid item p={2}>
          {ERROR_TEXT.API_ERROR}
        </Grid>
        <ErrorMessage item p={1}>
          {error.message}
        </ErrorMessage>

        <Grid item>
          <PrimaryButton
            onClick={resetErrorBoundary}
            children={'Try again'}
            fullWidth={false}
            disabled={false}
          />
        </Grid>
      </CenterWrapper>
    );
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
