import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { InstalledSystemsCSListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function InstalledSystemsCSRowItem({
  key,
  rowItem,
  tableName,
}: InstalledSystemsCSListProps): any {
  const theme = useTheme();

  return (
    <TableRow container key={key} alignItems="center" theme={theme}>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.SystemNumber || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.Type || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.Status || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.InstallationStatus || '-'}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default InstalledSystemsCSRowItem;
