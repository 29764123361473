import { useTheme } from '@mui/material/styles';

export default function NetsurionIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="231"
      height="57"
      viewBox="0 0 231 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.3314 0H59.4585V4.84432H64.3314V0Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M67.5387 9.70773H59.4585V17.7402H67.5387V9.70773Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M59.4588 4.84431H54.562V9.70772H59.4588V4.84431Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M0 15.392H8.71977L25.3766 42.1718H25.4864V15.392H33.705V55.3111H24.928L8.32841 28.5839H8.21386V55.3111H0V15.392Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M43.4892 42.7875C43.7135 47.818 46.1714 50.1136 50.5862 50.1136C53.7744 50.1136 56.3421 48.1568 56.848 46.367H63.8353C61.5969 53.1873 56.848 56.0939 50.3046 56.0939C41.1935 56.0939 35.5474 49.832 35.5474 40.888C35.5474 31.9439 41.5276 25.6248 50.3046 25.6248C60.1412 25.6248 64.8949 33.9007 64.3365 42.7875H43.4844H43.4892ZM56.4042 37.757C55.6787 33.7289 53.9462 31.605 50.0851 31.605C45.0546 31.605 43.599 35.5186 43.4892 37.757H56.4042Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M75.4614 26.4075H81.2746V31.7195H75.4614V46.0329C75.4614 48.7152 76.1344 49.3882 78.8167 49.3882C79.6567 49.3882 80.4394 49.3309 81.2746 49.1639V55.3684C79.9335 55.5927 78.201 55.6452 76.6355 55.6452C71.7721 55.6452 67.5244 54.5284 67.5244 48.7677V31.7148H62.7183V26.4027H67.5244V17.7354H75.4614V26.4027V26.4075Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M90.9444 45.9184C91.0016 49.3834 93.9082 50.7818 97.0344 50.7818C99.3253 50.7818 102.232 49.8893 102.232 47.0925C102.232 44.687 98.9339 43.847 93.2305 42.6204C88.6439 41.6134 84.0621 39.9954 84.0621 34.9029C84.0621 27.5243 90.4337 25.62 96.6382 25.62C102.843 25.62 108.77 27.7439 109.386 34.8457H101.84C101.616 31.772 99.268 30.932 96.4187 30.932C94.6289 30.932 92.0039 31.2661 92.0039 33.6143C92.0039 36.4684 96.476 36.8598 101.005 37.9193C105.644 38.9836 110.174 40.6589 110.174 46.0282C110.174 53.6311 103.578 56.0939 96.9819 56.0939C90.3859 56.0939 83.7328 53.5786 83.3987 45.9184H90.9444Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M148.355 26.4075C144.719 26.4075 141.034 27.9491 139.407 31.1755V26.4075H131.47V41.5609C131.47 47.4314 129.622 49.8368 125.317 49.8368C121.628 49.8368 120.23 47.4886 120.23 42.735V26.4075H112.293V44.1859C112.293 51.3402 114.417 56.0939 122.86 56.0939C126.215 56.0939 129.68 54.5284 131.694 51.2877H131.861V55.3111H139.411V42.2816C139.411 37.1939 141.425 32.9986 147.239 32.9986C148.188 32.9986 149.362 33.1084 150.088 33.2802V26.4027H148.355V26.4075Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M160.321 21.9307H152.384V15.3873H160.321V21.9307ZM152.384 26.4027H160.321V55.3064H152.384V26.4075V26.4027Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M177.021 25.62C186.127 25.62 191.997 31.6575 191.997 40.8784C191.997 50.0993 186.132 56.0795 177.021 56.0795C167.909 56.0795 162.101 50.042 162.101 40.8784C162.101 31.7148 167.967 25.62 177.021 25.62ZM177.021 50.0993C182.442 50.0993 184.06 45.4602 184.06 40.8784C184.06 36.2966 182.438 31.6002 177.021 31.6002C171.604 31.6002 170.038 36.2393 170.038 40.8784C170.038 45.5175 171.661 50.0993 177.021 50.0993Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M193.778 26.4027H201.323V30.4261H201.49C203.5 27.1854 206.965 25.62 210.32 25.62C218.758 25.62 220.882 30.3689 220.882 37.5232V55.2968H212.945V38.9789C212.945 34.23 211.546 31.8818 207.862 31.8818C203.557 31.8818 201.715 34.2873 201.715 40.1529V55.2968H193.778V26.4027Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M231 51.345C231 53.5548 229.487 55.2968 227.21 55.2968C224.934 55.2968 223.397 53.5977 223.397 51.345C223.397 49.0923 224.977 47.417 227.21 47.417C229.444 47.417 231 49.2307 231 51.345ZM224.256 51.345C224.256 53.087 225.511 54.4854 227.21 54.4854C229.119 54.4854 230.117 53.1586 230.117 51.3688C230.117 49.5791 228.952 48.2045 227.21 48.2045C225.258 48.2045 224.256 49.7175 224.256 51.345ZM225.674 53.2302V49.3452H227.325C228.16 49.3452 228.862 49.6459 228.862 50.462C228.862 50.9488 228.489 51.2782 228.213 51.3688C228.513 51.4834 228.747 51.765 228.747 52.3902C228.747 52.6002 228.747 52.9486 228.795 53.2254H227.888C227.817 53.0154 227.793 52.5954 227.793 52.4093C227.793 51.9225 227.678 51.7125 227.163 51.7125H226.604V53.2254H225.674V53.2302ZM226.604 51.0443H227.253C227.626 51.0443 227.883 50.9298 227.883 50.5098C227.883 50.1852 227.721 49.9991 227.253 49.9991H226.604V51.0443Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
