export default function NetworkIcon({ color = '#ffffff' }: any): JSX.Element {
  return (
    <svg
      xmlns="
http://www.w3.org/2000/svg"
      width="25"
      height="29"
      viewBox="0 0 25 29"
      fill="none"
    >
      <path
        d="M12.25 11.8724C13.4926 11.8724 14.5 10.7904 14.5 9.45573C14.5 8.12104 13.4926 7.03906 12.25 7.03906C11.0074 7.03906 10 8.12104 10 9.45573C10 10.7904 11.0074 11.8724 12.25 11.8724Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.021 4.14389C17.6487 4.76293 18.1467 5.49806 18.4865 6.30724C18.8262 7.11641 19.0011 7.98377 19.0011 8.85972C19.0011 9.73567 18.8262 10.603 18.4865 11.4122C18.1467 12.2214 17.6487 12.9565 17.021 13.5756M7.47898 13.5644C6.85126 12.9454 6.35328 12.2103 6.01352 11.4011C5.67375 10.5919 5.49887 9.72456 5.49887 8.84861C5.49887 7.97266 5.67375 7.10531 6.01352 6.29613C6.35328 5.48695 6.85126 4.75182 7.47898 4.13278M20.2054 1C22.3149 3.08327 23.5 5.90842 23.5 8.85417C23.5 11.7999 22.3149 14.6251 20.2054 16.7083M4.29455 16.7083C2.18505 14.6251 1 11.7999 1 8.85417C1 5.90842 2.18505 3.08327 4.29455 1"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.375 13.957L17.2464 24.832H7.50361L12.375 13.957Z"
        fill={color}
      />
    </svg>
  );
}
