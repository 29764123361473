import { Box, Grid, useTheme } from '@mui/material';
import CustomMaps from 'app/component/core/Maps';
import { Listview } from 'app/component/core/CustomListView';
import { useSelector } from 'react-redux';
import { getViewType } from 'app/features/DashboardSlice/Selectors';
import { PanelGrid } from '../../styles';
import { IconsButton } from 'app/component/core/Buttons';
import { ClearIcon } from 'assets/component';
import { CONTEXT } from 'utils/Constants/Content';

function Events(): JSX.Element {
  const themes = useTheme();
  const MapViewData: any = [];
  const ListViewData: any = [];
  const selectedView = useSelector(getViewType);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item container xs={10} spacing={1}>
            <Grid item container xs={11} spacing={2}></Grid>
            <Grid item xs={1}>
              <IconsButton
                name={'ClearFilter'}
                children={<ClearIcon color={themes.palette.icon.main} />}
                style={{
                  background: themes.palette.primary.main,
                  borderRadius: 4,
                  padding: '6px',
                }}
                title={CONTEXT.CLEAR_FILTER}
                isShowTooltip={true}
              />
            </Grid>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end"></Grid>
        </Grid>
        <PanelGrid container item pt={1} theme={themes}>
          {selectedView === 'map' && (
            <CustomMaps
              markerResponseData={
                MapViewData && MapViewData.length > 0 ? MapViewData : []
              }
            />
          )}
          {selectedView === 'list' && (
            <Listview
              headerItems={[]}
              listData={
                ListViewData && ListViewData.length > 0 ? ListViewData : []
              }
            />
          )}
        </PanelGrid>
      </Box>
    </>
  );
}

export default Events;

// import { Box, Grid, useTheme } from '@mui/material';
// import CustomMaps from 'app/component/core/Maps';
// import { Listview } from 'app/component/core/CustomListView';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   getCompanyId,
//   getDashboardFilter,
//   getDashboardListPageNo,
//   getFilterAlarmEvents,
//   getDashboardFilterData,
//   // getResolvedEvents,
//   getSiteManager,
//   getViewType,
// } from 'app/features/DashboardSlice/Selectors';
// import { PanelGrid } from '../../styles';
// import { IconsButton } from 'app/component/core/Buttons';
// import { ClearIcon } from 'assets/component';
// import { CONTEXT } from 'utils/Constants/Content';
// import { useEffect, useState } from 'react';
// import { SECURITY_EVENTS_HEADER, SORT_BY, SORT_OBJECT } from 'utils/Constants';
// import { FilterMenu } from 'app/component/core/Filter';
// import {
//   checkIEVersion,
//   customListSearch,
//   getFilterData,
//   manageDropdownDataForIE,
//   useMobileDevice,
// } from 'utils/CommonFn';
// // import { COLORS } from 'styles/colors';
// import { MultipleSelectCheckmarks } from 'app/component/core/CustomSelectWithoutLable/multiselectCheckbox';
// import { actions } from 'app/features/DashboardSlice/slice';
// import LocationFilter from 'app/component/common/LocationFilter';
// import { persistSiteInfoId } from 'app/features/authService';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { isEmpty } from 'utils/CommonFn/validators';
// import { CustomMultiselectIE } from 'app/component/core/CustomMultiselectIE';

// function Events({
//   isLocation = true,
//   isSort = false,
//   source = CONTEXT.DASHBOARD,
// }): JSX.Element {
//   const themes = useTheme();
//   const navigate = useNavigate();
//   const dashboardPageNo = useSelector(getDashboardListPageNo);
//   const isMobile = useMobileDevice();
//   const dispatch = useDispatch();
//   const { pathname } = useLocation();
//   const [currentPage, setCurrentPage] = useState(
//     pathname.includes('/dashboard/sitemanager') ? 1 : dashboardPageNo,
//   );
//   const viewType = useSelector(getViewType);
//   const isSiteManager: any = useSelector(getSiteManager);
//   const selectedView = isSiteManager ? 'list' : viewType;
//   const selectedCompanyId = useSelector(getCompanyId);
//   // const isResolvedEvents: any = useSelector(getResolvedEvents);
//   const getFilters = useSelector(getDashboardFilterData);
//   const filterEventStatus: any = getFilters?.data?.events ?? [];
//   const eventStatus = filterEventStatus.eventCategoryList || [];
//   const dashboardFilter: any = useSelector(getDashboardFilter);
//   const alarmEvents: any = useSelector(getFilterAlarmEvents);
//   const MapViewData: any = alarmEvents?.mapData ?? [];
//   const ListViewData: any = alarmEvents?.listData ?? [];
//   const totalListCount: any = alarmEvents?.recordCount ?? 0;
//   const { sortColumn, sortBy, eventType, locationValue } = dashboardFilter;
//   const eventOptionIE: any = manageDropdownDataForIE(eventStatus);
//   const [selectedEventIE, setSelectedEventIE] = useState(eventType ?? []);

//   const defaultDashboardFilter = (): any => {
//     let reqObj: any = {
//       page: currentPage,
//       recordCountRequire: selectedView === 'list',
//     };
//     if (!isEmpty(sortBy)) {
//       reqObj.sortBy = sortBy;
//     }
//     if (!isEmpty(sortColumn)) {
//       reqObj.sortColumn = sortColumn;
//     }
//     if (eventType.length > 0) {
//       reqObj.eventType = customListSearch(eventType, eventStatus);
//     }
//     if (locationValue.isFilterApplied) {
//       reqObj = { ...reqObj, ...getFilterData(locationValue) };
//     }
//     return reqObj;
//   };

//   const [defaultValue, setDefaultValue] = useState({
//     page: currentPage,
//     recordCountRequire: selectedView === 'list',
//     ...defaultDashboardFilter(),
//   });

//   const updateRecord = (params?: any, isMenuClick = false) => {
//     if (selectedCompanyId) {
//       dispatch(
//         actions.fetchFilterSecurityEvents({
//           ...defaultValue,
//           ...params,
//         }),
//       );
//     }
//   };

//   const refreshCount = () => {
//     dispatch(actions.fetchActiveSecurityEventsCount({}));
//     dispatch(actions.fetchSecurityEventCategory());
//   };

//   const onFilterMenuClick = (keyName: any) => {
//     const sortObj =
//       sortColumn && keyName === 'list' ? { sortColumn, sortBy } : {};
//     refreshCount();
//     updateRecord({ recordCountRequire: keyName === 'list', ...sortObj }, true);
//   };

//   const applyfilter = (value: any) => {
//     if (value) {
//       dispatch(actions.updateDashboardFilter(value));
//       const data: any = getFilterData(value.locationValue);
//       dispatch(actions.updateDashboardListPageNo(1));
//       updateRecord({ ...data, page: 1 });
//     }
//   };
//   const onFilterClear = () => {
//     if (selectedCompanyId) {
//       setDefaultValue({
//         page: 1,
//         recordCountRequire: selectedView === 'list',
//       });
//       dispatch(
//         actions.updateDashboardFilter({
//           eventType: [],
//           locationValue: {
//             address: '',
//             city: '',
//             siteName: '',
//             state: [],
//             zipcode: '',
//           },
//           ...SORT_OBJECT,
//         }),
//       );
//       dispatch(actions.updateDashboardListPageNo(1));
//       setCurrentPage(1);
//       refreshCount();
//       dispatch(
//         actions.fetchFilterSecurityEvents({
//           page: 1,
//           recordCountRequire: selectedView === 'list',
//         }),
//       );
//     }
//   };
//   // const handleChangeResolvedevents = () => {};
//   const onPagination = (pageNo: any) => {
//     setCurrentPage(pageNo);
//     dispatch(actions.updateDashboardListPageNo(pageNo));
//     const reqObj: any = { page: pageNo, recordCountRequire: true };
//     if (sortBy) {
//       reqObj.sortColumn = sortColumn;
//       reqObj.sortBy = sortBy;
//     }
//     refreshCount();
//     updateRecord(reqObj);
//   };
//   const onSortClick = (e: any, item: any): void => {
//     const sortObj: any = {};
//     if (item.sortColumn === sortColumn) {
//       sortObj.sortColumn = sortColumn;
//       const sort = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC;
//       sortObj.sortBy = sort;
//     } else {
//       sortObj.sortColumn = item.sortColumn;
//       sortObj.sortBy = SORT_BY.ASC;
//     }
//     dispatch(actions.updateDashboardFilter(sortObj));
//     dispatch(actions.updateDashboardListPageNo(1));
//     setCurrentPage(1);
//     updateRecord(sortObj);
//   };

//   const handleChangeEventType = (e: any): void => {
//     const selectedVal = e.target.value || '';
//     const getSelectedList = (list: any, value: any) => {
//       return value.map((val: any) => {
//         const obj = list.find((o: any) => o.key === val);
//         return obj.value;
//       });
//     };
//     let selectedValue = {};
//     let selectedType = {};
//     selectedValue = { eventType: selectedVal };
//     selectedType = {
//       eventType: getSelectedList(eventStatus, selectedVal),
//       page: 1,
//     };
//     if (selectedValue) {
//       dispatch(
//         actions.updateDashboardFilter({
//           ...selectedValue,
//           ...SORT_OBJECT,
//         }),
//       );
//       dispatch(actions.updateDashboardListPageNo(1));
//       setDefaultValue({ ...defaultValue, ...selectedType });
//       setCurrentPage(1);
//       updateRecord(selectedType);
//     }
//   };
//   const handleChangeEventIE = (item: any): void => {
//     setSelectedEventIE(item);
//     const newValue = item.map((o: any) => o.value);
//     const selectedType = { eventType: newValue };
//     if (item) {
//       dispatch(
//         actions.updateDashboardFilter({
//           eventType: item,
//           pageNo: 1,
//           ...SORT_OBJECT,
//         }),
//       );
//       setDefaultValue({ ...defaultValue, ...selectedType });
//       setCurrentPage(1);
//       updateRecord(selectedType);
//     }
//   };
//   const onSiteHandleClick = (e: any, siteId: any) => {
//     e.preventDefault();
//     persistSiteInfoId(siteId);
//     navigate(`sitemanager/${siteId}`);
//   };
//   const onDetailsClick = (e: any, item: any) => {
//     navigate(
//       `event-details/${item.AlarmNo}?locationCode=${item.siteDetails.LocationCode}`,
//     );
//   };
//   useEffect(() => {
//     const obj = {
//       page: currentPage,
//       recordCountRequire: selectedView === 'list',
//       ...defaultDashboardFilter(),
//     };
//     setDefaultValue(obj);
//   }, [selectedView]);

//   return (
//     <>
//       <Box sx={{ flexGrow: 1 }}>
//         <Grid container spacing={1}>
//           <Grid item container xs={12} sm={12} md={10} spacing={2}>
//             {isLocation && (
//               <Grid item xs={6} md={3}>
//                 <LocationFilter onfilter={applyfilter} />
//               </Grid>
//             )}
//             <Grid item xs={6} md={3}>
//               {!checkIEVersion() ? (
//                 <MultipleSelectCheckmarks
//                   value={eventType}
//                   handleChange={handleChangeEventType}
//                   options={eventStatus}
//                   placeholder="Event Category"
//                 />
//               ) : (
//                 <CustomMultiselectIE
//                   key={'switch_status'}
//                   value={selectedEventIE}
//                   handleChange={handleChangeEventIE}
//                   options={eventOptionIE}
//                   placeholder="Switch Status"
//                 />
//               )}
//             </Grid>
//             <Grid item xs={1}>
//               <IconsButton
//                 name={'ClearFilter'}
//                 children={<ClearIcon color={themes.palette.icon.main} />}
//                 style={{
//                   background: themes.palette.primary.main,
//                   borderRadius: 4,
//                   padding: '6px',
//                 }}
//                 OnIconClick={onFilterClear}
//                 title={CONTEXT.CLEAR_FILTER}
//                 isShowTooltip={true}
//               />
//             </Grid>
//           </Grid>
//           <Grid item container xs={12} md={2} justifyContent="flex-end">
//             {isMobile && (
//               <IconsButton
//                 name={'ClearFilter'}
//                 children={<ClearIcon color={themes.palette.icon.main} />}
//                 style={{
//                   background: themes.palette.primary.main,
//                   borderRadius: 4,
//                   padding: '0.35rem',
//                 }}
//                 OnIconClick={onFilterClear}
//                 title={CONTEXT.CLEAR_FILTER}
//                 isShowTooltip={true}
//               />
//             )}
//             <FilterMenu onhandleChange={onFilterMenuClick} />
//             {/* {isSiteManager && (
//               <IconsButton
//                 style={{
//                   background: isResolvedEvents ? COLORS.lightRed : COLORS.white,
//                   borderRadius: 4,
//                   marginLeft: '4px',
//                 }}
//                 name={'ResolvedEvents'}
//                 children={
//                   <RoundTick
//                     color={isResolvedEvents ? COLORS.white : COLORS.royalBlue}
//                   />
//                 }
//                 OnIconClick={handleChangeResolvedevents}
//                 title={'Resolved Events'}
//                 isShowTooltip={true}
//               />
//             )} */}
//           </Grid>
//           <Grid item container xs={2} justifyContent="flex-end"></Grid>
//         </Grid>
//         <PanelGrid container item pt={1} theme={themes}>
//           {selectedView === 'map' && (
//             <CustomMaps
//               markerResponseData={
//                 MapViewData && MapViewData.length > 0 ? MapViewData : []
//               }
//               type={CONTEXT.SECURITY_EVENTS}
//               loading={alarmEvents.loading}
//             />
//           )}
//           {selectedView === 'list' && (
//             <Listview
//               headerItems={SECURITY_EVENTS_HEADER}
//               name={CONTEXT.SECURITY_EVENTS}
//               listData={
//                 ListViewData && ListViewData.length > 0 ? ListViewData : []
//               }
//               totalRecords={totalListCount}
//               onPageChange={onPagination}
//               currentPage={currentPage}
//               loading={alarmEvents.loading}
//               CustomHeight={501}
//               isSort={isSort}
//               onSortClick={onSortClick}
//               onSiteHandle={onSiteHandleClick}
//               source={source}
//               onEventDetailsClick={onDetailsClick}
//             />
//           )}
//         </PanelGrid>
//       </Box>
//     </>
//   );
// }

// export default Events;
