import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { getFormatedDate } from 'utils/CommonFn';
import { DATE_FORMAT_1 } from 'utils/Constants';

function NetworkExportRowItem({
  key,
  rowItem,
  tableName,
  isAutoCells,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
      sx={{
        flexWrap: Object.keys(rowItem).length <= 12 ? 'nowrap' : 'wrap',
      }}
    >
      {isAutoCells ? (
        Object.keys(rowItem).length > 0 &&
        Object.keys(rowItem).map((key: any, value: any) => {
          const count = Object.keys(rowItem).length <= 12 ? 2 : 1;
          return (
            <TableCell item xs={count}>
              <OverflowTip
                text={
                  key === 'StatusChangeDate'
                    ? getFormatedDate(rowItem.StatusChangeDate, DATE_FORMAT_1)
                    : rowItem[key] || '-'
                }
              ></OverflowTip>
            </TableCell>
          );
        })
      ) : (
        <>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.CompanyNumber ? rowItem.CompanyNumber : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.Address ? rowItem.Address : ''}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={0.5}>
            <OverflowTip text={rowItem.City ? rowItem.City : '-'}></OverflowTip>
          </TableCell>
          <TableCell item xs={0.5}>
            <OverflowTip
              text={rowItem.State ? rowItem.State : '-'}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={0.5}>
            <OverflowTip text={rowItem.Zip ? rowItem.Zip : ''}></OverflowTip>
          </TableCell>

          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.CurrentStatus ? rowItem.CurrentStatus : ''}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={0.5}>
            <OverflowTip text={rowItem?.Chronic?.toString()}></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={getFormatedDate(rowItem.StatusChangeDate, DATE_FORMAT_1)}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={0.5}>
            <OverflowTip
              text={rowItem.CircuitState ? rowItem.CircuitState : ''}
            ></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            -
          </TableCell>
          <TableCell item xs={0.5}>
            <OverflowTip text={rowItem.Vpn ? rowItem.Vpn : ''}></OverflowTip>
          </TableCell>
          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.BackupVpn === null ? '-' : rowItem.BackupVpn}
            ></OverflowTip>
          </TableCell>

          <TableCell item xs={1}>
            <OverflowTip
              text={rowItem.Switch === null ? '-' : rowItem.Switch}
            ></OverflowTip>
          </TableCell>

          <TableCell item xs={1}>
            -
          </TableCell>
          <TableCell item xs={1}>
            -
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
export default NetworkExportRowItem;
