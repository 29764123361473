import { useTheme } from '@mui/material/styles';
export default function DownloadIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3992 22.2291L18.4071 28.237L24.415 22.2291"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.407 14.7194V28.2371"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.7443 23.8664C33.0501 22.9481 34.0293 21.6377 34.5399 20.1252C35.0504 18.6127 35.0657 16.9768 34.5836 15.455C34.1014 13.9333 33.1469 12.6047 31.8585 11.6622C30.5701 10.7196 29.0149 10.2122 27.4186 10.2134H25.5261C25.0744 8.45292 24.2291 6.81783 23.054 5.43126C21.8789 4.04469 20.4046 2.94276 18.742 2.20844C17.0794 1.47411 15.2719 1.12653 13.4555 1.19184C11.6392 1.25716 9.8613 1.73369 8.25575 2.58554C6.6502 3.43739 5.25882 4.64238 4.18635 6.10977C3.11389 7.57717 2.38829 9.26875 2.06418 11.0572C1.74006 12.8456 1.82589 14.6842 2.31518 16.4346C2.80448 18.1851 3.6845 19.8017 4.88899 21.1628"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
