import { Grid, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { ROW_WIDTH } from 'utils/Constants';

export const TableContainer = styled(Grid)(({ theme }) => ({
  // width: '100%',
  backgroundColor: theme.palette.customBackground.table,
  borderRadius: '0.5rem',
  display: 'flex',
  overflow: 'auto',
  padding: '0',
  '::-webkit-scrollbar': {
    height: '10px',
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.scroll,
    borderRadius: '0.625rem',
  },
  'scrollbar-color': theme.palette.customBackground.scroll,
  'scrollbar-width': 'auto',
}));
export const HeaderLabel = styled('div')(({ theme }) => ({
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  alignItems: 'flex-start',
}));
export const HeaderSort = styled('div')(({ theme }) => ({
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  position: 'absolute',
  right: '5px',
}));
export const SortIcon = styled('i')(({ theme }) => ({
  border: `solid ${theme.palette.text.primary}`,
  borderWidth: '0 2px 2px 0',
  display: 'inline-block',
  padding: '3px',
}));
export const TableHeader = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.icon.main}`,
}));

export const TableBody = styled(Grid)(({ theme }) => ({
  width: '100%',
  // height: "450px",
  overflowY: 'auto',
  padding: '4px',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.scroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.scroll,
  'scrollbar-width': 'auto',
}));

export const TableRow = styled<any>(Grid)(({ theme }) => ({
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.25rem',
  },
  minHeight: useMediaQuery(theme.breakpoints.down('md'))
    ? '3.5rem'
    : '2.813rem',
  padding: '0rem 0.5rem',
  borderBottom: `1px soild ${COLORS.solitude}`,
  background: theme.palette.customBackground.main,
  borderRadius: '0.5rem',
  margin: '4px auto',
  height: useMediaQuery(theme.breakpoints.down('md')) ? 'auto' : '9%',
  '.exploreDriverPill': {
    display: 'none',
  },
  '&:hover': {
    background:
      theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.titanWhite,
    '.exploreDriverPill': {
      display: 'flex',
    },
  },
}));

export const TableCell = styled(Grid)(function ({ theme, width }: any) {
  return {
    width:
      theme?.breakpoints && useMediaQuery(theme?.breakpoints?.down('md'))
        ? width ?? ROW_WIDTH
        : '100%',
    minHeight: 45,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: '0px 5px',
    '.name':
      theme?.breakpoints && useMediaQuery(theme?.breakpoints?.down('md'))
        ? { wordBreak: 'break-all' }
        : {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
    '.address': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '.link': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  };
});
export const GlobalLabel = styled<any>(Grid)(({ theme }) => ({
  padding: '0.313rem 1.25rem',
  borderRadius: '1rem',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.mode === 'dark' ? COLORS.jaguar : COLORS.white,
}));
