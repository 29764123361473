import { COLORS } from 'styles/colors';

export default function UploadIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <rect y="0.607361" width="24" height="24" rx="4" fill="#13388E" />
      <path
        d="M21 15.6074V19.6074C21 20.1378 20.7893 20.6465 20.4142 21.0216C20.0391 21.3966 19.5304 21.6074 19 21.6074H5C4.46957 21.6074 3.96086 21.3966 3.58579 21.0216C3.21071 20.6465 3 20.1378 3 19.6074V15.6074"
        stroke={COLORS.white}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 9.60736L12 4.60736L7 9.60736"
        stroke={COLORS.white}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 4.60736L12 16.6074"
        stroke={COLORS.white}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
