import { Avatar, CardHeader } from '@mui/material';
import styled from 'styled-components';
import mail from 'assets/icons/mail.svg';
import smartphone from 'assets/icons/smartphone.svg';
import { COLORS } from 'styles/colors';

interface Props {
  role: string;
  theme: any;
  title: string;
  email?: string;
  contact?: string;
}

export const CustomHeader = styled(CardHeader)(({ theme }) => ({
  color: COLORS.lightGrey,
  // maxWidth: 300,
  '& .MuiCardHeader-avatar': {
    marginRight: 8,
  },
}));
export const CustomAvtar = styled(Avatar)(({ theme }) => ({
  marginRight: 0,
}));

const getAvatar = (name: string): any => {
  if (name) {
    const avtarName = name.split(' ');
    return {
      sx: { fontSize: 14, color: COLORS.white, bgcolor: COLORS.gainsBoroWhite },
      children:
        avtarName.length > 1
          ? avtarName[0][0] + avtarName[1][0]
          : avtarName[0][0],
    };
  }
};
export function UserProfile({
  role,
  theme,
  title,
  email,
  contact,
}: Props): JSX.Element {
  return (
    <CustomHeader
      sx={{
        padding: '0.625rem 0.625rem 0px 0.625rem',
        '.MuiAvatar-root': {
          width: '100px',
          height: '100px',
          fontSize: '30px',
        },
        '.MuiCardHeader-title': {
          fontSize: '20px',
          color: COLORS.white,
          fontWeight: '600',
          marginBottom: '0.5rem',
        },
        '.MuiCardHeader-root': {
          //  padding: "16px 16px 16px 0px",
        },
        // "&.MuiCardHeader-title": { fontSize: "16px" },
      }}
      avatar={<Avatar {...getAvatar(title)} />}
      subheader={
        <div>
          <div>{role}</div>
          <div style={{ marginTop: '0.5rem', textDecoration: 'underline' }}>
            <img
              style={{ verticalAlign: 'middle', marginRight: '5px' }}
              src={mail}
              alt=""
            />{' '}
            {email}
          </div>

          <div style={{ marginTop: '6px' }}>
            <img
              style={{ verticalAlign: 'middle', marginRight: '5px' }}
              src={smartphone}
              alt=""
            />
            {contact}
          </div>
        </div>
      }
      subheaderTypographyProps={{ color: theme.palette.text.primary }}
      title={title}
    />
  );
}
export default UserProfile;
