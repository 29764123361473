export default function SearchWhiteIcon(): JSX.Element {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24386 2.55313C4.15108 2.55313 1.64388 5.05805 1.64388 8.14802C1.64388 11.238 4.15108 13.7429 7.24386 13.7429C10.3366 13.7429 12.8438 11.238 12.8438 8.14802C12.8438 5.05805 10.3366 2.55313 7.24386 2.55313ZM0.0438843 8.14802C0.0438843 4.1752 3.26742 0.95459 7.24386 0.95459C11.2203 0.95459 14.4438 4.1752 14.4438 8.14802C14.4438 12.1208 11.2203 15.3415 7.24386 15.3415C3.26742 15.3415 0.0438843 12.1208 0.0438843 8.14802Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1982 12.0984C11.5106 11.7862 12.0171 11.7862 12.3295 12.0984L15.8095 15.5752C16.122 15.8873 16.122 16.3934 15.8095 16.7055C15.4971 17.0177 14.9906 17.0177 14.6782 16.7055L11.1982 13.2287C10.8858 12.9166 10.8858 12.4105 11.1982 12.0984Z"
        fill="white"
      />
    </svg>
  );
}
