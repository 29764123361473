import { TextField, useMediaQuery } from '@mui/material';
import { IconsButton } from '../Buttons';
import { SearchIcon } from 'assets/component';
import { useTheme } from '@mui/material/styles';

interface Props {
  label: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  onChange?: any;
  value?: any;
  isDisable?: boolean;
}
export function CustomSearch({
  label,
  style,
  labelStyle,
  onChange,
  value,
  isDisable = false,
}: Props): JSX.Element {
  const theme = useTheme();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };
  return (
    <>
      <TextField
        disabled={isDisable}
        style={{ width: '100%' }}
        size="small"
        placeholder={label}
        id="outlined-start-adornment"
        sx={{
          backgroundColor: theme.palette.customBackground.main,
          border: 0,
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiInputBase-input': {
            paddingLeft: '5px',
            fontSize: useMediaQuery(theme.breakpoints.up('xl'))
              ? '0.875rem'
              : '0.75rem',
          },
          borderRadius: '0.5rem',
        }}
        InputProps={{
          startAdornment: <IconsButton children={<SearchIcon />} />,
        }}
        onChange={handleChange}
        value={value}
      />
    </>
  );
}
