export default function FireIcon({ color = '#ffffff' }: any): JSX.Element {
  return (
    <svg
      xmlns="
        http://www.w3.org/2000/svg"
      width="18"
      height="25"
      viewBox="0 0 18 25"
      fill="none"
    >
      <path
        d="M16.7732 10.2617C16.7732 11.7412 16.7732 13.2206 16.7732 14.7001C16.7732 15.8297 16.1831 16.7917 15.9796 17.9047C15.7836 18.9761 14.7644 20.24 13.6172 20.5951"
        stroke={color}
        stroke-linecap="round"
      />
      <path
        d="M4.07916 20.1209C1.75419 19.2653 -1.49793 15.5945 4.08201 8.55469C5.46177 14.1528 7.50729 8.3524 8.66376 3.44564L9.07576 1.05476C10.4038 0.269331 14.0574 8.09971 14.2478 13.8695L16.8145 10.0547C17.5149 13.0894 16.1388 14.4013 14.2455 14.8337C14.1122 18.1232 12.6222 20.4581 8.53726 19.5548L4.12685 20.1148C4.1083 20.1188 4.09237 20.1209 4.07916 20.1209Z"
        fill={color}
      />
      <path
        d="M4.07916 20.1209C1.75419 19.2653 -1.49793 15.5945 4.08201 8.55469C5.6725 15.0078 8.14763 6.31474 9.12589 1.30377L11.7683 16.0548V17.5547M4.07916 20.1209L8.53726 19.5548M4.07916 20.1209C4.76298 20.1209 12.753 14.6214 11.7683 17.5547M8.53726 19.5548L6.92174 13.5548L9.07576 1.05476C11.2737 -0.245147 19.8417 22.0548 8.53726 19.5548ZM11.7683 17.5547L16.8145 10.0547C18.1069 15.6547 12.3286 15.388 9.27786 14.5547"
        stroke={color}
      />
      <ellipse cx="9.45313" cy="17.0547" rx="7.53907" ry="7" fill={color} />
    </svg>
  );
}
