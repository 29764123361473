import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { UserProfilePermissionGroupListProps } from './type';

function UserProfilePermissionGroupRowItem({
  key,
  rowItem,
  tableName,
}: UserProfilePermissionGroupListProps): any {
  const theme = useTheme();

  return (
    <TableRow container key={key} alignItems="center" theme={theme}>
      <TableCell item xs={4}>
        <span>-</span>
      </TableCell>
      <TableCell item xs={4}>
        <span> -</span>
      </TableCell>
    </TableRow>
  );
}
export default UserProfilePermissionGroupRowItem;
