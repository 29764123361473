import {
  BillingIcon,
  ExternalLinkIcon,
  HelpfulDownloadsIcon,
  I3InternationalIcon,
  IPConfigureIcon,
  IdeaIcon,
  OnlineManualIcon,
  SalesIcon,
  ServiceRequestIcon,
  WobotIcon,
} from 'assets/component';
import PhosphorMediaIcon from 'assets/component/PhosphorMediaIcon';
import { MenuProps } from '../../app/pages/Partner/businessIntelligence/type';
import SpeedOfService from 'assets/component/MenuIcons/SpeedOfServiceIcon';
import VisualInsightsIcon from 'assets/component/MenuIcons/VisualInsightsIcon';
import ItemConversionsIcon from 'assets/component/MenuIcons/ItemConversionsIcon';
import QueueIcon from 'assets/component/MenuIcons/QueueIcon';
import BoschAccessControlIcon from 'assets/component/MenuIcons/BoschAccessControlIcon';
import SangomaIcon from 'assets/component/MenuIcons/SangomaIcon';
import FortivoiceIcon from 'assets/component/MenuIcons/FortivoiceIcon';

import FortinetIcon from 'assets/component/MenuIcons/FortinetIcon';
import FirewallIcon from 'assets/component/MenuIcons/FirewallIcon';
import NetsurionIcon from 'assets/component/MenuIcons/NetsurionIcon';
import CradlepointIcon from 'assets/component/MenuIcons/CradlepointIcon';
import ISSsecurityManagerIcon from 'assets/component/MenuIcons/ISSsecurityManagerIcon';
import HoneywellProWatchIcon from 'assets/component/MenuIcons/HoneywellProWatchIcon';
import DmpVirtualKeypadIcon from 'assets/component/MenuIcons/DmpVirtualKeypadIcon';
import BoschIntrusionDashboardIcon from 'assets/component/MenuIcons/BoschIntrusionDashboardIcon';
import OpeneyeWebServicesIcon from 'assets/component/MenuIcons/OpeneyeWebServicesIcon';
import MarchNetworkssearchlightIcon from 'assets/component/MenuIcons/MarchNetworkssearchlightIcon';
import OpenServiceRequestIcon from 'assets/component/MenuIcons/OpenServiceRequestIcon';
import OnlineManualsIcon from 'assets/component/MenuIcons/OnlineManualsIcon';
import SubmitanIdeaIcon from 'assets/component/MenuIcons/SubmitanIdeaIcon';
import NewReleasesIcon from 'assets/component/MenuIcons/NewReleasesIcon';
import TelephoneIcon from 'assets/component/MenuIcons/TelephoneIcon';
import CustomCareIcon from 'assets/component/MenuIcons/CustomCareIcon';
import ServiceAndSupportIcon from 'assets/component/MenuIcons/ServiceAndSupportIcon';
import HikvisionCentralIcon from 'assets/component/MenuIcons/HikvisionCentralIcon';
import HanwhaManagementSoftwareIcon from 'assets/component/MenuIcons/HanwhaManagementSoftwareIcon';
import ElearningIcon from 'assets/component/MenuIcons/ElearningIcon';
import AcumeraIcon from 'assets/component/MenuIcons/AcumeraIcon';

export const BUSINESS_INTELLIGENCE: MenuProps[] = [
  {
    title: 'Loading...',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Loading...',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Loading...',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Loading...',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
];

export const MANAGED_NETWORK: MenuProps[] = [
  {
    title: 'Firewall',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Fortinet',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Sangoma',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Cradlepoint',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Netsurion',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Fortivoice',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
];

export const BUSINESS_SECURITY: MenuProps[] = [
  {
    title: 'Bosch Access Control',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'ISS Security Manager',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Honeywell Pro Watch',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'DMP Virtual Keypad',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Bosch Intrusion Dashboard',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
];

export const VIDEO_VERIFIED_ALARM: MenuProps[] = [
  {
    title: 'March Networks Searchlight',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Openeye Web Services',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Hikvision Central',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
  {
    title: 'Hanwha Management Software',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: <ExternalLinkIcon />,
  },
];

export const HELP_SUPPORT: MenuProps[] = [
  {
    title: 'Open a Service Request',
    icon: <ServiceRequestIcon />,
    url: '',
    imageUrl: '',
  },
  {
    title: 'Online Manuals',
    icon: <OnlineManualIcon />,
    url: '',
    imageUrl: '',
  },
  {
    title: 'Submit an Idea',
    icon: <IdeaIcon />,
    url: '',
    imageUrl: '',
  },
  {
    title: 'E-learning',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: '',
  },
  {
    title: 'Helpful Downloads',
    icon: <HelpfulDownloadsIcon />,
    url: '',
    imageUrl: '',
  },
  {
    title: 'New Releases',
    icon: <ExternalLinkIcon />,
    url: '',
    imageUrl: '',
  },
];

export const HELP_SUPPORT_DATA: MenuProps[] = [
  {
    title: 'Security Alarm Monitoring',
    icon: <PhosphorMediaIcon />,
    url: '',
    imageUrl: '',
    'Contact Information': ' ',
  },
  {
    title: 'Interactive Alarm Monitoring',
    icon: <PhosphorMediaIcon />,
    url: '',
    imageUrl: '',
    'Contact Information': ' ',
  },
  {
    title: 'Sales',
    icon: <SalesIcon />,
    url: '',
    imageUrl: '',
    'Contact Information': ' ',
  },
  {
    title: 'Customer Care',
    icon: <SalesIcon />,
    url: '',
    imageUrl: '',
    'Contact Information': ' ',
  },
  {
    title: 'Service and Support',
    icon: <SalesIcon />,
    url: '',
    imageUrl: '',
    'Contact Information': ' ',
  },
  {
    title: 'Billing',
    icon: <SalesIcon />,
    url: '',
    imageUrl: '',
    'Contact Information': ' ',
  },
];

export const HELP_SUPPORT_CENTER: MenuProps[] = [
  {
    title: 'Secure Operations Center',
    icon: <PhosphorMediaIcon />,
    url: '',
    imageUrl: '',
  },
  {
    title: 'Digital Witness Central Command Center',
    icon: <PhosphorMediaIcon />,
    url: '',
    imageUrl: '',
  },
];
export const MENUICONS: any = {
  speedofservice: <SpeedOfService />,
  visualinsights: <VisualInsightsIcon />,
  queue: <QueueIcon />,
  itemconversions: <ItemConversionsIcon />,
  cradlepoint: <CradlepointIcon />,
  firewall: <FirewallIcon />,
  fortinet: <FortinetIcon />,
  fortivoice: <FortivoiceIcon />,
  sangoma: <SangomaIcon />,
  netsurion: <NetsurionIcon />,
  boschaccesscontrol: <BoschAccessControlIcon />,
  isssecuritymanager: <ISSsecurityManagerIcon />,
  honeywellprowatch: <HoneywellProWatchIcon />,
  dmpvirtualkeypad: <DmpVirtualKeypadIcon />,
  boschintrusiondashboard: <BoschIntrusionDashboardIcon />,
  openeyewebservices: <OpeneyeWebServicesIcon />,
  hikvisioncentral: <HikvisionCentralIcon />,
  hanwhamanagementsoftware: <HanwhaManagementSoftwareIcon />,
  marchnetworkssearchlight: <MarchNetworkssearchlightIcon />,
  openservicerequest: <OpenServiceRequestIcon />,
  onlinemanuals: <OnlineManualsIcon />,
  submitanidea: <SubmitanIdeaIcon />,
  'e-learning': <ElearningIcon />,
  helpfuldownloads: <HelpfulDownloadsIcon />,
  newreleases: <NewReleasesIcon />,
  securityalarmmonitoring: <TelephoneIcon />,
  interactivealarmmonitoring: <TelephoneIcon />,
  sales: <SalesIcon />,
  customercare: <CustomCareIcon />,
  serviceandsupport: <ServiceAndSupportIcon />,
  billing: <BillingIcon />,
  ipconfigure: <IPConfigureIcon />,
  wobot: <WobotIcon />,
  i3international: <I3InternationalIcon />,
  acumera: <AcumeraIcon />,
  default: <ExternalLinkIcon />,
};
