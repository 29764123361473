import { useTheme } from '@mui/material/styles';
export default function UpArrowIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.20898 11.9697L9.95898 7.21973L14.709 11.9697"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
