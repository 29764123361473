import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { SiteDetailsCSListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function SiteDetailsCSRowItem({
  key,
  rowItem,
  tableName,
}: SiteDetailsCSListProps): any {
  const theme = useTheme();

  return (
    <TableRow container key={key} alignItems="center" theme={theme}>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.CompanyNumber || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.HostName || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.NavisionStatus || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={rowItem?.TempClosed?.toString() || '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={rowItem?.PermClosed?.toString() || '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem?.IsActive?.toString() || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem?.IsHidden?.toString() || '-'}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default SiteDetailsCSRowItem;
