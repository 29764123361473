import { useTheme } from '@mui/material/styles';
export default function CheckboxIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.04297 11.0947L12.043 14.0947L22.043 4.09473"
        stroke={theme.palette.icon.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.043 12.0947V19.0947C21.043 19.6252 20.8323 20.1339 20.4572 20.5089C20.0821 20.884 19.5734 21.0947 19.043 21.0947H5.04297C4.51254 21.0947 4.00383 20.884 3.62876 20.5089C3.25368 20.1339 3.04297 19.6252 3.04297 19.0947V5.09473C3.04297 4.56429 3.25368 4.05559 3.62876 3.68051C4.00383 3.30544 4.51254 3.09473 5.04297 3.09473H16.043"
        stroke={theme.palette.icon.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
