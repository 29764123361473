import { useTheme } from '@mui/material/styles';

export default function Cancel(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L7.08 7L0.999999 1"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13L6.92 7L13 1"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
