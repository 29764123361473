export default function MapIcon({ color = '#434874' }: any): JSX.Element {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_923_330)">
        <path
          d="M0.625 3.75V13.75L5 11.25L10 13.75L14.375 11.25V1.25L10 3.75L5 1.25L0.625 3.75Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 1.25V11.25"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 3.75V13.75"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_923_330">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
