export default function DownloadArrowIcon(): JSX.Element {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1001 15.6984V19.6984C21.1001 20.2288 20.8894 20.7375 20.5143 21.1126C20.1392 21.4877 19.6305 21.6984 19.1001 21.6984H5.1001C4.56966 21.6984 4.06096 21.4877 3.68588 21.1126C3.31081 20.7375 3.1001 20.2288 3.1001 19.6984V15.6984"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.1001 10.6984L12.1001 15.6984L17.1001 10.6984"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1001 15.6984V3.69836"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
