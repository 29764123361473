import { TextField, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from 'styles/colors';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '0.541rem',
  font: '0.875rem Inter,sans-serif',
  letterSpacing: 0.15,
  marginBottom: '0.5em',
  input: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.neutral.contrastText,
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: COLORS.royalBlue,
  },
  '.MuiInputBase-root': {
    borderRadius: '0.5rem',
  },
  '.MuiInputBase-input': {
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '0.625rem',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.customBackground.scroll,
      borderRadius: '0.625rem',
    },
    'scrollbar-color': theme.palette.customBackground.scroll,
    'scrollbar-width': 'auto',
  },
}));

export const ErrorLabel = styled('div')(({ theme }) => ({
  width: '100%',
  fontSize: '0.875rem',
  textDecorationLine: 'underline',
  color: COLORS.lightRed,
}));

export const StyledTextFieldDashboard = styled(TextField)(
  ({ theme, type }) => ({
    borderRadius: '0.541rem',
    font: '0.875rem Inter,sans-serif',
    letterSpacing: 0.15,
    input: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0.5rem',
      padding: '8.5px',
      fontSize: useMediaQuery(theme.breakpoints.up('xl'))
        ? '0.875rem'
        : '0.75rem',
    },
    'input::-ms-clear': {
      display: 'none',
      height: 0,
      width: 0,
    },
    '.MuiInputBase-root': {
      borderRadius: '0.5rem',
      fontSize: '0.75rem',
      paddingRight: '1px',
    },

    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: theme.palette.mode === 'dark' ? 'transparent' : COLORS.grey,
    },
    'input::-webkit-inner-spin-button': {
      '-webkit-appearance': type === 'number' ? 'none' : '',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': type === 'number' ? 'textfield' : '',
    },
  }),
);
