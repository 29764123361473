import { call, all, takeLatest, put, select } from '@redux-saga/core/effects';
import { actions } from './slice';
import {
  getNetworkCount,
  getNetworkStatusCount,
  getServiceCount,
  getFilters,
  postFilterNetwork,
  postFilterSite,
  postFilterServiceRequest,
  postFilterServiceTrip,
  getLocation,
  getSiteManagerInfo,
  getSiteItemDetail,
  getServiceCountInfo,
  getNetworkCountInfo,
  getTripsItemDetail,
  getVideoUrlDetails,
  postFilterSystem,
  getCaseNotesDetail,
  postSRItemCaseNote,
  postSRItemComment,
  getSRItemComments,
  postSRItemEscalate,
  postSRItemInternalNote,
  getSRItemInternalNote,
  // getEventStatusFilters,
  // getSecurityEventCount,
  // postFilterSecurityEvents,
  // getSecurityEventDetails,
  // getSecurityEventZones,
  // getSecurityEventCurrentActions,
  getSRDownloadedFile,
  getNotesAndLogs,
} from './api';
import { ResponseProps } from './types';
import { getViewType } from './Selectors';
import { fetchMasterCompanyId } from '../authService';
import { actions as adminActions } from '../AdminSlice/slice';
import { ERROR_TEXT, ITEM_DETAILS } from 'utils/Constants/Content';
// import { DataMaptoArray } from '../utils';

function* fetchNetworkStatusCount(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      response = yield call(getNetworkStatusCount, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateNetworkStatusCount({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNetworkStatusCount({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchServiceCount(request: any): any {
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      const { payload }: any = request;
      const [
        networkCount,
        serviceCount,
        // securityCount
      ] = yield all([
        call(getNetworkCount, payload),
        call(getServiceCount, payload),
        // call(getSecurityEventCount, { ...payload, onlyCount: true }),
      ]);
      // serviceCount
      if (serviceCount && Array.isArray(serviceCount.data)) {
        yield put(
          actions.updateServiceCount({
            loading: false,
            serviceRequestCount: serviceCount.data[0].serviceRequestCount || 0,
          }),
        );
      } else {
        yield put(
          actions.updateServiceCount({
            loading: false,
            serviceRequestCount: 0,
          }),
        );
      }
      // NetworkCount
      if (networkCount && Array.isArray(networkCount.data)) {
        yield put(
          actions.updateServiceCount({
            loading: false,
            networkOutageCount: networkCount.data[0].networkOutageCount || 0,
          }),
        );
      } else {
        yield put(
          actions.updateServiceCount({
            loading: false,
            networkOutageCount: 0,
          }),
        );
      }
      // if (securityCount?.data?.activeEventCount) {
      //   yield put(
      //     actions.updateServiceCount({
      //       loading: false,
      //       securityEventsCount: securityCount?.data?.activeEventCount || 0,
      //     }),
      //   );
      // } else {
      //   yield put(
      //     actions.updateServiceCount({
      //       loading: false,
      //       securityEventsCount: 0,
      //     }),
      //   );
      // }
    }
  } catch (e) {
    yield put(
      actions.updateServiceCount({
        loading: false,
        networkOutageCount: 0,
        serviceRequestCount: 0,
      }),
    );
  }
}
function* fetchServiceInfo(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      response = yield call(getServiceCountInfo, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateServiceInfo({
            loading: false,
            data: response.data,
            recordCount: response.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateServiceInfo({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchNetowrkInfo(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      response = yield call(getNetworkCountInfo, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateNetworkServiceInfo({
            loading: false,
            data: response.data,
            recordCount: response.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNetworkServiceInfo({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}

function* fetchDropdownData(): any {
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      const response: any = yield call(getFilters);
      if (response) {
        yield put(
          actions.updateFilterData({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateFilterData({
            loading: false,
            data: [],
          }),
        );
      }
    }
  } catch (e) {
    yield put(
      actions.updateFilterData({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchGetLocation(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getLocation, payload);
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateLocationData({
              loading: false,
              locationList: response.data,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Payload ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateLocationData({
        loading: false,
        locationList: [],
      }),
    );
  }
}
function* fetchPostFilterSite(request: any) {
  let response: ResponseProps;
  const selectedviewType: Object = yield select(getViewType);
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(postFilterSite, {
          ...payload,
          view: selectedviewType,
          limit: 10,
        });
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateFilterSiteData({
              loading: false,
              mapData: (selectedviewType === 'map' && response.data) || [],
              listData: (selectedviewType === 'list' && response.data) || [],
              recordCount: response.recordCount,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Payload ===');
      }
    } else {
      yield put(
        actions.updateFilterSiteData({
          loading: false,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateFilterSiteData({
        loading: false,
        listData: [],
        mapData: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchPostFilterNetwork(request: any) {
  let response: ResponseProps;
  const selectedviewType: Object = yield select(getViewType);

  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(postFilterNetwork, {
          ...payload,
          view: selectedviewType,
          limit: 10,
        });
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateFilterNetworkData({
              loading: false,
              mapData: (selectedviewType === 'map' && response.data) || [],
              listData: (selectedviewType === 'list' && response.data) || [],
              recordCount: response.recordCount,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Payload ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateFilterNetworkData({
        loading: false,
        listData: [],
        mapData: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchPostServiceRequest(request: any) {
  let response: ResponseProps;
  const selectedviewType: Object = yield select(getViewType);
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(postFilterServiceRequest, {
          ...payload,
          view: selectedviewType,
          limit: 10,
        });
        if (response && Array.isArray(response.data)) {
          const tempData = response.data.map((item: any) => {
            return {
              ...item.Site,
              CaseNumber: item.CaseNumber,
              Status: item.Status,
              SubStatus: item.SubStatus,
              Subject: item.Subject,
              Priority: item.Priority,
              CreatedOn: item.CreatedOn,
              ResolvedOn: item.ResolvedOn,
            };
          });
          // const tempData = response.data.map((item: any) => item.Site);
          yield put(
            actions.updateFilterServiceRequest({
              loading: false,
              mapData: (selectedviewType === 'map' && tempData) || [],
              listData: (selectedviewType === 'list' && response.data) || [],
              recordCount: response.recordCount,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Payload ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateFilterServiceRequest({
        loading: false,
        listData: [],
        mapData: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchPostServiceTrip(request: any) {
  let response: ResponseProps;
  const selectedviewType: Object = yield select(getViewType);
  const { payload }: any = request;

  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(postFilterServiceTrip, {
          ...payload,
          view: selectedviewType,
          limit: 10,
        });
        if (response && Array.isArray(response.data)) {
          const tempData = response.data.map((item: any) => {
            return {
              ...item.Site,
              WorkOrderNumber: item.WorkOrderNumber,
              Status: item.Status,
            };
          });
          yield put(
            actions.updateFilterServiceTrip({
              loading: false,
              mapData: (selectedviewType === 'map' && tempData) || [],
              listData: (selectedviewType === 'list' && response.data) || [],
              recordCount: response.recordCount,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Payload ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateFilterServiceTrip({
        loading: false,
        listData: [],
        mapData: [],
        recordCount: 0,
      }),
    );
  }
}

function* fetchSiteInfo(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getSiteManagerInfo, payload);
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateSiteManagerInfo({
              loading: false,
              siteManagerInfo: response,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing SiteID ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSiteManagerInfo({
        loading: false,
        siteManagerInfo: [],
      }),
    );
  }
}

function* fetchSiteItemDetail(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getSiteItemDetail, payload);
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateSiteItemDetail({
              loading: false,
              siteItemDetail: response,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Case number ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSiteItemDetail({
        loading: false,
        siteItemDetail: [],
      }),
    );
  }
}
function* fetchCaseNotesDetail(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getCaseNotesDetail, payload);
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateCaseNotesDetail({
              loading: false,
              data: response.data[0],
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Case number ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCaseNotesDetail({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchCaseNotesAndLogs(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getNotesAndLogs, payload);
        if (response && Array.isArray(response.data?.data)) {
          yield put(
            actions.updateNotesAndLogs({
              loading: false,
              data: response.data,
            }),
          );
        } else {
          yield put(
            actions.updateNotesAndLogs({
              loading: false,
              data: [],
            }),
          );
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Case number ===');
      }
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    console.log(error);
    yield put(
      actions.updateNotesAndLogs({
        loading: false,
        data: [],
      }),
    );
  }
}
function exportUtils(response: any, fileName: any) {
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(new Blob([response.data]));
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
  }
}
function* fetchSRDownload(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getSRDownloadedFile, payload);
      if (response.data) {
        const contentDisposition = response.headers['content-disposition'];
        const filename = contentDisposition
          ? contentDisposition
              .split(';')
              .find(part => part.trim().startsWith('filename='))
              ?.split('=')[1]
              ?.replace(/"/g, '')
          : 'unknown';
        exportUtils(response, filename);
        yield put(
          actions.updateSRDownload({
            loading: false,
            data: [],
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: 'Download Successfull',
            toastType: 'success',
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSRDownload({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchTripsItemDetail(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getTripsItemDetail, payload);
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateTripsItemDetail({
              loading: false,
              tripsItemDetail: response,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing work order number ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateTripsItemDetail({
        loading: false,
        tripsItemDetail: [],
      }),
    );
  }
}

function* fetchVideoViewerInfo(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(getVideoUrlDetails, payload);
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateVideoViewerInfo({
              loading: false,
              data: response.data[0],
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing device number ===');
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateVideoViewerInfo({
        loading: false,
        videoViewerInfo: [],
      }),
    );
  }
}

function* fetchPostFilterSystem(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    const companyID = fetchMasterCompanyId();
    if (companyID) {
      if (payload) {
        response = yield call(postFilterSystem, {
          ...payload,
          limit: 10,
        });
        if (response && Array.isArray(response.data)) {
          yield put(
            actions.updateFilterSystemData({
              loading: false,
              listData: response.data || [],
              recordCount: response.recordCount,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
        }
      } else {
        console.log('=== Missing Payload ===');
      }
    } else {
      yield put(
        actions.updateFilterSystemData({
          loading: false,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateFilterSystemData({
        loading: false,
        listData: [],
        mapData: [],
        recordCount: 0,
      }),
    );
  }
}

/* Service request Add comment/Internal note/Case note & Escalate request API integrated */
function* addSRItemCaseNote(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;
      response = yield call(postSRItemCaseNote, payload);
      if (
        response &&
        response.data &&
        response.data[0] &&
        response.data[0].ObjectId
      ) {
        yield put(
          adminActions.displayToast({
            toastMessage: ITEM_DETAILS.ADD_CASE_NOTE_MESSAGE,
            toastType: 'success',
          }),
        );
        yield actions.fetchCaseNotesDetail({ srNumber: payload.srNumber });
        callback();
      } else {
        yield put(
          adminActions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
      yield put(
        actions.updateAddSRItemCaseNote({
          loading: false,
          data: [],
        }),
      );
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateAddSRItemCaseNote({
        loading: false,
        data: [],
      }),
    );
  }
}
function* addSRItemComment(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      const callback = payload.success;
      delete payload.success;
      response = yield call(postSRItemComment, payload);
      if (response?.data && response?.data[0] && response?.data[0]?.MessageId) {
        callback(response);
      } else {
        yield put(
          adminActions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
      yield put(
        actions.updateAddSRItemComment({
          loading: false,
          data: [],
        }),
      );
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateAddSRItemComment({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSRItemComment(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSRItemComments, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.updateFetchSRItemComment({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        yield put(
          actions.updateFetchSRItemComment({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateFetchSRItemComment({
        loading: false,
        data: [],
      }),
    );
  }
}
function* srItemEscalate(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(postSRItemEscalate, payload);
      if (
        response &&
        response.data &&
        response.data[0] &&
        response.data[0].ServiceRequests
      ) {
        yield put(
          actions.updateSRItemEscalate({
            loading: false,
            data: [],
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: ITEM_DETAILS.ESCALATE_SUCCESS_MESSAGE,
            toastType: 'success',
          }),
        );
      } else {
        yield put(
          actions.updateSRItemEscalate({
            loading: false,
            data: [],
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSRItemEscalate({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSRItemInternalNotes(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSRItemInternalNote, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.updateSRItemInternalNote({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        yield put(
          actions.updateSRItemInternalNote({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSRItemInternalNote({
        loading: false,
        data: [],
      }),
    );
  }
}
function* addSRItemInternalNote(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;
      response = yield call(postSRItemInternalNote, payload);
      if (
        response &&
        response.data &&
        response.data[0] &&
        response.data[0].ObjectId
      ) {
        yield put(
          actions.updateddSRItemInternalNote({
            loading: false,
            data: [],
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: ITEM_DETAILS.ADD_INTERNAL_NOTE_MESSAGE,
            toastType: 'success',
          }),
        );
        callback();
      } else {
        yield put(
          actions.updateddSRItemInternalNote({
            loading: false,
            data: [],
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateddSRItemInternalNote({
        loading: false,
        data: [],
      }),
    );
  }
}
// function* fetchPostSecurityEvents(request: any) {
//   let response: ResponseProps;
//   const selectedviewType: Object = yield select(getViewType);
//   const { payload }: any = request;

//   try {
//     const companyID = fetchMasterCompanyId();
//     if (companyID) {
//       if (payload) {
//         response = yield call(postFilterSecurityEvents, {
//           ...payload,
//           mode: selectedviewType,
//           limit: 10,
//         });
//         if (response && response.data && Array.isArray(response.data.events)) {
//           const tempData = response.data.events.map((item: any) => {
//             return {
//               ...item.siteDetails,
//               EventName: item.EventName,
//               EventCategory: item.EventCategory,
//               AlarmTime: item.AlarmTime,
//             };
//           });
//           yield put(
//             actions.updateFilterSecurityEvents({
//               loading: false,
//               mapData: (selectedviewType === 'map' && tempData) || [],
//               listData:
//                 (selectedviewType === 'list' && response.data.events) || [],
//               recordCount: response.data.count,
//             }),
//           );
//         } else {
//           console.log('=== API Failed to respond ===');
//         }
//       } else {
//         console.log('=== Missing Payload ===');
//       }
//     }
//   } catch (error) {
//     console.log(error);
//     yield put(
//       actions.updateFilterSecurityEvents({
//         loading: false,
//         listData: [],
//         mapData: [],
//         recordCount: 0,
//       }),
//     );
//   }
// }

// function* fetchActiveSecurityEventCounts(request: any) {
//   let response: ResponseProps;
//   try {
//     const companyID = fetchMasterCompanyId();
//     if (companyID) {
//       response = yield call(getSecurityEventCount, { onlyCount: true });
//       if (response?.data) {
//         yield put(
//           actions.updateServiceCount({
//             loading: false,
//             securityEventsCount: response.data?.activeEventCount || 0,
//           }),
//         );
//       } else {
//         yield put(
//           actions.updateServiceCount({
//             loading: false,
//             securityEventsCount: 0,
//           }),
//         );
//       }
//     }
//   } catch (error) {
//     yield put(
//       actions.updateServiceCount({
//         loading: false,
//         securityEventsCount: 0,
//       }),
//     );
//   }
// }

// function* fetchSecurityEventCategory(request: any) {
//   let eventStatusFilter: ResponseProps;
//   try {
//     const companyID = fetchMasterCompanyId();
//     if (companyID) {
//       eventStatusFilter = yield call(getEventStatusFilters);
//       if (eventStatusFilter && Array.isArray(eventStatusFilter.data)) {
//         yield put(
//           actions.updateEventStatus({
//             loading: false,
//             events: DataMaptoArray(eventStatusFilter.data[0]),
//           }),
//         );
//       } else {
//         yield put(
//           actions.updateEventStatus({
//             loading: false,
//             eventsType: {
//               loading: false,
//               data: [],
//             },
//           }),
//         );
//       }
//     }
//   } catch (error: any) {
//     // Error
//   }
// }

// function* fetchSecurityEventsDetails(request: any) {
//   let response: ResponseProps;
//   const { payload } = request;
//   try {
//     response = yield call(getSecurityEventDetails, payload);
//     if (response?.data) {
//       yield put(
//         actions.updateSecurityEventDetails({
//           loading: false,
//           data: response?.data,
//         }),
//       );
//     } else {
//       yield put(
//         actions.updateSecurityEventDetails({
//           loading: false,
//           data: [],
//         }),
//       );
//     }
//   } catch (error) {
//     yield put(
//       actions.updateSecurityEventDetails({
//         loading: false,
//         data: [],
//       }),
//     );
//   }
// }
// function* fetchSecurityEventsZones(request: any) {
//   let response: ResponseProps;
//   const { payload } = request;
//   try {
//     response = yield call(getSecurityEventZones, payload);
//     if (response?.data) {
//       yield put(
//         actions.updateSecurityEventZones({
//           loading: false,
//           data: response?.data,
//         }),
//       );
//     } else {
//       yield put(
//         actions.updateSecurityEventZones({
//           loading: false,
//           data: [],
//         }),
//       );
//     }
//   } catch (error) {
//     yield put(
//       actions.updateSecurityEventZones({
//         loading: false,
//         data: [],
//       }),
//     );
//   }
// }
// function* fetchSecurityEventsActionDetails(request: any) {
//   let response: ResponseProps;
//   const { payload } = request;
//   try {
//     response = yield call(getSecurityEventCurrentActions, payload);
//     if (response?.data) {
//       yield put(
//         actions.updateSecurityEventActionDetails({
//           loading: false,
//           data: response?.data,
//         }),
//       );
//     } else {
//       yield put(
//         actions.updateSecurityEventActionDetails({
//           loading: false,
//           data: [],
//         }),
//       );
//     }
//   } catch (error) {
//     yield put(
//       actions.updateSecurityEventActionDetails({
//         loading: false,
//         data: [],
//       }),
//     );
//   }
// }
export function* dashboardSaga() {
  yield takeLatest(actions.fetchNetworkStatusCount, fetchNetworkStatusCount);
  yield takeLatest(actions.fetchFillterData, fetchDropdownData);
  yield takeLatest(actions.fetchServiceCount, fetchServiceCount);
  yield takeLatest(actions.fetchFilterSiteData, fetchPostFilterSite);
  yield takeLatest(actions.fetchFilterNetworkData, fetchPostFilterNetwork);
  yield takeLatest(actions.fetchFilterServiceRequest, fetchPostServiceRequest);
  yield takeLatest(actions.fetchFilterServiceTrip, fetchPostServiceTrip);
  yield takeLatest(actions.fetchLocationData, fetchGetLocation);
  yield takeLatest(actions.fetchSiteManagerInfo, fetchSiteInfo);
  yield takeLatest(actions.fetchSiteItemDetail, fetchSiteItemDetail);
  yield takeLatest(actions.fetchCaseNotesDetail, fetchCaseNotesDetail);
  yield takeLatest(actions.fetchCaseNotesAndLogs, fetchCaseNotesAndLogs);
  yield takeLatest(actions.fetchServiceCountInfo, fetchServiceInfo);
  yield takeLatest(actions.fetchNetworkCountInfo, fetchNetowrkInfo);
  yield takeLatest(actions.fetchTripsItemDetail, fetchTripsItemDetail);
  yield takeLatest(actions.fetchVideoViewerInfo, fetchVideoViewerInfo);
  yield takeLatest(actions.fetchFilterSystemData, fetchPostFilterSystem);
  yield takeLatest(actions.addSRItemCaseNote, addSRItemCaseNote);
  yield takeLatest(actions.addSRItemComment, addSRItemComment);
  yield takeLatest(actions.fetchSRItemComment, fetchSRItemComment);
  yield takeLatest(actions.srItemEscalate, srItemEscalate);
  yield takeLatest(actions.fetchSRItemInternalNote, fetchSRItemInternalNotes);
  yield takeLatest(actions.addSRItemInternalNote, addSRItemInternalNote);
  // yield takeLatest(actions.fetchFilterSecurityEvents, fetchPostSecurityEvents);
  // yield takeLatest(
  //   actions.fetchActiveSecurityEventsCount,
  //   fetchActiveSecurityEventCounts,
  // );
  // yield takeLatest(
  //   actions.fetchSecurityEventDetails,
  //   fetchSecurityEventsDetails,
  // );
  // yield takeLatest(
  //   actions.fetchSecurityEventCategory,
  //   fetchSecurityEventCategory,
  // );
  // yield takeLatest(actions.fetchSecurityEventZones, fetchSecurityEventsZones);
  // yield takeLatest(
  //   actions.fetchSecurityEventActionDetails,
  //   fetchSecurityEventsActionDetails,
  // );
  yield takeLatest(actions.fetchSRDownload, fetchSRDownload);
}
