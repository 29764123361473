/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react';
import { EventType } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GlobalStyle } from 'styles/global-styles';
import GlobalSlice from './features/GlobalSlice';
import CssBaseline from '@mui/material/CssBaseline';
import getDesignTokens from '../styles/customTheme';
import { saveOnLocalStorage, getFromLocalStorage } from 'store/localStore';
import DashboardSlice from './features/DashboardSlice';
import AdminSlice from './features/AdminSlice';
import ToastAlert from './component/core/ToastAlert';
import { globalConfig } from 'appconfig';
import SecurityManagerSlice from './features/SecurityManagerSlice';
import Router from 'app/pages/Router/index';
import { loadDynamicScript } from 'utils/CommonFn';
import { getUserPermissions, updateStorage } from './features/authService';
import { isEmpty } from 'utils/CommonFn/validators';
import { USER_PERMISSIONS } from './features/Constant';
import { STORE_KEY } from 'store/Constant';

export function App({ pca }: any): JSX.Element {
  const selectedMode: any =
    getFromLocalStorage(STORE_KEY.MODE) != null
      ? getFromLocalStorage(STORE_KEY.MODE)
      : 'dark';
  const [mode, setMode] = useState<any>(selectedMode);
  const theme = createTheme(getDesignTokens(mode));
  const config = globalConfig.getDetails();
  const { instance } = useMsal();
  const [status, setStatus] = useState('');
  const isHasPermission = getUserPermissions(USER_PERMISSIONS.LIVE_CHAT);

  const onChange = (value: any): void => {
    setMode(value);
    saveOnLocalStorage(STORE_KEY.MODE, value);
  };
  useEffect(() => {
    if (selectedMode != null) {
      setMode(selectedMode);
    }
  }, [mode, selectedMode]);

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      (event: {
        eventType: EventType;
        payload: {
          account: null;
          idTokenClaims: {
            tfp: string;
            oid: string | undefined;
            sub: string | undefined;
          };
        };
      }) => {
        if (
          (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
          event.payload.account !== null
        ) {
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth
           * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
           * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
           * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            event.payload.idTokenClaims.tfp ===
            config.REACT_APP_AZURE_ACTIVE_DIRECTORY_EDIT_POLICY
          ) {
            // retrieve the account from initial sing-in to the app
            const originalSignInAccount = instance
              .getAllAccounts()
              .find(
                account =>
                  account &&
                  account.idTokenClaims &&
                  account.idTokenClaims.oid ===
                    event.payload.idTokenClaims.oid &&
                  account.idTokenClaims.sub ===
                    event.payload.idTokenClaims.sub &&
                  account.idTokenClaims.tfp ===
                    config.REACT_APP_AZURE_ACTIVE_DIRECTORY_SIGN_IN_POLICY,
              );

            const signUpSignInFlowRequest = {
              authority:
                config.REACT_APP_AZURE_ACTIVE_DIRECTORY_SIGN_IN_AUTHORITY,
              account: originalSignInAccount,
            };

            // silently login again with the signUpSignIn policy
            instance.ssoSilent(signUpSignInFlowRequest);
          }
        }

        if (
          event.eventType === EventType.SSO_SILENT_SUCCESS &&
          event.payload.account
        ) {
          setStatus('ssoSilent success');
        }
        console.log(status);
      },
    );

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isHasPermission) {
      loadDynamicScript('/le-mtagconfig.js');
      loadDynamicScript(`${process.env.PUBLIC_URL}/Livechat.js`);
    }
  }, [isHasPermission]);

  pca?.handleRedirectPromise().then((response: any): any => {
    if (!isEmpty(response)) {
      const token = response?.idToken ?? '';
      if (!isEmpty(token)) updateStorage(STORE_KEY.AD_TOKEN, token);
    }
  });

  const props = { mode, theme, onChange };
  return (
    <>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={pca}>
          <CssBaseline />
          <Router {...props} />
          <GlobalStyle />
          <GlobalSlice />
          <DashboardSlice />
          <AdminSlice />
          <SecurityManagerSlice />
          <ToastAlert />
        </MsalProvider>
      </ThemeProvider>
    </>
  );
}
