import { useTheme } from '@mui/material/styles';
export default function DownArrowIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47775 1L7.47775 7L13.4777 1"
        stroke={theme.palette.icon.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
