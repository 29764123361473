import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { actions } from 'app/features/AdminSlice/slice';
import { selectToastInfo } from 'app/features/AdminSlice/Selectors';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledAlert = styled(Alert)`
  background-color: ${props => getBackgroundColor(props.severity)};
  color: ${props => getColor(props.severity)};
  max-width: 28em;
`;

const getBackgroundColor = (type: any): string => {
  switch (type) {
    case 'success':
      return '#367A5C';
    case 'info':
      return '#EDF6FE';
    case 'warning':
      return '#FFD500';
    default:
      return '#FF0000';
  }
};

const getColor = (type: any): string => {
  switch (type) {
    case 'success':
      return '#fff';
    case 'info':
      return '#000';
    case 'warning':
      return '#000';
    default:
      return '#fff';
  }
};

function ToastAlert(): JSX.Element {
  const dispatch = useDispatch();

  const toastInfo = useSelector(selectToastInfo);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(actions.closeToast());
  };
  return (
    <Snackbar
      open={toastInfo.showToast}
      autoHideDuration={6000}
      TransitionComponent={Fade}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <StyledAlert
        onClose={handleClose}
        severity={toastInfo.toastType}
        icon={false}
      >
        {toastInfo.toastMessage}
      </StyledAlert>
    </Snackbar>
  );
}

export default ToastAlert;
