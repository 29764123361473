import { useTheme } from '@mui/material/styles';
export default function LeftArrowIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9278_10591)">
        <path
          d="M13 3.5L7 9.5L13 15.5"
          stroke={theme.palette.icon.main}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9278_10591">
          <rect
            width="19"
            height="19"
            stroke={theme.palette.icon.main}
            transform="matrix(0 1 -1 0 19.5 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
