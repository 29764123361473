import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import CustomMaps from 'app/component/core/Maps';
import {
  DEFAULT_PAGE,
  MAP_LEGEND,
  NETWORK_HEADER,
  SORT_BY,
} from 'utils/Constants/index';
import { FilterMenu } from 'app/component/core/Filter';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDashboardFilterData,
  getViewType,
  getFilterNetworkData,
  getSiteManager,
  getDashboardFilter,
  getCompanyId,
  getDashboardListPageNo,
} from 'app/features/DashboardSlice/Selectors';
import { Listview } from 'app/component/core/CustomListView';
import { actions } from 'app/features/DashboardSlice/slice';
import { PanelGrid } from '../../styles';
import { IconsButton } from 'app/component/core/Buttons';
import { ClearIcon } from 'assets/component';
import { MultipleSelectCheckmarks } from 'app/component/core/CustomSelectWithoutLable/multiselectCheckbox';
import {
  checkIEVersion,
  customListSearch,
  getFilterData,
  manageDropdownDataForIE,
  navigateToExternalUrl,
  useMobileDevice,
} from 'utils/CommonFn';
import { CustomMultiselectIE } from 'app/component/core/CustomMultiselectIE';
import { saveOnLocalStorage } from 'store/localStore';
import { useNavigate } from 'react-router-dom';
import { LocationFilter } from 'app/component/common/LocationFilter';
import { CONTEXT } from 'utils/Constants/Content';
import { persistSiteInfoId } from 'app/features/authService';
import { isEmpty } from 'utils/CommonFn/validators';
import { STORE_KEY } from 'store/Constant';

function Network({
  isLocation = true,
  isSort = false,
  source = CONTEXT.DASHBOARD,
}: any): JSX.Element {
  const themes = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getFilters = useSelector(getDashboardFilterData);
  const selectorServiceNetwork: any = getFilters?.data?.netowork?.[0] ?? [];
  const selectorNetworkData: any = useSelector(getFilterNetworkData);
  const isSiteManager: any = useSelector(getSiteManager);
  const viewType = useSelector(getViewType);
  const selectedCompanyId = useSelector(getCompanyId);
  const selectedView = isSiteManager ? 'list' : viewType;
  const isMobile = useMobileDevice();
  const MapViewData = selectorNetworkData.mapData || [];
  const ListViewData = selectorNetworkData.listData || [];
  const TotalListCount = selectorNetworkData.recordCount || 0;
  const networkStatus = selectorServiceNetwork?.network_status || [];
  const switchStatus = selectorServiceNetwork?.switch_status || [];
  const dashboardPageNo = useSelector(getDashboardListPageNo);
  const [currentPage, setCurrentPage] = useState(dashboardPageNo);
  const dashboardFilter: any = useSelector(getDashboardFilter);
  const {
    locationValue,
    networkStatusValue,
    switchStatusValue,
    sortColumn,
    sortBy,
  } = dashboardFilter;
  const countLabels = () => {
    const primary = MapViewData.filter((o: any) =>
      o.CurrentStatus.includes(MAP_LEGEND.PRIMARY),
    );
    const down = MapViewData.filter((o: any) =>
      o.CurrentStatus.includes(MAP_LEGEND.DOWN),
    );
    const backup = MapViewData.filter((o: any) =>
      o.CurrentStatus.includes(MAP_LEGEND.BACKUP),
    );
    const unknown = MapViewData.filter((o: any) =>
      o.CurrentStatus.includes(MAP_LEGEND.UNKNOWN),
    );
    const obj = {
      up: primary.length,
      down: down.length,
      warning: backup.length,
      unknown: unknown.length,
    };
    return obj;
  };
  const labelCount = countLabels();
  const sortObject = { sortColumn: '', sortBy: '' };

  const defaultDashboardFilter = (): any => {
    let reqObj: any = {
      page: dashboardPageNo,
      recordCountRequire: selectedView === 'list',
    };
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (networkStatusValue.length > 0) {
      reqObj.networkStatus = customListSearch(
        networkStatusValue,
        networkStatus,
      );
    }
    if (switchStatusValue.length > 0) {
      reqObj.switchStatus = customListSearch(switchStatusValue, switchStatus);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };

  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    recordCountRequire: selectedView === 'list',
    ...defaultDashboardFilter(),
  });

  const switchOptionIE: any = manageDropdownDataForIE(switchStatus);
  const networkOptionIE: any = manageDropdownDataForIE(networkStatus);
  const [selectedSwitchIE, setSelectedSwitchIE] = useState(
    switchStatusValue ?? [],
  );
  const [selectedNetworkIE, setSelectedNetworkIE] = useState(
    networkStatusValue ?? [],
  );

  const handleChangeNetworkStatus = (e: any): void => {
    const selectedVal = e.target.value || '';
    const newValue = selectedVal.map((val: any) => {
      const obj = networkStatus.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { networkStatus: newValue, ...DEFAULT_PAGE };
    if (selectedVal) {
      setDefaultValue({ ...defaultValue, ...selectedType });
      dispatch(
        actions.updateDashboardFilter({
          networkStatusValue: selectedVal,
          ...sortObject,
        }),
      );
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };
  const handleChangeSwitchStatus = (e: any): void => {
    const selectedVal = e.target.value || '';
    const newValue = selectedVal.map((val: any) => {
      const obj = switchStatus.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { switchStatus: newValue, ...DEFAULT_PAGE };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          switchStatusValue: selectedVal,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };
  const onSiteHandleClick = (e: any, siteId: any) => {
    e.preventDefault();
    persistSiteInfoId(siteId);
    navigate(`/dashboard/sitemanager/${siteId}`);
  };

  const onPagination = (pageNo: any) => {
    setCurrentPage(pageNo);
    dispatch(actions.updateDashboardListPageNo(pageNo));
    const reqObj: any = { page: pageNo, recordCountRequire: true };
    if (sortBy) {
      reqObj.sortColumn = sortColumn;
      reqObj.sortBy = sortBy;
    }
    updateRecord(reqObj);
  };

  const onFilterMenuClick = (keyName: any) => {
    const sortObj =
      sortColumn && keyName === 'list' ? { sortColumn, sortBy } : {};
    updateRecord({ recordCountRequire: keyName === 'list', ...sortObj }, true);
  };

  const updateRecord = (params?: any, isMenuClick = false) => {
    if (selectedCompanyId) {
      dispatch(
        actions.fetchFilterNetworkData({
          ...defaultValue,
          ...params,
        }),
      );
    }
  };
  const onFilterClear = () => {
    if (selectedCompanyId) {
      setDefaultValue({
        page: 1,
        recordCountRequire: selectedView === 'list',
      });
      dispatch(
        actions.updateDashboardFilter({
          StatusValue: [],
          networkStatusValue: [],
          switchStatusValue: [],
          locationValue: {
            address: '',
            city: '',
            siteName: '',
            state: [],
            zipcode: '',
          },
          ...sortObject,
        }),
      );
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(
        actions.fetchFilterNetworkData({
          page: 1,
          recordCountRequire: selectedView === 'list',
        }),
      );
      if (checkIEVersion()) {
        setSelectedNetworkIE([]);
        setSelectedSwitchIE([]);
      }
    }
  };
  const handleChangeNetworkIE = (item: any): void => {
    setSelectedNetworkIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { networkStatus: newValue };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({
          networkStatusValue: item,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeSwitchIE = (item: any): void => {
    setSelectedSwitchIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { switchStatus: newValue };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({
          switchStatusValue: item,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const onSortClick = (e: any, item: any): void => {
    const sortObj: any = {};
    if (item.sortColumn === sortColumn) {
      sortObj.sortColumn = sortColumn;
      const sort = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC;
      sortObj.sortBy = sort;
    } else {
      sortObj.sortColumn = item.sortColumn;
      sortObj.sortBy = SORT_BY.ASC;
    }
    dispatch(actions.updateDashboardFilter(sortObj));
    updateRecord(sortObj);
  };
  useEffect(() => {
    const obj = {
      page: currentPage,
      recordCountRequire: selectedView === 'list',
      ...defaultDashboardFilter(),
    };
    setDefaultValue(obj);
  }, [selectedView]);

  const applyfilter = (value: any) => {
    if (value) {
      dispatch(actions.updateDashboardFilter(value));
      const data: any = getFilterData(value.locationValue);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord({ ...data, page: 1 });
    }
  };

  const onRedirectFullScreen = () => {
    saveOnLocalStorage(
      STORE_KEY.NETWORK_PROJECTOR_FILTER,
      JSON.stringify(defaultValue),
    );
    navigateToExternalUrl('/projectorView?type=network');
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item container xs={12} md={10} spacing={2}>
            {isLocation && (
              <Grid item xs={6} md={3}>
                <LocationFilter onfilter={applyfilter} />
              </Grid>
            )}
            {!checkIEVersion() ? (
              <>
                <Grid item xs={6} md={3}>
                  <MultipleSelectCheckmarks
                    value={networkStatusValue}
                    handleChange={handleChangeNetworkStatus}
                    options={networkStatus}
                    placeholder="Network Status"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <MultipleSelectCheckmarks
                    value={switchStatusValue}
                    handleChange={handleChangeSwitchStatus}
                    options={switchStatus}
                    placeholder="Switch Status"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  <CustomMultiselectIE
                    key={'network_status'}
                    value={selectedNetworkIE}
                    handleChange={handleChangeNetworkIE}
                    options={networkOptionIE}
                    placeholder="Network Status"
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomMultiselectIE
                    key={'switch_status'}
                    value={selectedSwitchIE}
                    handleChange={handleChangeSwitchIE}
                    options={switchOptionIE}
                    placeholder="Switch Status"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={1} sx={{ marginTop: '1px' }}>
              {!isMobile && (
                <IconsButton
                  name={'ClearFilter'}
                  children={<ClearIcon color={themes.palette.icon.main} />}
                  style={{
                    background: themes.palette.primary.main,
                    borderRadius: 4,
                    padding: '6px',
                  }}
                  OnIconClick={onFilterClear}
                  title={CONTEXT.CLEAR_FILTER}
                  isShowTooltip={true}
                />
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} md={2} justifyContent="flex-end">
            {isMobile && (
              <IconsButton
                name={'ClearFilter'}
                children={<ClearIcon color={themes.palette.icon.main} />}
                style={{
                  background: themes.palette.primary.main,
                  borderRadius: 4,
                  padding: '0.35rem',
                }}
                OnIconClick={onFilterClear}
                title={CONTEXT.CLEAR_FILTER}
                isShowTooltip={true}
              />
            )}
            <FilterMenu onhandleChange={onFilterMenuClick} />
          </Grid>
        </Grid>
        <PanelGrid container item pt={1} theme={themes}>
          {selectedView === 'map' && !isSiteManager && (
            <CustomMaps
              markerResponseData={
                MapViewData && MapViewData.length > 0 ? MapViewData : []
              }
              isShowlabel={true}
              labelCount={labelCount}
              type={CONTEXT.NETWORK}
              loading={selectorNetworkData.loading}
              onFullScreenclick={onRedirectFullScreen}
            />
          )}
          {selectedView === 'list' && (
            <Listview
              headerItems={NETWORK_HEADER}
              name={CONTEXT.NETWORK}
              listData={
                ListViewData && ListViewData.length > 0 ? ListViewData : []
              }
              totalRecords={TotalListCount}
              onPageChange={onPagination}
              currentPage={currentPage}
              loading={selectorNetworkData.loading}
              onSiteHandle={onSiteHandleClick}
              CustomHeight={500}
              isSort={isSort}
              onSortClick={onSortClick}
              source={source}
            />
          )}
        </PanelGrid>
      </Box>
    </>
  );
}

export default React.memo(Network);
