import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { SiteDetailsCSListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { isEmpty } from 'utils/CommonFn/validators';

function SiteDetailsCheckListRowItem({
  key,
  rowItem,
  tableName,
}: SiteDetailsCSListProps): any {
  const theme = useTheme();

  return (
    <TableRow container key={key} alignItems="center" theme={theme}>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.serviceLevel || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={
            !isEmpty(rowItem?.siteInstallationStatus)
              ? rowItem?.siteInstallationStatus.toString()
              : '-'
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={
            !isEmpty(rowItem?.systemStatus)
              ? rowItem?.systemStatus.toString()
              : '-'
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.navisionStatus || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={
            !isEmpty(rowItem?.oveIsActive)
              ? rowItem?.oveIsActive.toString()
              : '-'
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={
            !isEmpty(rowItem?.isHidden) ? rowItem?.isHidden.toString() : '-'
          }
        ></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default SiteDetailsCheckListRowItem;
