import { CustomMultiSelect } from './styles';
import { useTheme } from '@mui/material';

export function CustomMultiselectIE({
  key,
  placeholder,
  handleChange,
  value,
  options,
  hasMultiselect = false,
}: any): JSX.Element {
  const theme = useTheme();

  return (
    <>
      <CustomMultiSelect
        key={key}
        theme={theme}
        options={options}
        value={value}
        onChange={handleChange}
        labelledBy=""
        overrideStrings={{ selectSomeItems: placeholder }}
        disableSearch
        hasSelectAll={hasMultiselect}
        ClearSelectedIcon={null}
        // isOpen={true}
      />
    </>
  );
}
