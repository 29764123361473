import { Grid, useTheme } from '@mui/material';
import { COLORS } from 'styles/colors';
import { Listview } from 'app/component/core/CustomListView';
import { Cancel } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import { useNavigate } from 'react-router-dom';
import { persistSiteInfoId } from 'app/features/authService';
import { ROUTE_PATH } from 'app/pages/Router/constant';

function QuickStatsModal({
  label,
  onCancelClick,
  ListViewData,
  TotalListCount,
  loading,
  currentPage,
  headers,
  onPagination,
}: any): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const onSiteHandleClick = (e: any, siteId: any): void => {
    e.preventDefault();
    persistSiteInfoId(siteId);
    navigate(`/dashboard/sitemanager/${siteId}`);
  };
  const onServiceRequestEditClick = (value: any): void => {
    navigate(
      { pathname: `/${ROUTE_PATH.DASHBOARD}/${ROUTE_PATH.CASE_DETAILS}` },
      { state: value },
    );
  };
  return (
    <>
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{
          backgroundColor:
            theme.palette.mode === 'dark'
              ? COLORS.gulfBlue
              : COLORS.deeplightGrey,
          width: '80%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: ' translate(-50%, -50%)',
          padding: '30px',
          borderRadius: '0.5rem',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            {label}
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
            <IconsButton
              children={<Cancel />}
              OnIconClick={(e: any) => {
                onCancelClick(e);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item mt={3}>
          <Listview
            headerItems={headers}
            name={label}
            listData={
              ListViewData && ListViewData.length > 0 ? ListViewData : []
            }
            totalRecords={TotalListCount}
            onPageChange={onPagination}
            currentPage={currentPage}
            loading={loading}
            CustomHeight={365}
            onSiteHandle={onSiteHandleClick}
            onServiceRequestEditHandle={onServiceRequestEditClick}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default QuickStatsModal;
