import { useTheme } from '@mui/material/styles';

export default function BillingIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2545 13.0151C12.4824 12.8672 11.7578 12.5341 11.1428 12.0443C10.5279 11.5546 10.0411 10.9228 9.7243 10.2033C9.40747 9.48388 9.27008 8.69825 9.32394 7.91396C9.3778 7.12968 9.6213 6.37021 10.0335 5.70082C10.4457 5.03142 11.0143 4.47214 11.6904 4.07103C12.3665 3.66992 13.1299 3.43898 13.915 3.39806C14.7 3.35714 15.4833 3.50747 16.1974 3.83612C16.9115 4.16478 17.5352 4.66192 18.0148 5.28483"
        stroke={theme.palette.icon.main}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M20.6919 17.8799C23.7294 17.8799 26.1919 15.4174 26.1919 12.3799C26.1919 9.34232 23.7294 6.87988 20.6919 6.87988C17.6543 6.87988 15.1919 9.34232 15.1919 12.3799C15.1919 15.4174 17.6543 17.8799 20.6919 17.8799Z"
        stroke={theme.palette.icon.main}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M3.91687 21.7257L9.92837 20.0083C12.2709 19.3388 14.7695 19.4664 17.0316 20.3713L18.9772 21.151C19.3817 21.3123 19.7169 21.6103 19.9244 21.9931C20.1319 22.376 20.1986 22.8195 20.113 23.2465C20.0634 23.4938 19.9642 23.7284 19.8213 23.9363C19.6785 24.1442 19.4949 24.3209 19.2819 24.4558C19.0688 24.5908 18.8305 24.6811 18.5816 24.7214C18.3326 24.7617 18.078 24.7511 17.8332 24.6902L12.9272 23.4651"
        stroke={theme.palette.icon.main}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.60437 29.2882L6.82775 28.5484C7.43081 28.347 8.06769 28.2665 8.7019 28.3116C9.33612 28.3567 9.95522 28.5264 10.5237 28.8111L13.4649 30.0389C14.1347 30.3194 14.858 30.4493 15.5836 30.4196C16.3092 30.3899 17.0194 30.2013 17.6641 29.8671L29.0835 23.9546C29.535 23.7218 29.8837 23.3292 30.0617 22.8534C30.2396 22.3775 30.234 21.8525 30.046 21.3806C29.8579 20.9111 29.5024 20.5282 29.0483 20.3057C28.5942 20.0832 28.0737 20.037 27.5875 20.1761L22.071 21.7518"
        stroke={theme.palette.icon.main}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0022 10.1758C21.6032 10.0684 21.1929 10.0084 20.7799 9.99703C19.5602 9.99703 19.1299 10.6268 19.1299 11.1658C19.1299 11.9908 19.9727 12.1957 20.7152 12.373C21.8317 12.648 22.3295 12.8859 22.3295 13.6188C22.3295 14.2004 21.8854 14.6198 21.1401 14.7435C20.4376 14.8268 19.7254 14.7298 19.0707 14.4617M20.7001 9.95441V9.14453M20.7001 15.6153V14.8068"
        stroke={theme.palette.icon.main}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
}
