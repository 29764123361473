import { useTheme } from '@mui/material/styles';

export default function InsightsAnalyticsIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15 16.6666V8.33325"
          stroke={theme.palette.icon.main}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 16.6666V3.33325"
          stroke={theme.palette.icon.main}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 16.6667V11.6667"
          stroke={theme.palette.icon.main}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
