export default function FilterIcon(): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4304_4902)">
        <path
          d="M18.2658 1.5H1.59912L8.26579 9.38333V14.8333L11.5991 16.5V9.38333L18.2658 1.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4304_4902">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.932129)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
