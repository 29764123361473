export const COLORS = {
  main: '#33323E',
  light: '#41926E',
  dark: '#F5F5F5',
  black: '#000000',
  white: '#ffffff',
  purple: '#8744E1',
  lightPink: '#FE718E',
  lightOrange: '#FBBA6D',
  lightGreen: '#1BD8BA',
  jungleGreen: '#2AA67B',
  selectedBtnBackground: '#17142A',
  btnHoverBackground: '#17142A',
  selectedBtnColor: '#17142A',
  btnHoverColor: '#17142A',
  darkGrey: '#17142A',
  selectedSubMenuBtnColor: '#17142A',
  btnFocusedColor: '#475467',
  backupColor: '#E8C300',
  primaryColor: '#2AA67B',
  downColor: '#E5000A',
  iconBgColor: '#4841EB',
  darkBlue: '#2A324B',
  jaguar: '#1B1A28',
  grey: '#656874',
  lightRed: '#F15F57',
  red: '#E5000A',
  lightTintRed: '#FE5F55',
  lightGrey: '#A5A5A5',
  tintGrey: '#CACBD4',
  dimGrey: '#656565',
  nightRiderGrey: '#313131',
  WhiteLilac: '#EEEEF0',
  grainsBoroWhite: '#DEDEDE',
  gainsBoroWhite: 'D9D9D9',
  shipCove: '#7380A8',
  royalBlue: '#205EED',
  solitude: '#D9DCE6',
  portage: '#809BD0',
  gulfBlue: '#33323E',
  luckyBlue: '#283350',
  titanWhite: '#e1e0e9',
  neonBlue: '#625bf7',
  cornFlowerBlue: '#434874',
  greyLight: '#d3d3d366', // d5d5d5
  midnightBlue: '#131E49',
  deeplightGrey: '#E8E8EA',
  haitiViolet: '#1C1B28',
  smokyViolet: '#3F3E48',
  painiViolet: '#605F69',
  eclipseGrey: '#3E3B3B',
  valhallaViolet: '#28253B',
  smokeWhite: '#F0F0F0',
  hawkesBlue: '#F2F1F9',
  ghostBlue: '#BBBBBF',
  primaryDarkBlue: '#191E2D',
  primaryDarkGray: '#282730',
  primaryTreeGroup: '#33323E',
  darkRed: '#E5000A',
  linkWaterBlue: '#CACCD5',
  mayaBlue: '#5CCFFE',
  whiteLilacBlue: '#F9F9FA',
  gulfBlueBlue: '#303A58',
  jacarta: '#3A3058',
  lightSlateBlue: '#7F7EFF',
  gridcolor: '#525557',
  whiteGridColor: '#d9dbdb',
  testLogColor: '#13388E',
  detailsLogColor: '#2AA67B',
};
