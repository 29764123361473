import { useTheme } from '@mui/material/styles';
export default function WobotIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="196"
      height="41"
      viewBox="0 0 196 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.5947 28.8901H27.5507C27.5507 28.8901 26.805 29.0684 26.0594 27.8865L7.67594 0.722905C7.67594 0.722905 7.27992 0.0984003 6.3945 0.0314958C5.50909 -0.0354086 4.92652 0.678363 4.78675 1.01289L0.289948 8.7069C0.289948 8.7069 -0.362435 9.46521 0.289948 10.6249L19.2793 39.9295C19.2793 39.9295 19.7452 40.933 20.6539 40.9776C21.5626 41.0223 33.5387 40.9776 33.5387 40.9776C33.5387 40.9776 34.4241 41.0445 35.0531 39.9964C35.6823 38.9483 40.1557 31.2541 40.1557 31.2541C40.1557 31.2541 40.9479 28.9346 38.5947 28.8901Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M67.37 28.8901H56.3259C56.3259 28.8901 55.5803 29.0684 54.8346 27.8865L36.4745 0.722905C36.4745 0.722905 36.0783 0.0984003 35.1931 0.0314958C34.3077 -0.0354086 33.7251 0.678363 33.5853 1.01289L29.0884 8.7069C29.0884 8.7069 28.4361 9.46521 29.0884 10.6249L48.0777 39.9295C48.0777 39.9295 48.5438 40.933 49.4525 40.9776C50.3612 41.0223 62.3373 40.9776 62.3373 40.9776C62.3373 40.9776 63.2225 41.0445 63.8517 39.9964C64.4808 38.9483 68.9543 31.2541 68.9543 31.2541C68.9543 31.2541 69.7232 28.9346 67.37 28.8901Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M61.4985 0.834427L57.1413 8.39482C57.1413 8.39482 56.2559 9.46529 56.8618 10.4243C57.4676 11.3832 67.7661 27.2399 67.7661 27.2399C67.7661 27.2399 68.2553 28.288 69.3738 28.2656C70.4689 28.2211 70.7484 27.6189 71.1212 26.9275C71.4941 26.2585 75.6414 19.055 75.6414 19.055C75.6414 19.055 76.2005 18.23 75.5482 17.2931C74.8958 16.3343 64.4343 0.834427 64.4343 0.834427C64.4343 0.834427 63.9682 -0.102234 62.8032 0.00921084C61.8479 0.120842 61.4985 0.834427 61.4985 0.834427Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M137.805 20.6605C131.933 20.6605 127.18 25.2102 127.18 30.8302C127.18 36.4503 131.933 40.9998 137.805 40.9998C143.676 40.9998 148.43 36.4503 148.43 30.8302C148.43 25.2102 143.653 20.6605 137.805 20.6605ZM137.805 39.5056C132.796 39.5056 128.741 35.6251 128.741 30.8302C128.741 26.0352 132.796 22.1548 137.805 22.1548C142.814 22.1548 146.869 26.0352 146.869 30.8302C146.869 35.6028 142.814 39.5056 137.805 39.5056Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M90.8793 20.6605C85.0077 20.6605 80.2545 25.2102 80.2545 30.8302C80.2545 36.4503 85.0077 40.9998 90.8793 40.9998C96.7508 40.9998 101.504 36.4503 101.504 30.8302C101.481 25.2102 96.7275 20.6605 90.8793 20.6605ZM90.8793 39.5056C85.8698 39.5056 81.8157 35.6251 81.8157 30.8302C81.8157 26.0352 85.8698 22.1548 90.8793 22.1548C95.8887 22.1548 99.9429 26.0352 99.9429 30.8302C99.9429 35.6028 95.8887 39.5056 90.8793 39.5056Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M114.902 20.6607C111.15 20.6607 107.865 22.5343 105.978 25.3442V14.8624C105.978 14.4163 105.605 14.0596 105.139 14.0596C104.673 14.0596 104.3 14.4163 104.3 14.8624V40.1749C104.3 40.621 104.673 40.9777 105.139 40.9777C105.605 40.9777 105.978 40.621 105.978 40.1749V36.2944C107.865 39.1044 111.15 40.9777 114.902 40.9777C120.773 40.9777 125.526 36.4282 125.526 30.8082C125.503 25.2104 120.75 20.6607 114.902 20.6607ZM114.902 39.5058C110.405 39.5058 106.7 36.3837 105.978 32.2801V29.3809C106.7 25.2773 110.428 22.155 114.902 22.155C119.911 22.155 123.965 26.0357 123.965 30.8304C123.965 35.603 119.911 39.5058 114.902 39.5058Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M189.576 30.808C189.576 25.1878 184.824 20.6383 178.952 20.6383C173.081 20.6383 168.328 25.1878 168.328 30.808C168.328 36.4279 173.081 40.9774 178.952 40.9774C182.796 40.9774 186.153 39.015 188.016 36.0711V40.2415C188.016 40.6429 188.365 40.9774 188.784 40.9774C189.204 40.9774 189.554 40.6429 189.554 40.2415V31.7892C189.554 31.7223 189.53 31.6554 189.507 31.5885C189.554 31.3432 189.576 31.0756 189.576 30.808ZM178.952 39.5055C173.942 39.5055 169.889 35.6251 169.889 30.8301C169.889 26.0354 173.942 22.1547 178.952 22.1547C183.961 22.1547 188.016 26.0354 188.016 30.8301C188.016 35.6027 183.961 39.5055 178.952 39.5055Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M158.612 20.8166H155.28V14.5721C155.28 14.1262 154.907 13.7693 154.441 13.7693C153.975 13.7693 153.602 14.1262 153.602 14.5721V20.839H150.27C149.874 20.839 149.548 21.1511 149.548 21.5302C149.548 21.9095 149.874 22.2216 150.27 22.2216H153.602V40.1969C153.602 40.6429 153.975 40.9998 154.441 40.9998C154.907 40.9998 155.28 40.6429 155.28 40.1969V22.1994H158.612C159.008 22.1994 159.334 21.8871 159.334 21.508C159.334 21.1288 159.008 20.8166 158.612 20.8166Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M195.402 40.9774H193.818C193.794 40.9774 193.771 40.9553 193.771 40.9329V20.9281C193.771 20.9059 193.794 20.8835 193.818 20.8835H195.402C195.424 20.8835 195.449 20.9059 195.449 20.9281V40.9329C195.449 40.9553 195.424 40.9774 195.402 40.9774Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M194.61 17.3598C195.203 17.3598 195.682 16.9006 195.682 16.334C195.682 15.7675 195.203 15.3081 194.61 15.3081C194.017 15.3081 193.538 15.7675 193.538 16.334C193.538 16.9006 194.017 17.3598 194.61 17.3598Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M163.831 41C164.423 41 164.903 40.5407 164.903 39.9741C164.903 39.4076 164.423 38.9484 163.831 38.9484C163.239 38.9484 162.759 39.4076 162.759 39.9741C162.759 40.5407 163.239 41 163.831 41Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
