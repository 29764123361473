export const DataMaptoArray = (data: any): any => {
  try {
    const newArr: any = {};
    Object.entries(data).map((key: any) => {
      return (newArr[`${key[0]}`] = ObjToArray(key[1]));
    });
    return newArr;
  } catch (error) {
    return [];
  }
};

export const ObjToArray = (data: any): any => {
  try {
    return Object.keys(data).map(key => ({ key, value: data[key] }));
  } catch (error) {
    return [];
  }
};

export const getMapData = (data: any): any => {
  try {
    data.map((item: any) => {
      if (item.Latitude === null) {
        item.Latitude = 0;
      }
      if (item.Longitude === null) {
        item.Longitude = 0;
      }
      return {
        ...item,
      };
    });
  } catch (error) {
    return data;
  }
};
