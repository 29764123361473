/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import CustomTooltip from '../Tooltip';
import { IconsButton } from '../Buttons';
import { COLORS } from 'styles/colors';
import InfoCircleIconSolidWhite from 'assets/component/InfoCircleSolidWhite';
import { DATE_FORMAT_1 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';

function SiteGroupBulkUploadedRowItem({ key, rowItem, onIconClick }: any): any {
  const theme = useTheme();
  return (
    <TableRow container key={key} alignContent="center" theme={theme}>
      <TableCell item xs={5}>
        <OverflowTip text={rowItem?.fileName || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={5}>
        <OverflowTip
          text={getFormatedDate(rowItem.updatedOn, DATE_FORMAT_1)}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <CustomTooltip title={'View'}>
          <div>
            <IconsButton
              name="info"
              children={<InfoCircleIconSolidWhite />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '0.313rem',
              }}
              OnIconClick={onIconClick}
            />
          </div>
        </CustomTooltip>
      </TableCell>
    </TableRow>
  );
}
export default SiteGroupBulkUploadedRowItem;
