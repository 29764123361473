import styled from 'styled-components/macro';
import { MultiSelect } from 'react-multi-select-component';
import { useMediaQuery } from '@mui/material';
import { COLORS } from 'styles/colors';

export const CustomMultiSelect = styled(MultiSelect)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '0.5rem',
  '&:hover': {
    border: `1px solid ${
      theme.palette.mode === 'dark' ? COLORS.linkWaterBlue : COLORS.haitiViolet
    }`,
  },
  position: 'relative',
  zIndex: 999,
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  '& .dropdown-container': {
    border: 'none',
    backgroundColor: 'transparent',
  },
  '& .dropdown-container:focus-within': {
    boxShadow: 'none',
    borderColor: 'transparent',
  },
  '& .dropdown-content': {
    background:
      theme.palette.mode === 'dark' ? COLORS.gulfBlue : COLORS.deeplightGrey,
    padding: '3px',
    borderRadius: '0.5rem',
  },
  '& .dropdown-content .panel-content': {
    background:
      theme.palette.mode === 'dark' ? COLORS.gulfBlue : COLORS.deeplightGrey,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0',
  },
  '& .dropdown-heading': {
    padding: '6px 6px 6px 15px',
  },
  '& .dropdown-content .panel-content .select-panel ul li .select-item .item-renderer span':
    {
      paddingLeft: '5px',
    },
  '& .dropdown-content .panel-content .select-panel ul li .select-item .item-renderer':
    {
      padding: '0.6rem 0.2rem',
      margin: '3px',
    },
  '& .dropdown-content .panel-content .select-panel ul li .select-item.selected':
    {
      background:
        theme.palette.mode === 'dark'
          ? COLORS.haitiViolet
          : COLORS.linkWaterBlue,
      borderRadius: '0.5rem',
    },
  '& .dropdown-content .panel-content .select-panel ul li .select-item:hover': {
    background:
      theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
    borderRadius: '0.5rem',
  },
  '& .dropdown-content .panel-content .select-panel ul li .select-item': {
    margin: '3px 0px',
  },
  '& .dropdown-content .panel-content .select-panel ul .select-item .item-renderer':
    {
      padding: '0.6rem 0.2rem',
      margin: '3px',
    },
  '& .dropdown-content .panel-content .select-panel ul .select-item:hover': {
    background:
      theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
    borderRadius: '0.5rem',
  },
  '& .dropdown-content .panel-content .select-panel ul .select-item.selected': {
    background:
      theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
    borderRadius: '0.5rem',
  },
  '-ms-overflow-style': 'scrollbar',
  'scrollbar-base-color':
    theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
  'scrollbar-face-color':
    theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
  'scrollbar-arrow-color':
    theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
  'scrollbar-shadow-color':
    theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
  'scrollbar-dark-shadow-color':
    theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.linkWaterBlue,
  'scrollbar-track-color': theme.palette.customBackground.scroll,
}));
