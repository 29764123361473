export default function ClearIcon({ color = '#434874' }: any): JSX.Element {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3633_5540)">
        <path
          d="M9 16.5476C13.1421 16.5476 16.5 13.1897 16.5 9.04761C16.5 4.90547 13.1421 1.54761 9 1.54761C4.85786 1.54761 1.5 4.90547 1.5 9.04761C1.5 13.1897 4.85786 16.5476 9 16.5476Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.25 6.79761L6.75 11.2976"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.75 6.79761L11.25 11.2976"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3633_5540">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.0476074)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
