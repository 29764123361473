export const envConfigUrl = '/init';

export interface config {
  REACT_APP_BASE_API: string;
  environment: 'DEV' | 'UAT' | 'PROD';
}

export const envConfig: config = {
  REACT_APP_BASE_API: '',
  environment: 'DEV',
};

class AppConfig {
  config: config = envConfig;
  isUrl = true;

  public getDetails(): any {
    if (this.isUrl) {
      console.log('error');
    } else {
      return this.config;
    }
  }

  public updateConfig(value: config): void {
    if (this.isUrl) {
      this.config = value;
      this.isUrl = false;
    } else {
      // console.log('update config again');
    }
  }
}

export const globalConfig = new AppConfig();
