import { Grid, Badge, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Props } from 'app/component/core/Charts/types';

export const CustomChartTitle = styled(Grid)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.833rem',
}));

export const CustomGraphanaTitle = styled(Grid)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.833rem',
  padding: '5px',
  textAlign: 'center',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.customBackground.primary,
  borderRadius: 4,
}));

export const CustomBadge = styled(Badge)<Props>(({ theme, badgecolor }) => ({
  '& .MuiBadge-badge': {
    color: badgecolor,
    backgroundColor: badgecolor,
  },
}));
export const CustomDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  // border: theme.palette.divider,
  borderColor: theme.palette.divider,
  margin: '20px 0px',
}));

export const MsgContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '0.625rem',
  textAlign: 'center',
}));

export const ChartPanel = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customBackground.primary,
  borderRadius: '8px',
}));
