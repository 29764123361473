import { Tabs, Tab, Divider, Grid, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

interface TabData {
  currentindex: number;
  tabslength: number;
}
export const CustomTabs = styled(Tabs)(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  '& .MuiTab-root': {
    textTransform: 'capitalize',
    fontSize: '0.83rem',
    color:
      theme.palette.mode === 'dark' ? COLORS.ghostBlue : COLORS.painiViolet,
    flex: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
  '& .MuiTabs-flexContainer': {
    overflow: useMediaQuery(theme.breakpoints.down('md')) ? 'none' : 'auto',
    width: '100%',
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: '700',
    borderBottom: `2px solid ${COLORS.lightRed}`,
  },
  '& .MuiTabs-indicator': {
    background: 'transparent',
  },
}));
export const CustomTab = styled(Tab)<TabData>(
  ({ theme, currentindex, tabslength }) => ({
    width: 'auto',
    // "&.MuiTab-root": {
    //   borderLeft:
    //     currentindex > 0 && currentindex <= tabslength
    //       ? `1px solid ${theme.palette.divider}`
    //       : "none",
    // },
  }),
);
export const CustomDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
}));

export const PanelGrid = styled(Grid)<any>(({ theme }) => ({
  // overflowY: "auto",
  //  height: `${window.innerHeight - 380}px`,
  // "::-webkit-scrollbar": {
  //   width: "5px",
  // },
  // "::-webkit-scrollbar-track": {
  //   borderRadius: "0.625rem",
  // },
  // "::-webkit-scrollbar-thumb": {
  //   background:
  //     theme.palette.mode === "dark" ? COLORS.portage : COLORS.solitude,
  //   borderRadius: "0.625rem",
  // },
}));
