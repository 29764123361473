import { Grid } from '@mui/material';
import { IconContainer } from './style';
import { IconButtonProps } from './type';
import CustomTooltip from '../Tooltip';
import { isImpersonateUser } from 'app/features/authService';

function IconsButton({
  name = 'btn-icon',
  OnIconClick = () => {},
  children,
  style = {},
  isDisabled = false,
  isShowTooltip = false,
  title = '',
  styleTooltip,
}: IconButtonProps): JSX.Element {
  const isImpersonate: any = isImpersonateUser(name);
  return isShowTooltip ? (
    <div
      style={{
        width: '36px',
        height: '36px',
        ...styleTooltip,
      }}
    >
      <CustomTooltip title={title}>
        <IconContainer
          onClick={_e => {
            OnIconClick(name, _e);
          }}
          style={style}
          disabled={isDisabled || isImpersonate}
          value={name}
          data-testid={name}
        >
          <Grid container alignItems="center" justifyContent={'center'}>
            {children}
          </Grid>
        </IconContainer>
      </CustomTooltip>
    </div>
  ) : (
    <IconContainer
      onClick={_e => {
        OnIconClick(name, _e);
      }}
      style={style}
      disabled={isDisabled || isImpersonate}
      value={name}
      data-testid={name}
    >
      <Grid container alignItems="center" justifyContent="center">
        {children}
      </Grid>
    </IconContainer>
  );
}

export default IconsButton;
