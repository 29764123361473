import { Grid, useTheme } from '@mui/material';
import { PrimaryButton } from 'app/component/core/Buttons';

export function AlertPopup({ onOkClick, message }: any): JSX.Element {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        item
        sx={{
          backgroundColor: theme.palette.neutral.light,
          width: '35%',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: ' translate(-50%, -50%)',
          padding: '30px',
        }}
      >
        <Grid container item xs={12}>
          {message}
        </Grid>
        <Grid container item xs={12} justifyContent="center" mt={3}>
          <PrimaryButton
            style={{ marginRight: '0.625rem' }}
            children={'Ok'}
            disabled={false}
            fullWidth={false}
            onClick={e => {
              onOkClick(e);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
