import { Grid } from '@mui/material';
import { PrimaryButton } from 'app/component/core/Buttons';
import { CustomSearch } from 'app/component/core/CustomSearch';
import { ADD_EXISTING_GROUP1, PERMISSIONS } from 'utils/Constants';
import { useState } from 'react';
import LabelWithIconButton from 'app/component/core/LabelWithIconButton';
import { PlusIcon } from 'assets/component';
import { GridContainer } from './style';
import { CustomCheckboxWithIcon } from 'app/component/core/CustomCheckbox';
import { COLORS } from 'styles/colors';

interface Props {
  theme: any;
  oncancel?: any;
}
export function Permissions({ theme, oncancel }: Props): JSX.Element {
  const [addExistingGroup] = useState(ADD_EXISTING_GROUP1);
  const [permissions] = useState(PERMISSIONS);
  return (
    <Grid container direction="column">
      <Grid container item direction="row">
        {/* <Grid item xs={12}> */}
        <CustomSearch label="Search Group" />
        <GridContainer container direction="row" theme={theme} mt={2}>
          <Grid item xs={12} md={7} lg={7} p={2}>
            {addExistingGroup.map((item: any) => {
              return (
                <LabelWithIconButton
                  iconName={<PlusIcon />}
                  label={item.label}
                  style={{
                    marginTop: '5px',
                    fontSize: '0.875rem',
                    fontWeight: '300',
                    padding: '15px',
                  }}
                />
              );
            })}
          </Grid>

          <Grid item xs={12} md={5} lg={5} p={2}>
            {permissions.map((item: any) => {
              return (
                <Grid
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? COLORS.gulfBlue
                        : COLORS.solitude,
                    borderRadius: '0.5rem',
                    padding: '0.5rem',
                    marginTop: '5px',
                  }}
                >
                  <CustomCheckboxWithIcon label={item.label} />
                  {/* label={item.label}
                    checkBoxStyle={{
                      color: "#FFFFFF",
                      "&.Mui-checked": {
                        color: "#FFFFFF",
                      },
                    }}
                  /> */}
                </Grid>
              );
            })}
          </Grid>
        </GridContainer>
        {/* </Grid> */}
      </Grid>

      <Grid container item xs={12} justifyContent="flex-end" spacing={2} mt={3}>
        <PrimaryButton
          style={{
            background: COLORS.white,
            marginRight: '0.625rem',
            color: COLORS.jaguar,
          }}
          children={'Cancel'}
          disabled={false}
          fullWidth={false}
          onClick={oncancel}
        />
        <PrimaryButton children={'Save'} disabled={true} fullWidth={false} />
      </Grid>
    </Grid>
  );
}

export default Permissions;
