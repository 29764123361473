import { useTheme } from '@mui/material/styles';

export default function HelpSupportIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
          stroke={theme.palette.icon.main}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3519 8.66285L15.4372 6.45402L16.6666 5.00002L15 3.33335L13.5539 4.56915L11.2981 3.64147L10.7794 1.66669H9.15077L8.62419 3.66763L6.4203 4.59666L4.99996 3.33335L3.33329 5.00002L4.54443 6.49073L3.64371 8.70529L1.66663 9.16669V10.8334L3.66755 11.3796L4.59642 13.5831L3.33329 15L4.99996 16.6667L6.4926 15.4503L8.66414 16.3436L9.16663 18.3334H10.8333L11.3371 16.3443L13.5459 15.4296C13.914 15.6928 15 16.6667 15 16.6667L16.6666 15L15.4299 13.5412L16.3449 11.3317L18.3332 10.8143L18.3333 9.16669L16.3519 8.66285Z"
          stroke={theme.palette.icon.main}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
