import { useTheme } from '@mui/material/styles';

export default function BIntelligenceIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.6 6.80005H4.4C3.6268 6.80005 3 7.42685 3 8.20005V15.2C3 15.9732 3.6268 16.6 4.4 16.6H15.6C16.3732 16.6 17 15.9732 17 15.2V8.20005C17 7.42685 16.3732 6.80005 15.6 6.80005Z"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.8002 16.6V5.4C12.8002 5.0287 12.6527 4.6726 12.3901 4.41005C12.1276 4.1475 11.7715 4 11.4002 4H8.6002C8.22889 4 7.8728 4.1475 7.61025 4.41005C7.3477 4.6726 7.2002 5.0287 7.2002 5.4V16.6"
          stroke={theme.palette.icon.main}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
