import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import CustomTooltip from '../Tooltip';
import { COLORS } from 'styles/colors';
import { Grid, Switch } from '@mui/material';
import { UserListProps } from './type';
import { CustomToggleLeft } from 'assets/component';
import { isEmpty } from 'utils/CommonFn/validators';
import {
  capitalizeFirstLetter,
  getFormatedDate,
  useMobileDevice,
} from 'utils/CommonFn';
import {
  getUserId,
  getUserPermissions,
  isImpersonateUser,
} from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import IconAndLabelButton from '../Buttons/IconAndLabelButton';
import Loader from 'app/component/layout/Loader/Loader';
import { useSelector } from 'react-redux';
import { getImpersonateUser } from 'app/features/AdminSlice/Selectors';
import { DISPLAY_TEXT } from 'utils/Constants/Content';

function UserListRowItem({
  key,
  rowItem,
  tableName,
  onEditIconClick,
  handleChange,
  onImpersonateClick,
  source = '',
}: UserListProps): any {
  const theme = useTheme();
  const isMobile = useMobileDevice();
  const isUserEditPermission = getUserPermissions(USER_PERMISSIONS.EDIT_USER);
  const isUserActivePermission = getUserPermissions(
    USER_PERMISSIONS.ACITVE_DEACTIVE,
  );
  const isUserImpersonatePermission: any = getUserPermissions(
    USER_PERMISSIONS.USER_IMPERSONATE,
  );
  const impersonateUser: any = useSelector(getImpersonateUser);
  const format = 'LTS MM-DD-YYYY ';
  const userId: any = getUserId();
  const handleClick = (): any => {};
  const getUserName = (): string => {
    try {
      const firstName = rowItem.FirstName || '';
      const lastName = rowItem.LastName || '';
      let name = '';
      if (!isEmpty(firstName)) {
        name = capitalizeFirstLetter(firstName);
      }
      if (!isEmpty(lastName)) {
        name = name
          ? name + ' ' + capitalizeFirstLetter(lastName)
          : capitalizeFirstLetter(lastName);
      }
      if (isEmpty(firstName) && isEmpty(lastName)) {
        name = '-';
      }
      return name;
    } catch (error) {
      return '-';
    }
  };
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={isUserImpersonatePermission ? 2 : 3}>
        <OverflowTip text={getUserName()}></OverflowTip>
      </TableCell>
      <TableCell item xs={3}>
        <OverflowTip text={rowItem.Email}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={getFormatedDate(rowItem.CreatedOn, format)}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={
            rowItem.UserCreatedBy?.Email ? rowItem.UserCreatedBy.Email : '-'
          }
        ></OverflowTip>
      </TableCell>
      {isUserImpersonatePermission && (
        <TableCell item xs={1.5}>
          {parseInt(userId) !== rowItem?.UserId && !isMobile && (
            <IconAndLabelButton
              style={{
                padding: '0 0.5rem',
                width: '100%',
                fontSize: '0.75rem',
              }}
              iconName={
                parseInt(source) === rowItem?.UserId &&
                impersonateUser.loading && <Loader size={15} />
              }
              isIcon={
                (parseInt(source) === rowItem?.UserId &&
                  impersonateUser.loading) ??
                false
              }
              children={DISPLAY_TEXT.IMPERSONATE}
              disabled={impersonateUser.loading}
              customHeight="30px"
              onClick={() => {
                onImpersonateClick(rowItem?.UserId);
              }}
            />
          )}
        </TableCell>
      )}
      <TableCell item xs={isUserImpersonatePermission ? 1.5 : 2}>
        <Grid container>
          <Grid item xs={6}>
            <CustomTooltip title={rowItem.IsActive ? 'Active' : 'Deactivated'}>
              <Switch
                checked={rowItem.IsActive}
                onChange={handleChange}
                icon={<CustomToggleLeft />}
                checkedIcon={<CustomToggleLeft />}
                disabled={isImpersonateUser()}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    padding: '15px !important',
                    opacity: !isUserActivePermission ? 0.6 : 1,
                  },
                  '& .MuiSwitch-track': {
                    border: `1px solid ${COLORS.white}`,
                    boxSizing: 'border-box',
                    borderRadius: '0.5rem',
                    backgroundColor: rowItem.IsActive
                      ? COLORS.primaryColor
                      : COLORS.darkRed,
                    opacity: !isUserActivePermission ? 0.6 : 1,
                  },
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    // color: "transparent",
                  },
                  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                    backgroundColor: rowItem.IsActive
                      ? COLORS.primaryColor
                      : COLORS.darkRed,
                    opacity: !isUserActivePermission ? 0.6 : 1,
                  },
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={6}>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                opacity: !isUserEditPermission ? 0.6 : 1,
              }}
              OnIconClick={onEditIconClick}
              isShowTooltip={true}
              title="Edit"
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
export default UserListRowItem;
