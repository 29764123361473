import { useTheme } from '@mui/material/styles';

export default function CustomCareIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.84058 21.9508L8.65374 21.1377C9.72142 20.07 11.2095 19.5114 12.7196 19.5114H16.7854L19.2249 20.3245H26.5433C27.1903 20.3245 27.8108 20.5815 28.2683 21.039C28.7258 21.4965 28.9828 22.117 28.9828 22.764C28.9828 23.411 28.7258 24.0315 28.2683 24.489C27.8108 24.9465 27.1903 25.2035 26.5433 25.2035H19.2249C18.7935 25.2035 18.3799 25.3748 18.0749 25.6798C17.7699 25.9848 17.5985 26.3985 17.5985 26.8298"
        stroke={theme.palette.icon.menu}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.1647 21.1374L32.2305 18.698L33.8568 17.0716C35.4832 15.4453 36.2963 15.4453 37.1095 15.4453C37.9227 15.4453 38.7358 16.2585 38.7358 16.2585L34.67 22.7638L25.7252 30.0822H14.3409L12.7146 31.7085"
        stroke={theme.palette.icon.menu}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M10.2773 37.4016L14.3431 33.3358L4.58517 23.5779L1.33252 26.8305"
        stroke={theme.palette.icon.menu}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.9152 6.50067C24.9152 8.28882 23.4523 9.75332 21.6626 9.75332C19.8744 9.75332 18.4099 8.28882 18.4099 6.50067V4.87435C18.4099 3.08621 19.8744 1.6217 21.6626 1.6217C23.4523 1.6217 24.9152 3.08621 24.9152 4.87435V6.50067Z"
        stroke={theme.palette.icon.menu}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M23.2844 9.75195V10.435C23.2847 10.7215 23.3605 11.0029 23.5043 11.2508C23.648 11.4986 23.8546 11.7042 24.1032 11.8467L27.3372 13.6942C27.8351 13.9787 28.2489 14.3897 28.5368 14.8857C28.8247 15.3816 28.9764 15.9448 28.9765 16.5183V17.0704M14.3396 19.5099V16.5183C14.3396 15.9629 14.4803 15.4002 14.7795 14.8855C15.0668 14.3892 15.4807 13.9781 15.9789 13.6942L19.2129 11.8467C19.4621 11.7049 19.6692 11.4995 19.8131 11.2515C19.9569 11.0035 20.0324 10.7217 20.0317 10.435V9.75195"
        stroke={theme.palette.icon.menu}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
