import { useTheme } from '@mui/material/styles';

export default function SmartPhoneIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke={theme.palette.icon.main}
      stroke-width="2"
      stroke-linecap="round"
      strokeLinejoin="round"
      className="feather feather-smartphone"
    >
      <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
      <line x1="12" y1="18" x2="12.01" y2="18"></line>
    </svg>
  );
}
