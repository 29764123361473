import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { UserProfilePermissionListProps } from './type';

function UserProfilePermissionRowItem({
  key,
  rowItem,
  tableName,
}: UserProfilePermissionListProps): any {
  const theme = useTheme();

  return (
    <TableRow container key={key} alignItems="center" theme={theme}>
      <TableCell item xs={4}>
        <span>-</span>
      </TableCell>
      <TableCell item xs={4}>
        <span> -</span>
      </TableCell>
    </TableRow>
  );
}
export default UserProfilePermissionRowItem;
