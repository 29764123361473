import { Grid, Pagination, useMediaQuery } from '@mui/material';
import styled from 'styled-components';

export const PaginationWrapper = styled(Grid)(() => ({
  // justifyContent: "space-between",
  alignItems: 'center',
  margin: '1.25rem auto',
  paddingLeft: '16px',
}));

export const MuiPagination = styled(Pagination)``;

export const PaginationLabel = styled(Grid)(({ theme }) => ({
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.25rem',
  },
}));

export const PaginationContainer = styled(Grid)`
  padding-left: 24px;
`;
