import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { DeleteIcon } from 'assets/component';
import EditIcon from 'assets/component/EditIcon';
import SlashIcon from 'assets/component/SlashIcon';
import { IconsButton } from '../Buttons';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import ModalComponent from '../Modal';
import { useState } from 'react';
import { EditUserProfile } from 'app/pages/admin/component/EditUserProfile';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function UserManagementRowItem({ key, rowItem, tableName }: any): any {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClick = (): any => {};

  const onEditClick = (): any => {
    setOpen(true);
  };
  const onModalClose = (): any => {
    setOpen(false);
  };
  return (
    <>
      <TableRow
        container
        key={key}
        onClick={handleClick}
        alignItems="center"
        theme={theme}
      >
        <TableCell item xs={2}>
          <span>
            <CustomCheckbox
              label=""
              checkBoxStyle={{
                color: theme.palette.neutral.contrastText,
                '&.Mui-checked': {
                  color: theme.palette.neutral.contrastText,
                },
              }}
            />
          </span>
          <span>{rowItem.Id}</span>
        </TableCell>
        <TableCell item xs={2}>
          <OverflowTip text={rowItem.Name}></OverflowTip>
        </TableCell>
        <TableCell item xs={3}>
          <OverflowTip text={rowItem.Description}></OverflowTip>
        </TableCell>
        <TableCell item xs={2}>
          <OverflowTip text={rowItem.DateCreated}></OverflowTip>
        </TableCell>
        <TableCell item xs={2}>
          <OverflowTip text={rowItem.CreatedBy}></OverflowTip>
        </TableCell>
        <TableCell item xs={1}>
          <IconsButton children={<DeleteIcon />} />
          <IconsButton children={<SlashIcon />} />
          <IconsButton children={<EditIcon />} OnIconClick={onEditClick} />
        </TableCell>
      </TableRow>

      <ModalComponent
        modalOpen={open}
        children={<EditUserProfile theme={theme} />}
        setModalOpen={onModalClose}
      />
    </>
  );
}

export default UserManagementRowItem;
