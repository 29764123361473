import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function SMOpenCloseActivityRowItem({ key, rowItem }: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.Date ? rowItem.Date : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.Time ? rowItem.Time : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={rowItem['Event Name'] ? rowItem['Event Name'] : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={0.5}>
        <OverflowTip text={rowItem?.Area ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={rowItem['Manitou ID'] ? rowItem['Manitou ID'] : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1.5}>
        <OverflowTip
          text={rowItem['Site Name'] ? rowItem['Site Name'] : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={rowItem['Store Number'] ? rowItem['Store Number'] : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.Dealer ? rowItem.Dealer : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.Region ? rowItem.Region : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={rowItem.District ? rowItem.District : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={rowItem['User Number'] ? rowItem['User Number'] : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={rowItem['User Name'] ? rowItem['User Name'] : '-'}
        ></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default SMOpenCloseActivityRowItem;
