import { useTheme } from '@mui/material/styles';

export default function OnlineManualIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.1027 91.716C32.1027 89.9765 33.5128 88.5664 35.2522 88.5664H66.7477C68.4872 88.5664 69.8973 89.9765 69.8973 91.716C69.8973 93.4554 68.4872 94.8655 66.7477 94.8655H35.2522C33.5128 94.8655 32.1027 93.4554 32.1027 91.716Z"
        fill={theme.palette.icon.main}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M67.0322 17.9389C62.123 14.5715 56.2782 12.8353 50.3267 12.9764C34.9677 13.3303 22.5832 26.1251 22.6541 41.5055L22.6541 41.5097C22.6681 45.7814 23.6541 49.9939 25.5374 53.8281C27.4207 57.6622 30.152 61.0174 33.5243 63.6395L31.5909 66.126L33.54 63.6519C35.0438 64.8365 36.2616 66.3445 37.1033 68.0639C37.9449 69.7834 38.3887 71.6702 38.4018 73.5846L38.4019 73.6061L38.4019 75.9683H63.5983L63.5983 73.6C63.602 71.6673 64.0477 69.7609 64.9011 68.0268C65.7526 66.2967 66.9875 64.7837 68.5118 63.6029C73.1899 59.927 76.5928 54.8724 78.2385 49.1548C79.8852 43.434 79.6888 37.3399 77.6774 31.7368C75.6659 26.1338 71.9415 21.3063 67.0322 17.9389ZM29.6489 68.6055C25.526 65.3976 22.1867 61.294 19.8836 56.6052C17.5788 51.9131 16.3721 46.758 16.355 41.5304L19.5046 41.5201L16.355 41.5346C16.355 41.5332 16.355 41.5318 16.355 41.5304C16.2707 22.7403 31.3669 7.11289 50.1787 6.679C50.1783 6.67901 50.1791 6.67899 50.1787 6.679C57.452 6.50702 64.5957 8.62909 70.5953 12.7444C76.5955 16.86 81.1476 22.7603 83.606 29.6085C86.0645 36.4566 86.3044 43.905 84.2919 50.8972C82.2793 57.8894 78.1164 64.0704 72.3936 68.5638L72.3748 68.5785C71.6054 69.1732 70.9822 69.9358 70.5528 70.8083C70.1239 71.6799 69.8997 72.638 69.8974 73.6094C69.8974 73.6085 69.8974 73.6103 69.8974 73.6094V75.9683C69.8974 77.6389 69.2337 79.2411 68.0524 80.4224C66.8711 81.6037 65.2689 82.2674 63.5983 82.2674H38.4019C36.7312 82.2674 35.129 81.6037 33.9477 80.4224C32.7664 79.2411 32.1028 77.6389 32.1028 75.9683V73.6179C32.0947 72.6523 31.8701 71.7007 31.4456 70.8333C31.0209 69.9656 30.4069 69.2043 29.6489 68.6055Z"
        fill={theme.palette.icon.main}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.0837 22.2141C51.3745 20.4991 53.0004 19.3445 54.7154 19.6353C59.2077 20.3968 63.353 22.5339 66.579 25.7517C69.805 28.9695 71.9527 33.1092 72.7257 37.5996C73.0208 39.3139 71.8703 40.9427 70.1561 41.2378C68.4419 41.5329 66.813 40.3825 66.5179 38.6683C65.9661 35.4631 64.4332 32.5083 62.1305 30.2115C59.8279 27.9148 56.8691 26.3893 53.6626 25.8458C51.9476 25.555 50.793 23.9291 51.0837 22.2141Z"
        fill={theme.palette.icon.main}
      />
    </svg>
  );
}
