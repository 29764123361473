import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Skleton = styled(Box)(({ theme }) => ({
  width: '100%',
  overflowY: 'auto',
  padding: '4px',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.scroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.scroll,
  'scrollbar-width': 'auto',
}));
export const SkeletonBox = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  margin: '3px 0',
  borderRadius: '0.5rem',
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.25rem',
  },
  height: '9% !important',
  minHeight: '2.813rem',
}));

export function TableSkleton({ item = 10, theme }: any) {
  return (
    <Skleton theme={theme}>
      {Array.apply(0, Array(item)).map((x, i) => {
        return (
          <SkeletonBox variant="rectangular" animation="wave" theme={theme} />
        );
      })}
      <Grid
        sx={{
          alignItems: 'center',
          margin: '1.25rem auto',
          paddingLeft: '16px',
          height: '20px',
        }}
      ></Grid>
    </Skleton>
  );
}

export default TableSkleton;
