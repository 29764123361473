import { useTheme } from '@mui/material/styles';

export default function OpenServiceRequestIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95545 13.0389L0.152115 5.03336L3.35434 1.83114L11.3599 6.63447V9.83669L19.5942 18.071V18.7572L16.6207 21.5019L8.15767 13.0389H4.95545ZM39.265 32.481H37.4351C35.834 32.481 34.4616 32.2522 33.0893 32.0235L28.2859 37.513L39.0362 49.6357C41.5523 52.3805 45.8981 52.6092 48.4142 49.8645C51.1589 47.1197 50.9302 43.0026 48.1854 40.4865L39.265 32.481ZM47.0418 23.7892C50.7015 20.1295 51.8451 14.64 50.4728 9.83669C50.244 8.92177 49.1004 8.69304 48.6429 9.37923L40.6374 17.3848L34.2329 10.9803L42.2385 2.97479C42.9247 2.2886 42.6959 1.37367 41.781 1.14494C36.9777 -0.456167 31.4881 0.687484 27.8285 4.5759C23.7113 8.69304 22.7964 15.0975 25.0837 20.1295L2.21069 40.4865C-0.534076 43.0026 -0.762807 47.3484 1.98196 49.8645C4.72672 52.6092 8.84386 52.3805 11.3599 49.6357L31.7169 26.7627C36.7489 29.05 42.9247 28.1351 47.0418 23.7892Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
