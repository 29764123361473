import { Divider, Grid } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const CustomDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette.divider,
}));

export const ItemHeader = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '1rem',
  },
  fontSize: '1.25rem',
  fontWeight: 600,
  padding: '0.625rem 1rem 0.625rem 1rem',
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  borderRadius: '0.5rem',
  paddingBottom: '0.625rem',
  backgroundColor: theme.palette.customBackground.main,
}));

export const ActiveCardContainer = styled(Grid)`
  width: 100%;
  padding-left: 1rem;
`;

export const ServiceValueContainer = styled(Grid)<any>(({ theme }) => ({
  fontWeight: '600',
  fontSize: '1.125rem',
  [theme.breakpoints.down('xl')]: {
    fontSize: '1rem',
  },
}));

export const GridContainer = styled(Grid)<any>(({ theme }) => ({}));

export const ServiceItem = styled(Grid)<any>(({ theme }) => ({}));

export const MessageTemplate = styled(Grid)<any>(({ theme, isHighlight }) => ({
  [theme.breakpoints.down('xxl')]: {
    height: '8.5rem',
  },
  [theme.breakpoints.down('xl')]: {
    height: '10rem',
  },
  [theme.breakpoints.down('lg')]: {
    height: '14rem',
  },
  [theme.breakpoints.down('md')]: {
    height: '13rem',
  },
  padding: '0.5rem',
  wordWrap: 'break-word',
  background: isHighlight
    ? theme.palette.mode === 'dark'
      ? COLORS.haitiViolet
      : COLORS.titanWhite
    : '',
  borderRadius: '0.5rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '100%',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.containerScroll,
  'scrollbar-width': 'auto',
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    '-ms-overflow-style': 'scrollbar',
    'scrollbar-base-color': theme.palette.customBackground.containerScroll,
    'scrollbar-face-color': theme.palette.customBackground.containerScroll,
    'scrollbar-arrow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-shadow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-dark-shadow-color':
      theme.palette.customBackground.containerScroll,
  },
}));
