import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import CustomMaps from 'app/component/core/Maps';
import {
  SERVICE_REQUEST_HEADER_SITE_MANAGER,
  SORT_BY,
} from 'utils/Constants/index';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDashboardFilterData,
  getViewType,
  getFilterServiceRequestData,
  getSiteManager,
  getDashboardFilter,
  getCompanyId,
  getResolvedEvents,
  getDashboardListPageNo,
} from 'app/features/DashboardSlice/Selectors';
import { Listview } from 'app/component/core/CustomListView';
import { actions } from 'app/features/DashboardSlice/slice';
import { PanelGrid } from '../../styles';
import { ClearIcon, RoundTick } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomInput from 'app/component/core/CustomInputs/customInputsDashboard';
import { MultipleSelectCheckmarks } from 'app/component/core/CustomSelectWithoutLable/multiselectCheckbox';

import { FilterMenu } from 'app/component/core/Filter';
import { COLORS } from 'styles/colors';
import {
  checkIEVersion,
  customListSearch,
  getFilterData,
  manageDropdownDataForIE,
  useMobileDevice,
} from 'utils/CommonFn';
import { CustomMultiselectIE } from 'app/component/core/CustomMultiselectIE';
import { isEmpty } from 'utils/CommonFn/validators';
import { LocationFilter } from 'app/component/common/LocationFilter';
import { persistSiteInfoId } from 'app/features/authService';
import { CONTEXT } from 'utils/Constants/Content';
import { ROUTE_PATH } from 'app/pages/Router/constant';

function ServiceRequest({
  isLocation = true,
  isSort = false,
  source = CONTEXT.DASHBOARD,
}: any): JSX.Element {
  const themes = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getFilters = useSelector(getDashboardFilterData);
  const selectorServiceRequest: any =
    getFilters?.data?.serviceRequest?.[0] ?? [];
  const selectorServiceRequestData: any = useSelector(
    getFilterServiceRequestData,
  );
  const selectedCompanyId = useSelector(getCompanyId);

  const subject = selectorServiceRequest?.sr_subject || [];
  const status = selectorServiceRequest?.sr_status || [];
  const severity = selectorServiceRequest?.severity || [];
  const incidentType = selectorServiceRequest?.sr_type || [];
  const srSubStatus = selectorServiceRequest?.sr_sub_status || [];
  const viewType = useSelector(getViewType);
  const isSiteManager: any = useSelector(getSiteManager);
  const isResolvedEvents: any = useSelector(getResolvedEvents);
  const selectedView = isSiteManager ? 'list' : viewType;
  const isMobile = useMobileDevice();
  const MapViewData = selectorServiceRequestData.mapData || [];
  const ListViewData = selectorServiceRequestData.listData || [];
  const TotalListCount = selectorServiceRequestData.recordCount || 0;
  const dashboardPageNo = useSelector(getDashboardListPageNo);
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState(
    pathname.includes('/dashboard/sitemanager') ? 1 : dashboardPageNo,
  );
  const dashboardFilter: any = useSelector(getDashboardFilter);
  const {
    locationValue,
    subjectValue,
    SeverityValue,
    StatusValue,
    serviceReqNumber,
    incidentTypeValue,
    sortColumn,
    sortBy,
    subStatusValue,
  } = dashboardFilter;
  const sortObject = { sortColumn: '', sortBy: '' };

  const defaultDashboardFilter = (): any => {
    let reqObj: any = {
      page: dashboardPageNo,
      recordCountRequire: selectedView === 'list',
    };
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (StatusValue.length > 0) {
      reqObj.srStatus = customListSearch(StatusValue, status);
    }
    if (subjectValue.length > 0) {
      reqObj.srSubject = customListSearch(subjectValue, subject);
    }
    if (SeverityValue.length > 0) {
      reqObj.severity = customListSearch(SeverityValue, severity);
    }
    if (incidentTypeValue.length > 0) {
      reqObj.srType = customListSearch(incidentTypeValue, incidentType);
    }
    if (subStatusValue.length > 0) {
      reqObj.srSubStatus = customListSearch(subStatusValue, srSubStatus);
    }
    if (serviceReqNumber.length > 0) {
      reqObj.srNumber = serviceReqNumber;
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };
  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    recordCountRequire: selectedView === 'list',
    ...defaultDashboardFilter(),
  });
  const srStatusOptionIE: any = manageDropdownDataForIE(status);
  const srSubjectOptionIE: any = manageDropdownDataForIE(subject);
  const severityOptionIE: any = manageDropdownDataForIE(severity);
  const incidentOptionIE: any = manageDropdownDataForIE(incidentType);
  const srSubStatusOptionIE: any = manageDropdownDataForIE(srSubStatus);
  const [selectedSrStatusIE, setSelectedSrStatusIE] = useState(
    StatusValue ?? [],
  );
  const [selectedSrSubjectIE, setSelectedSrSubjecIE] = useState(
    subjectValue ?? [],
  );
  const [selectedSeverityIE, setSelectedSeverityIE] = useState(
    SeverityValue ?? [],
  );
  const [selectedIncidentIE, setSelectedIncidentIE] = useState(
    incidentTypeValue ?? [],
  );
  const [selectedSrSubStatusIE, setSelectedSrSubStatusIE] = useState(
    subStatusValue ?? [],
  );
  const handleChangeSubject = (e: any): void => {
    let selectedVal = e.target.value || '';
    let newValue = [];
    if (selectedVal[selectedVal.length - 1] === 'all') {
      if (subject.length > 0 && subject.length === subjectValue.length) {
        newValue = [];
        selectedVal = [];
      } else {
        newValue = subject.map((o: any) => o.value);
        selectedVal = subject.map((o: any) => o.key);
      }
    } else {
      newValue = selectedVal.map((val: any) => {
        const obj = subject.find((o: any) => o.key === val);
        return obj.value;
      });
    }
    const selectedType = { srSubject: newValue, page: 1 };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          subjectValue: selectedVal,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeStatus = (e: any): void => {
    const selectedVal = e.target.value || '';
    const newValue = selectedVal.map((val: any) => {
      const obj = status.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { srStatus: newValue, page: 1 };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          StatusValue: selectedVal,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
    dispatch(actions.updateResolvedEventsActive(false));
  };
  const handleChangeSubStatus = (e: any): void => {
    const selectedVal = e.target.value || '';
    const newValue = selectedVal.map((val: any) => {
      const obj = srSubStatus.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { srSubStatus: newValue, page: 1 };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          subStatusValue: selectedVal,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };
  const handleChangeSeverity = (e: any): void => {
    const selectedVal = e.target.value || '';
    const newValue = selectedVal.map((val: any) => {
      const obj = severity.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { severity: newValue, page: 1 };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          SeverityValue: selectedVal,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeIncident = (e: any): void => {
    const selectedVal = e.target.value || '';
    const newValue = selectedVal.map((val: any) => {
      const obj = incidentType.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { srType: newValue, page: 1 };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          incidentTypeValue: selectedVal,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeResolvedevents = () => {
    if (status && status.length > 0) {
      let selectedVal = ['Closed'];
      if (isResolvedEvents) {
        selectedVal = ['Open'];
        dispatch(actions.updateResolvedEventsActive(false));
      } else {
        dispatch(actions.updateResolvedEventsActive(true));
      }
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      const newValue = selectedVal.map((val: any) => {
        const obj = status.find((o: any) => o.key === val);
        return obj.value;
      });
      const selectedType = { srStatus: newValue, page: 1 };
      if (selectedVal) {
        let selectedValues = [];
        if (checkIEVersion()) {
          selectedValues = srStatusOptionIE.filter((o: any) =>
            selectedVal.includes(o.label),
          );
          setSelectedSrStatusIE(selectedValues);
        } else {
          selectedValues = selectedVal;
        }
        dispatch(
          actions.updateDashboardFilter({
            StatusValue: selectedValues,
            ...sortObject,
          }),
        );
        setDefaultValue({ ...defaultValue, ...selectedType });
        updateRecord(selectedType);
      }
    }
  };
  const onServiceRequestEditClick = (value: any) => {
    navigate({ pathname: ROUTE_PATH.CASE_DETAILS }, { state: value });
  };

  const onFilterMenuClick = (keyName: any) => {
    const sortObj =
      sortColumn && keyName === 'list' ? { sortColumn, sortBy } : {};
    updateRecord({ recordCountRequire: keyName === 'list', ...sortObj }, true);
  };

  const updateRecord = (params?: any, isMenuClick = false) => {
    if (selectedCompanyId) {
      dispatch(
        actions.fetchFilterServiceRequest({
          ...defaultValue,
          ...params,
        }),
      );
    }
  };
  const onPagination = (pageNo: any) => {
    setCurrentPage(pageNo);
    dispatch(actions.updateDashboardListPageNo(pageNo));
    const reqObj: any = { page: pageNo };
    if (sortBy) {
      reqObj.sortColumn = sortColumn;
      reqObj.sortBy = sortBy;
    }
    updateRecord(reqObj);
  };
  const onFilterClear = () => {
    if (selectedCompanyId) {
      setDefaultValue({
        page: 1,
        recordCountRequire: selectedView === 'list',
      });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(
        actions.updateDashboardFilter({
          StatusValue: ['Open'],
          subjectValue: [],
          SeverityValue: [],
          incidentTypeValue: [],
          subStatusValue: [],
          locationValue: {
            address: '',
            city: '',
            siteName: '',
            state: [],
            zipcode: '',
          },
          serviceReqNumber: '',
          ...sortObject,
        }),
      );
      dispatch(actions.updateResolvedEventsActive(false));
      const newValue = status.find((o: any) => o.key === 'Open').value;
      const selectedType = { srStatus: [newValue] };
      dispatch(
        actions.fetchFilterServiceRequest({
          page: 1,
          recordCountRequire: selectedView === 'list',
          ...selectedType,
        }),
      );
      if (checkIEVersion()) {
        setSelectedSrStatusIE([]);
        setSelectedSrSubjecIE([]);
        setSelectedSeverityIE([]);
        setSelectedSrSubStatusIE([]);
      }
    }
  };
  const onSearchClick = (e: any) => {
    if (e === 'search' && serviceReqNumber) {
      const selectedType = {
        srNumber: serviceReqNumber,
        page: 1,
      };
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(actions.updateDashboardFilter({ ...sortObject }));
      setDefaultValue({ ...defaultValue, ...selectedType });
      updateRecord({ srNumber: serviceReqNumber });
    } else {
      try {
        if ('srNumber' in defaultValue) {
          delete defaultValue.srNumber;
          updateRecord({ ...defaultValue, page: currentPage });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onTextChange = (e: any) => {
    const searchText = e.target.value;
    dispatch(actions.updateDashboardFilter({ serviceReqNumber: searchText }));
    if (isEmpty(searchText)) {
      try {
        if ('srNumber' in defaultValue) {
          delete defaultValue.srNumber;
        }
        setCurrentPage(1);
        dispatch(actions.updateDashboardListPageNo(1));
        dispatch(actions.updateDashboardFilter({ ...sortObject }));
        updateRecord({ ...defaultValue, page: 1 });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeSrStatusIE = (item: any): void => {
    setSelectedSrStatusIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { srStatus: newValue, page: 1 };
    if (item) {
      dispatch(actions.updateResolvedEventsActive(false));
      dispatch(
        actions.updateDashboardFilter({ StatusValue: item, ...sortObject }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };
  const handleChangeSrSubStatusIE = (item: any): void => {
    setSelectedSrSubStatusIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { srSubStatus: newValue, page: 1 };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({ subStatusValue: item, ...sortObject }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeSrSubjectIE = (item: any): void => {
    setSelectedSrSubjecIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { srSubject: newValue, page: 1 };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({ subjectValue: item, ...sortObject }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeSeverityIE = (item: any): void => {
    setSelectedSeverityIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { severity: newValue, page: 1 };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({ SeverityValue: item, ...sortObject }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const handleChangeIncidentIE = (item: any): void => {
    setSelectedIncidentIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { srType: newValue, page: 1 };
    if (item) {
      dispatch(
        actions.updateDashboardFilter({
          incidentTypeValue: item,
          ...sortObject,
        }),
      );
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const onSortClick = (e: any, item: any): void => {
    const sortObj: any = {};
    if (item.sortColumn === sortColumn) {
      sortObj.sortColumn = sortColumn;
      const sort = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC;
      sortObj.sortBy = sort;
    } else {
      sortObj.sortColumn = item.sortColumn;
      sortObj.sortBy = SORT_BY.ASC;
    }
    dispatch(actions.updateDashboardFilter(sortObj));
    updateRecord(sortObj);
  };
  useEffect(() => {
    const obj = {
      page: currentPage,
      recordCountRequire: selectedView === 'list',
      ...defaultDashboardFilter(),
    };
    setDefaultValue(obj);
  }, [selectedView]);

  const applyfilter = (value: any) => {
    if (value) {
      dispatch(actions.updateDashboardFilter(value));
      const data: any = getFilterData(value.locationValue);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord({ ...data, page: 1 });
    }
  };
  const onSiteHandleClick = (e: any, siteId: any) => {
    e.preventDefault();
    persistSiteInfoId(siteId);
    navigate(`/dashboard/sitemanager/${siteId}`);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item container xs={12} sm={12} md={10} spacing={1}>
            <Grid item container xs={12} md={11} spacing={1}>
              {isLocation && (
                <Grid item xs={6} md={2}>
                  <LocationFilter onfilter={applyfilter} />
                </Grid>
              )}
              <Grid item xs={6} md={2}>
                <CustomInput
                  label="Service Request Number"
                  onIconClick={onSearchClick}
                  onTextChange={onTextChange}
                  value={serviceReqNumber}
                  autofocus={serviceReqNumber.length > 0}
                />
              </Grid>

              {!checkIEVersion() ? (
                <>
                  <Grid item xs={6} md={2}>
                    <MultipleSelectCheckmarks
                      value={subjectValue}
                      handleChange={handleChangeSubject}
                      options={subject}
                      placeholder="SR Subject"
                      isSelectAll={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={1.5}>
                    <MultipleSelectCheckmarks
                      value={incidentTypeValue}
                      handleChange={handleChangeIncident}
                      options={incidentType}
                      placeholder="Type"
                    />
                  </Grid>
                  <Grid item xs={6} md={1.5}>
                    <MultipleSelectCheckmarks
                      value={StatusValue}
                      handleChange={handleChangeStatus}
                      options={status}
                      placeholder="SR Status"
                    />
                  </Grid>
                  <Grid item xs={6} md={1.5}>
                    <MultipleSelectCheckmarks
                      value={subStatusValue}
                      handleChange={handleChangeSubStatus}
                      options={srSubStatus}
                      placeholder="Sub Status"
                    />
                  </Grid>
                  <Grid item xs={6} md={1.5}>
                    <MultipleSelectCheckmarks
                      value={SeverityValue}
                      handleChange={handleChangeSeverity}
                      options={severity}
                      placeholder="Severity"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <CustomMultiselectIE
                      key={'sr_subject'}
                      value={selectedSrSubjectIE}
                      handleChange={handleChangeSrSubjectIE}
                      options={srSubjectOptionIE}
                      placeholder="SR Subject"
                      hasMultiselect={true}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <CustomMultiselectIE
                      key={'incident'}
                      value={selectedIncidentIE}
                      handleChange={handleChangeIncidentIE}
                      options={incidentOptionIE}
                      placeholder="Type"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <CustomMultiselectIE
                      key={'sr_status'}
                      value={selectedSrStatusIE}
                      handleChange={handleChangeSrStatusIE}
                      options={srStatusOptionIE}
                      placeholder="SR Status"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <CustomMultiselectIE
                      key={'sr_sub_status'}
                      value={selectedSrSubStatusIE}
                      handleChange={handleChangeSrSubStatusIE}
                      options={srSubStatusOptionIE}
                      placeholder="Sub Status"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <CustomMultiselectIE
                      key={'severity'}
                      value={selectedSeverityIE}
                      handleChange={handleChangeSeverityIE}
                      options={severityOptionIE}
                      placeholder="Severity"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={1} sx={{ marginTop: '1px' }}>
              {!isMobile && (
                <IconsButton
                  name={'ClearFilter'}
                  children={<ClearIcon color={themes.palette.icon.main} />}
                  style={{
                    background: themes.palette.primary.main,
                    borderRadius: 4,
                    padding: '6px',
                  }}
                  OnIconClick={onFilterClear}
                  title={CONTEXT.CLEAR_FILTER}
                  isShowTooltip={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={2} justifyContent="flex-end">
            {isMobile && (
              <IconsButton
                name={'ClearFilter'}
                children={<ClearIcon color={themes.palette.icon.main} />}
                style={{
                  background: themes.palette.primary.main,
                  borderRadius: 4,
                  padding: '0.35rem',
                }}
                OnIconClick={onFilterClear}
                title={CONTEXT.CLEAR_FILTER}
                isShowTooltip={true}
              />
            )}
            <FilterMenu onhandleChange={onFilterMenuClick} />
            <IconsButton
              style={{
                background: isResolvedEvents ? COLORS.lightRed : COLORS.white,
                borderRadius: 4,
                marginLeft: '4px',
              }}
              name={'ResolvedEvents'}
              children={
                <RoundTick
                  color={isResolvedEvents ? COLORS.white : COLORS.royalBlue}
                />
              }
              OnIconClick={handleChangeResolvedevents}
              title={'Resolved Events'}
              isShowTooltip={true}
            />
          </Grid>
        </Grid>

        <PanelGrid container item pt={1} theme={themes}>
          {selectedView === 'map' && !isSiteManager && (
            <CustomMaps
              markerResponseData={
                MapViewData && MapViewData.length > 0 ? MapViewData : []
              }
              type={CONTEXT.SERVICE_REQUESTS}
              loading={selectorServiceRequestData.loading}
            />
          )}
          {selectedView === 'list' && (
            <Listview
              headerItems={SERVICE_REQUEST_HEADER_SITE_MANAGER}
              name={CONTEXT.SERVICE_REQUESTS}
              listData={
                ListViewData && ListViewData.length > 0 ? ListViewData : []
              }
              totalRecords={TotalListCount}
              onPageChange={onPagination}
              currentPage={currentPage}
              loading={selectorServiceRequestData.loading}
              onServiceRequestEditHandle={onServiceRequestEditClick}
              CustomHeight={500}
              isSort={isSort}
              onSortClick={onSortClick}
              onSiteHandle={onSiteHandleClick}
              source={source}
            />
          )}
        </PanelGrid>
      </Box>
    </>
  );
}

export default React.memo(ServiceRequest);
