import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { NotificationListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { getFormatedDate } from 'utils/CommonFn';
import { DATE_FORMAT_3 } from 'utils/Constants';

function NewReleaseRowItem({
  key,
  rowItem,
  tableName,
  handleChange,
  onEditIconClick,
}: NotificationListProps): any {
  const theme = useTheme();

  const handleClick = (): any => {
    handleChange(rowItem);
  };
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
      style={{ cursor: 'pointer' }}
    >
      <TableCell item xs={3}>
        <OverflowTip text={rowItem.Title || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={5}>
        <OverflowTip text={rowItem.Message || '-'}></OverflowTip>
      </TableCell>

      <TableCell item xs={2}>
        <OverflowTip
          text={getFormatedDate(rowItem.StartDate, DATE_FORMAT_3)}
        ></OverflowTip>
      </TableCell>

      <TableCell item xs={2}>
        <OverflowTip
          text={getFormatedDate(rowItem.EndDate, DATE_FORMAT_3)}
        ></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default NewReleaseRowItem;
