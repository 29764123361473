import { PaletteMode } from '@mui/material';
import { COLORS } from './colors';

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: COLORS.linkWaterBlue, // filter color
          },
          customBackground: {
            main: COLORS.deeplightGrey, // new background
            light: COLORS.solitude,
            dark: COLORS.darkBlue,
            table: COLORS.linkWaterBlue, // new table
            primary: COLORS.deeplightGrey, // container back
            secondary: COLORS.deeplightGrey, // avatar
            scroll: COLORS.deeplightGrey,
            scrollIE: COLORS.deeplightGrey,
            containerScroll: COLORS.linkWaterBlue,
            logo: COLORS.black,
          },
          neutral: {
            main: COLORS.solitude,
            contrastText: COLORS.linkWaterBlue, // inputs search
            light: COLORS.deeplightGrey,
          },
          divider: COLORS.ghostBlue,
          icon: {
            main: COLORS.grey,
            menu: COLORS.jacarta,
          },
          background: {
            default: COLORS.white, // new background
          },
          text: {
            primary: COLORS.smokyViolet,
            secondary: COLORS.WhiteLilac,
          },
          hover: {
            primary: COLORS.ghostBlue,
          },
          treeGroup: COLORS.whiteLilacBlue,
          permissionGroup: COLORS.ghostBlue,
          customTreeGroup: COLORS.whiteLilacBlue,
          customTreePermission: COLORS.ghostBlue,
          dottedLine: COLORS.black,
          gridcolor: COLORS.whiteGridColor,
        }
      : {
          primary: {
            main: COLORS.haitiViolet,
          },
          customBackground: {
            main: COLORS.gulfBlue,
            light: COLORS.gulfBlue,
            dark: COLORS.white,
            table: COLORS.smokyViolet,
            primary: COLORS.gulfBlue,
            secondary: COLORS.white,
            scroll: COLORS.painiViolet,
            scrollIE: COLORS.haitiViolet,
            containerScroll: COLORS.painiViolet,
            logo: COLORS.white,
          },
          neutral: {
            main: COLORS.smokyViolet,
            contrastText: COLORS.smokyViolet,
            light: COLORS.haitiViolet,
          },
          divider: COLORS.haitiViolet,
          icon: {
            main: COLORS.white,
            menu: COLORS.white,
          },
          background: {
            default: COLORS.jaguar,
            paper: COLORS.eclipseGrey,
          },
          text: {
            primary: COLORS.white,
            secondary: COLORS.haitiViolet,
          },
          hover: {
            primary: COLORS.primaryDarkBlue,
          },
          treeGroup: COLORS.primaryDarkBlue,
          permissionGroup: COLORS.primaryDarkGray,
          customTreeGroup: COLORS.primaryTreeGroup,
          customTreePermission: COLORS.primaryDarkGray,
          dottedLine: COLORS.white,
          gridcolor: COLORS.gridcolor,
        }),
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 14,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1921,
    },
  },
  zIndex: { tooltip: 99999 },
});

export default getDesignTokens;
