import { useTheme } from '@mui/material/styles';
export default function MarchNetworkssearchlightIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="129"
      height="112"
      viewBox="0 0 129 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.967 72.4533L46.6344 87.0384L93.4105 23.978L82.5543 9.53455L35.967 72.4533Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M53.7146 0L35.967 23.978L46.8232 38.5631L75.2854 0H53.7146Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M89.6817 0L107.476 23.978L53.7146 96.6202L64.618 111.205L129 23.978L111.394 0H89.6817Z"
        fill={theme.palette.icon.menu}
      />
      <path
        d="M17.7947 0L0 23.978L28.8397 62.73L39.5071 48.2865L21.5236 23.978L39.3183 0H17.7947Z"
        fill={theme.palette.icon.menu}
      />
    </svg>
  );
}
