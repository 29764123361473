export default function ExportIcon(): JSX.Element {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.320312" width="36" height="36" rx="4" fill="white" />
      <path
        d="M20.3203 19.6663L24.487 15.4997L20.3203 11.333"
        stroke="#434874"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1533 24.6667V18.8333C11.1533 17.9493 11.5045 17.1014 12.1296 16.4763C12.7548 15.8512 13.6026 15.5 14.4867 15.5H24.4867"
        stroke="#434874"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
