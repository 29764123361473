import { updateStorage } from 'app/features/authService';
import { STORE_KEY } from './Constant';

export function getFromLocalStorage(keyName: string) {
  return window.localStorage.getItem(keyName);
}

export const saveOnLocalStorage = (keyName: string, defaultValue: string) => {
  window.localStorage.setItem(keyName, defaultValue);
};
export const saveOnCookie = (
  cookieName: string,
  cookieValue: string,
  hourToExpire: number = 0,
) => {
  const date = new Date();
  if (hourToExpire > 0) {
    date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
    document.cookie = `${cookieName}=${cookieValue}; expires=${date.toUTCString()}`;
  } else {
    document.cookie = `${cookieName}=${cookieValue};`;
  }
};

export const getFromCookies = (key: string) => {
  const AllCookies = document.cookie.split(';');
  const getCookies = () => {
    const name = key + '=';
    for (let i = 0; i < AllCookies.length; i++) {
      const temp = AllCookies[i].trim();
      if (temp.indexOf(name) === 0)
        return temp.substring(name.length, temp.length);
    }
    return '';
  };
  const cookie = getCookies();
  return cookie;
};
export const allStorage = () => {
  try {
    let secretKey: string = '';
    const keys: any = Object.keys(localStorage);
    let i: any = keys.length;
    while (i--) {
      const storageItem: any = localStorage.getItem(keys[i]);
      if (storageItem.startsWith('{')) {
        const findData = JSON.parse(storageItem);
        if (findData?.credentialType === 'IdToken') {
          secretKey = !secretKey ? findData?.secret : secretKey;
          if (secretKey) {
            updateStorage(STORE_KEY.AD_TOKEN, secretKey);
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteCookies = () => {
  const Cookies = document.cookie.split(';');
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + '=;expires=' + new Date(0).toUTCString();
};
// Session storage - onlt for active tab
export function getFromSessionStorage(keyName: string) {
  return window.sessionStorage.getItem(keyName);
}
export const saveOnSessionStorage = (keyName: string, defaultValue: string) => {
  window.sessionStorage.setItem(keyName, defaultValue);
};
export const clearSessionStorage = () => {
  window.sessionStorage.clear();
};
