import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer } from './slice';
import { dashboardSaga } from './saga';

function DashboardSlice() {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: dashboardSaga });

  return <></>;
}

export default DashboardSlice;
