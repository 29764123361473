import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import CustomTooltip from '../Tooltip';
import { COLORS } from 'styles/colors';
import { SiteGroupsCSListProps } from './type';
import { useNavigate } from 'react-router';
import { DeleteIcon } from 'assets/component';
import { CustomCheckbox } from '../CustomCheckbox';
import { getUserPermissions } from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';
import { useDispatch } from 'react-redux';
import { actions } from 'app/features/AdminSlice/slice';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { ROUTE_PATH } from 'app/pages/Router/constant';

function SiteGroupsCSRowItem({
  key,
  rowItem,
  tableName,
  handleChange,
}: SiteGroupsCSListProps): any {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isHasPermission = getUserPermissions(USER_PERMISSIONS.SITE_GROUP_ADMIN);

  const onEditIconClick = (): void => {
    dispatch(actions.checkUserAccessPermission(!isHasPermission));
    if (isHasPermission) {
      if (rowItem && rowItem.GroupDefinitionId) {
        navigate({ pathname: ROUTE_PATH.EDIT_SITE_GROUP }, { state: rowItem });
      }
    }
  };

  const onDeleteClick = (): void => {
    dispatch(actions.checkUserAccessPermission(!isHasPermission));
    if (isHasPermission) {
      handleChange();
    }
  };

  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={4}>
        <OverflowTip text={rowItem?.Name || '-'}></OverflowTip>
      </TableCell>

      <TableCell item xs={3}>
        <OverflowTip text={rowItem?.MasterCompanyName || '-'}></OverflowTip>
      </TableCell>

      <TableCell item xs={3}>
        <CustomCheckbox
          label=""
          isChecked={rowItem?.IsAllGroup}
          checkBoxStyle={{
            color: theme.palette.icon.main,
            padding: '0.563rem 0.25rem 0.563rem 0.563rem',
            '&.Mui-checked': {
              color: theme.palette.icon.main,
            },
          }}
        />
      </TableCell>

      <TableCell item xs={2}>
        <CustomTooltip title={'Edit'}>
          <div>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity: !isHasPermission ? 0.6 : 1,
              }}
              OnIconClick={onEditIconClick}
            />
          </div>
        </CustomTooltip>
        <CustomTooltip title={'Delete'}>
          <div>
            <IconsButton
              name="delete"
              children={<DeleteIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity: !isHasPermission ? 0.6 : 1,
              }}
              OnIconClick={onDeleteClick}
            />
          </div>
        </CustomTooltip>
      </TableCell>
    </TableRow>
  );
}
export default SiteGroupsCSRowItem;
