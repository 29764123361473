import { useTheme } from '@mui/material/styles';
export default function HamburgerMenuIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        fill={theme.palette.customBackground.main}
      />
      <path
        d="M11 20H29"
        stroke={theme.palette.customBackground.logo}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 14H29"
        stroke={theme.palette.customBackground.logo}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 26H29"
        stroke={theme.palette.customBackground.logo}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
