import { useTheme } from '@mui/material/styles';
export default function DownFillIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_15996_8582)">
        <path d="M6 9L12 15L18 9H6Z" fill={theme.palette.icon.main} />
      </g>
      <defs>
        <clipPath id="clip0_15996_8582">
          <rect width="24" height="24" fill={theme.palette.icon.main} />
        </clipPath>
      </defs>
    </svg>
  );
}
