import icons from 'assets/icons/FirewallIcon.svg';

export default function FirewallIcon(): JSX.Element {
  return (
    <>
      <img
        src={icons}
        alt="Fortinet"
        style={{ width: '100px', height: '100px' }}
      />
    </>
  );
}
