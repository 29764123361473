import { CircularProgress, inputLabelClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTextField } from 'app/component/core/CustomInputs/style';
import { COLORS } from 'styles/colors';
import React from 'react';
import { IconsButton } from 'app/component/core/Buttons';
import CustomTooltip from 'app/component/core/Tooltip';
import EditIcon from 'assets/component/EditIcon';
import { SaveIcon } from 'assets/component';

export interface Props {
  label: string;
  onTextChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  type?: string;
  errorText?: string;
  autofocus?: boolean;
  ID?: string;
  onBlur?: any;
  fieldName?: any;
  isDisabled?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  length?: any;
  isMaxLength?: any;
  isNumber?: boolean;
  customStyle?: any;
  loading?: boolean;
  customId?: any;
  inputPropStyle?: any;
  inputEditSaveIcon?: any;
}

function Inputs({
  type = 'text',
  label,
  value = '',
  errorText = '',
  autofocus,
  ID,
  fieldName,
  isDisabled = false,
  readOnly = false,
  defaultValue,
  length = 0,
  isMaxLength = false,
  isNumber = false,
  customStyle = {},
  loading = false,
  onBlur = () => {},
  onTextChange = () => {},
  customId = {},
  inputPropStyle = {},
  inputEditSaveIcon = {},
}: Props) {
  const theme = useTheme();
  const {
    editSaveIcon = false,
    onEditClick = () => {},
    isEdited = false,
  } = inputEditSaveIcon;
  return (
    <>
      <StyledTextField
        disabled={isDisabled}
        id={ID}
        autoFocus={autofocus}
        error={errorText.length > 0}
        helperText={errorText.length > 0 && errorText}
        label={label}
        variant="filled"
        type={type}
        inputProps={{
          'data-Id': customId,
          maxLength: isMaxLength === true ? length : '',
        }}
        autoComplete="off"
        sx={[
          {
            paddingBottom: '0.625rem',
            fontSize: '0.875rem',
            '& .MuiFilledInput-input:-webkit-autofill,.MuiFilledInput-input:-webkit-autofill:focus':
              {
                color: theme.palette.text.primary,
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.neutral.contrastText} inset`,
              },
            '& input::-webkit-inner-spin-button': {
              WebkitAppearance: !isNumber ? 'none' : '',
              margin: 0,
            },
            '& input[type=number]': {
              MozAppearance: !isNumber ? 'textfield' : '',
            },
          },
          customStyle,
        ]}
        InputProps={{
          disableUnderline: true,
          readOnly,
          style: {
            border: errorText.length > 1 ? `2px solid ${COLORS.lightRed}` : '',
            backgroundColor: loading
              ? theme.palette.neutral.contrastText
              : 'inherit',
            ...inputPropStyle,
          },
          endAdornment: editSaveIcon ? (
            <CustomTooltip
              title={isEdited ? 'Save' : 'Edit'}
              key={Math.random()}
            >
              <IconsButton
                style={{
                  background: COLORS.iconBgColor,
                  borderRadius: 4,
                }}
                name={isEdited ? 'Save' : 'Edit'}
                children={isEdited ? <SaveIcon /> : <EditIcon />}
                OnIconClick={onEditClick}
              />
            </CustomTooltip>
          ) : loading ? (
            <CircularProgress color={'inherit'} size={30} />
          ) : (
            ''
          ),
        }}
        InputLabelProps={{
          sx: {
            color: theme.palette.text.primary,
            fontFamily: "'Inter', sans-serif",
            fontWeight: 500,
            fontSize: '0.875rem',
            opacity: readOnly ? '0.6' : '1',
            [`&.${inputLabelClasses.shrink}`]: {
              color: theme.palette.text.primary,
            },
          },
        }}
        defaultValue={defaultValue}
        value={value}
        onChange={onTextChange}
        onBlur={onBlur}
        fullWidth
        name={fieldName}
        data-testid="custom-inputs"
      />
      {/* {errorText.length > 0 && <ErrorLabel>{errorText}</ErrorLabel>} */}
    </>
  );
}

export default Inputs;
