import { useTheme } from '@mui/material/styles';
export default function FileIcon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      xmlns="
http://www.w3.org/2000/svg"
      width="29"
      height="31"
      viewBox="0 0 29 31"
      fill="none"
    >
      <path
        d="M17.875 1H4.375C3.47989 1 2.62145 1.30553 1.98851 1.84939C1.35558 2.39325 1 3.13087 1 3.9V27.1C1 27.8691 1.35558 28.6068 1.98851 29.1506C2.62145 29.6945 3.47989 30 4.375 30H24.625C25.5201 30 26.3785 29.6945 27.0115 29.1506C27.6444 28.6068 28 27.8691 28 27.1V9.7L17.875 1Z"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.875 1V9.7H28"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.25 16.9502H7.75"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.25 22.75H7.75"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.125 11.1499H9.4375H7.75"
        stroke={theme.palette.icon.main}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
