import { useTheme } from '@mui/material';
import { COLORS } from 'styles/colors';

export default function InfoCircleIconSolidWhite({
  isThemeRequired = false,
}: any): JSX.Element {
  const theme = useTheme();
  const iconColor = isThemeRequired ? theme.palette.icon.main : COLORS.white;
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4951 22.5C18.018 22.5 22.4951 18.0228 22.4951 12.5C22.4951 6.97715 18.018 2.5 12.4951 2.5C6.97227 2.5 2.49512 6.97715 2.49512 12.5C2.49512 18.0228 6.97227 22.5 12.4951 22.5Z"
        stroke={iconColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4951 16.5V12.5"
        stroke={iconColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4951 8.5H12.5051"
        stroke={iconColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
