import icons from 'assets/icons/HanwhaManagement.svg';
export default function HanwhaManagementSoftwareIcon(): JSX.Element {
  return (
    <>
      <img
        src={icons}
        alt="Hanwha Management Software"
        style={{ width: '80px', height: '80px' }}
      />
    </>
  );
}
