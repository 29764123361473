import { useTheme } from '@mui/material';
export default function TickIcon({ isThemeBased = false }: any): JSX.Element {
  const theme = useTheme();
  const customColor = isThemeBased ? theme.palette.icon.main : 'white';
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.78809 6.59571L4.01031 8.81793L11.4177 1.41052"
        stroke={customColor}
        stroke-width="2"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
