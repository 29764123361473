// import { useTheme } from "@mui/material/styles";
import { COLORS } from 'styles/colors';

export default function CustomToggleLeft(): JSX.Element {
  // const theme = useTheme();
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="20"
    //   height="20"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   stroke={theme.palette.icon.main}
    //   stroke-width="2"
    //   stroke-linecap="round"
    //   stroke-linejoin="round"
    //   className="feather feather-toggle-left"
    // >
    //   <rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect>
    //   <circle cx="8" cy="12" r="3"></circle>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 24 24"
      fill="none"
      stroke={COLORS.white}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-circle"
    >
      <circle cx="12" cy="12" r="10"></circle>
    </svg>
  );
}
