import { Breadcrumbs, Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { Link } from 'react-router-dom';

export const Breadcrum = styled(Breadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.mode === 'dark' ? COLORS.white : COLORS.black,
  },
  [theme.breakpoints.down('xxl')]: {
    fontSize: '0.875rem !important',
  },
}));
export const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xxl')]: {
    fontSize: '0.875rem !important',
  },
  color: theme.palette.mode === 'dark' ? COLORS.white : COLORS.smokyViolet,
}));

export const CustomLink = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down('xxl')]: {
    fontSize: '0.875rem !important',
  },
  color: theme.palette.mode === 'dark' ? COLORS.white : COLORS.smokyViolet,
}));
