import styled from 'styled-components';
import { Link, IconButton } from '@mui/material';
import { PrimaryButtonProps } from './type';
import { COLORS } from 'styles/colors';

export const IconContainer = styled(IconButton)`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-right: 0.625rem !important;
  background-color: transparent;
  // border-radius: 21px;
  img {
    width: 24px;
    height: 24px;
  }
`;
export const Wrapper = styled(Link)`
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: PrimaryButtonProps) =>
    props.fullWidth ? '100%' : props.width || 'auto'};
  height: ${(props: PrimaryButtonProps) =>
    props.customHeight ? props.customHeight : 'auto'};
  font-family: 'Inter', Sans-serif;
  color: ${COLORS.white};
  background: rgba(72, 65, 235, 1);
  border-radius: 0.5rem;
  border: none;
  padding: 0.917rem;
  outline: none;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
