export default function MinusRemoveIcon(): JSX.Element {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.539673"
        y="0.0290527"
        width="24"
        height="24"
        rx="8"
        fill="#4841EB"
      />
      <path
        d="M7.2672 17.0281L17.5266 17.0281L22.8122 17.0281"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
